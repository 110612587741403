import {
    ElementTypes
} from '../../../config';

const OrderColumns = {
    referenceNumber: {
        Title: 'Row',
        Field: ElementTypes.TableLabelField(),
        Width: 90
    },
    puDate: {
        Title: 'READY',
        Field: ElementTypes.ColumnDate(),
        Width: 90,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    dueDate: {
        Title: 'DUE',
        Field: ElementTypes.ColumnDate(),
        Width: 90,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    customerCodeOrName: {
        Title: 'CUS',
        Field: ElementTypes.TableLabelField(),
        Width: 70,
        Sorted: 'asc',
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    pickLocationName: {
        Title: 'P/U WH',
        Field: ElementTypes.TableLabelField(),
        Width: 150,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    pickCity: {
        Title: 'P/U C',
        Field: ElementTypes.TableLabelField(),
        Width: 80,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    pickState: {
        Title: 'P/U ST',
        Field: ElementTypes.TableLabelField(),
        Width: 80,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    drop1WH: {
        Title: 'TRF WH',
        Field: ElementTypes.Generic(ElementTypes.TableLabelField, {
            display: (o, value) => o.warehouse ? value : 'Bypass'
        }),
        Width: 150,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    drop2State: {
        Title: 'DEL ST',
        Field: ElementTypes.TableLabelField(),
        Width: 100,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    drop2City: {
        Title: 'DEL CITY',
        Field: ElementTypes.TableLabelField(),
        Width: 100,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    drop2WH: {
        Title: 'DEL WH',
        Field: ElementTypes.TableLabelField(),
        Width: 100,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    poNumber: {
        Title: 'PO#',
        Field: ElementTypes.TableLabelField(),
        Width: 70,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    soNumber: {
        Title: 'SO#',
        Field: ElementTypes.TableLabelField(),
        Width: 70,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    palletCount: {
        Title: 'PAL',
        Field: ElementTypes.TableLabelField(),
        Width: 60,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    space: {
        Title: 'SPA',
        Field: ElementTypes.TableLabelField(),
        Width: 60,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    cases: {
        Title: 'CS',
        Field: ElementTypes.TableDecimalField(),
        Width: 60,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    weight: {
        Title: 'LBS',
        Field: ElementTypes.TableDecimalField(),
        Width: 60,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    inboundAppointment: null,
    orderStatus: {
        Title: 'ORDER CYCLE',
        Field: ElementTypes.ColumnBadge(),
        Width: 110
    },
    batchType: {
        Title: 'B',
        Field: ElementTypes.TableLabelField(),
        Width: 55
    }, 
    drop1City: {
        Title: 'Drop City',
        Field: ElementTypes.Generic(ElementTypes.TableLabelField, {
            display: (o, value) => o.warehouse ? value : 'Bypass'
        }),
        Width: 100,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    drop1State: {
        Title: 'Drop ST',
        Field: ElementTypes.Generic(ElementTypes.TableLabelField, {
            display: (o, value) => o.warehouse ? value : 'Bypass'
        }),
        Width: 100,
        Attributes: {
            wrap: {
                left: ''
            }
        }
    },
    lastComment: {
        Title: '',
        Field: ElementTypes.LatestCommentIndicator((_, data) => data),
        DataTitle: true,
        Width: 40,
        Attributes: {
            circle: {
                green: ''
            }
        }
    },
    puWeek: {
        Title: 'In-WK',
        Field: ElementTypes.TableLabelField(),
        Width: 80
    },
};

export default OrderColumns;