import moment from 'moment';
export const itemDefaultSize = 20;
const toDateTime = value => value ? moment.utc(value).toDate() : '';

const extractWebLoadsToList = (state, orders)  => {
    let loadsAssignedOrders = [];
    orders.forEach(item=> loadsAssignedOrders.push(createWebOrder(item, state.headers)))
    return loadsAssignedOrders;
};

export const updateOrdersLoadState = (state, orders, endpoint, hasError = false, message = '', filterType = 'all', sortType = {type: "", ind: "", sort: null }) => ({
    ...state, ...{
        orders,
        loading: false,
        changeRequestListLoading: endpoint === 'orderschangerequestslist' ? false : true,
        openOrdersLoading: endpoint === 'availableorderlist' ? false : true,
        hasError,
        message,
        filterType,
        sortType,
        loadsAssignedOrders: extractWebLoadsToList(state, orders),
    }
});

export const loadContent = (state, responce, hasError = false, message = '') => {
    const data = responce.responce.data || [];
    console.log(responce.endpoint);
    return updateOrdersLoadState(state, [...data.data], responce.endpoint, hasError, message);
};

export const createWebOrder = (oData, headers, isHidden) => {
    const orderData = { ...oData };

    const orderWebData = {
        row: [],
        rowState: {
            type: 'order',
            id: `order_${orderData.id}`,
            orderObjectId: orderData.id,
            orderData: orderData,
            orderId: `order_${orderData.id}`,
            visible: !isHidden,
            reacted: false,
            height: itemDefaultSize,
        }
    };
    const pathname = window.location.pathname;

    for (let hederIndex = 0; hederIndex < headers.length; hederIndex++) {
        const headerItem = headers[hederIndex];
        let value = null;
        let value2 = null;
        let fieldType = 'text';
        let editable = false;
        let cellStyle = {};
        let tooltip = null;
        if (!headerItem.leftAction) {
            switch (headerItem.key) {
                case 'id':
                    value = orderData.id;
                    break;
                case 'temperature':
                    value = orderData.temperature === 1 ? 'Frozen' : 
                            orderData.temperature === 2 ? 'Chilled' : 
                            orderData.temperature === 3 ? 'Dry' :
                            orderData.temperature === 4 ? 'C|F' : '';
                    fieldType = 'text';
                    break;
                case 'customerName':
                    value = orderData.customerName;
                    break;
                case 'orderGroupIndex':
                    value = orderData.batch === 3 ? '' : orderData.orderGroupIndex;
                    break;
                case 'price':
                    value =  orderData.price ? `$${orderData.price}` : '';
                    break;
                case 'batchType':
                    value = orderData.batchType;
                    break;
                case 'pickLocationName':
                    value = orderData.pickLocationName;
                    break;
                case 'drop2WH':
                    value = orderData.drop2WH;
                    break;
                case 'referenceNumber':
                    value = orderData.referenceNumber;
                    break;
                case 'routeId':
                    value = orderData.routeId === 1 ? "WHS" : orderData.routeId === 2 ? "DIR" : "Both";
                    break;
                case 'deliveredDate':
                    value = pathname === "/ar/openorders" || pathname === "/ar/changerequestsar" ? orderData?.deliverDate : orderData?.deliveredDate;
                    fieldType = pathname === "/ar/openorders" || pathname === "/ar/changerequestsar" ? 'date' : 'dateTime';
                    break;
                case 'pickInDate':
                    value =  toDateTime(orderData.outboundPickIn);
                    fieldType = 'dateTime';
                    break;
                case 'puDate':
                    value = toDateTime(orderData.puDate);
                    fieldType = 'date';
                    break;
                case 'dellAppt':
                    value =  toDateTime(orderData.outboundDropAppointment);
                    fieldType = 'dateTime';
                    break;
                case 'pickOutDate':
                    value = toDateTime(orderData.outboundPickOut);
                    fieldType = 'dateTime';
                    break;
                case 'wk':
                    value = orderData.puDate && new Date(orderData.puDate).getWeek();
                    fieldType = 'text';
                    break;
                case 'space':
                    value = orderData.space;
                    fieldType = 'text';
                    break;
                case 'dueDate':
                    value = toDateTime(orderData.dueDate);
                    fieldType = 'date';
                    editable = false;
                    break;
                case 'customerCodeOrName':
                    value = orderData.customerCodeOrName;
                    fieldType = 'text';
                    break;
                case 'dropLocation':
                    value = orderData.dropLocation;
                    fieldType = 'text';
                    break;
                case 'pickLocation':
                    value = orderData.pickLocation;
                    fieldType = 'text';
                    break;
                case 'poNumber':
                    value = orderData.poNumber;
                    fieldType = 'text';
                    break;
                case 'soNumber':
                    value = orderData.soNumber;
                    fieldType = 'textInput';
                    break;
                case 'palletCount':
                    value = orderData.palletCount;
                    fieldType = 'text';
                    break;
                case 'cases':
                    value = orderData.cases;
                    fieldType = 'text';
                    break;
                case 'weight':
                    value = orderData.weight;
                    fieldType = 'text';
                    break;
                case 'lumper':
                    value =  orderData.outboundLumper ? `$${orderData.outboundLumper}` : '';
                    fieldType = 'currency';
                    break;
                case 'podRequestStatus':
                    let tempValue =  orderData.outPodRequestStatus ;
                    if(pathname === "/ar/deliverednopod"){
                        value = tempValue
                        editable = true;
                        fieldType = 'select';
                    } else {
                        const selectOptionsPOD = { null: '-', 0: '-', 1: 'Received', 2: 'Requested' };
                        value = selectOptionsPOD[tempValue]
                        editable = false;
                        fieldType = 'text';
                    }
                    tooltip = orderData.outPodRequestAuthorFullName?.trim() ?
                        `Last Changed: ${orderData.outPodRequestAuthorFullName}` :
                        `No changes`;
                    break;
                case 'podDoc':
                    value = orderData.callCheckOutAttachment?.fileName;
                    if(pathname === "/ar/deliverednopod"){
                        editable = true;
                        fieldType = 'upload';
                    } else {
                        fieldType = 'download';
                        editable = false;
                    }
                    tooltip = orderData.callCheckOutAttachment?.fullName?.trim() ?
                        `Last Changed: ${orderData?.callCheckOutAttachment?.fullName}` :
                        `No changes`;
                        break;
                case 'orderNotesByCall':
                    // value = loadData.loadType === 1 ? orderData.inNotes : loadData.loadType === 3 ? orderData.outNotes : '';
                    value2 = orderData.outPodNotesHistory;
                    // value = loadData.loadType === 1 ? orderData.inNotes : loadData.loadType === 3 ? orderData.outNotes : '';
                    // fieldType = 'select';
                    // editable = true;
                    // break;
                    let tempValueOrderNotes = orderData.outNotes;
                    if(pathname === "/ar/deliverednopod"){
                        value = tempValueOrderNotes
                        editable = true;
                        fieldType = 'select_and_popover';
                    } else {
                        const selectOptionsPODStatus = { 'Delivered': 'Delivered', 'Shortage': 'Shortage', 'Overage': 'Overage', 'Reject': 'Reject' };
                        value = selectOptionsPODStatus[tempValueOrderNotes]
                        editable = false;
                        fieldType = 'popover';
                    }
                    break;
                case 'outPodRequestStatus':
                    value = orderData.outPodRequestStatus;
                    value = orderData.outPodRequestStatus === 1 ? 'Received' : orderData.outPodRequestStatus === 2 ? 'Requested' : '-';
                    break;
                case 'freight':
                    value = orderData.freight ? `$ ${orderData.freight}` : '';
                    break;                
                case 'orderStatus':
                    value = orderData.orderStatus;
                    break;
                case 'actions':
                    value = 'text';
                    fieldType = 'actions';
                    break;
                case 'readyToBill':
                    value = "SEND TO BILL";
                    fieldType = 'button';
                    break
                case 'status':
                    value = "BILLED";
                    fieldType = 'buttonText';
                    break
                case 'billedBy':
                    value = orderData.billedByFullName;
                    fieldType = 'text';
                    break
                case 'billedDate':
                    value = orderData.billedDate;
                    fieldType = 'dateTime';
                    break
                case 'changeHistory':
                    value = "Change History";
                    fieldType = 'button';
                    break
                case 'priceTemplateType':
                    value = orderData.priceTemplateType === 1 ? 'Spot Price' : orderData.priceTemplateType > 1 ? 'Price Template' : 'Spot Price';
                    fieldType = 'text';
                    break
                case 'recalculation':
                    value = "Recalculation";
                    fieldType = 'button';
                    break
                case 'confirm':
                    value = "Confirm";
                    fieldType = 'button';
                    break
                default:
                    break;
            }
        }
        else {
            value = '';
            fieldType = 'text';
        }

        orderWebData.row.push({
            key: headerItem.key,
            value,
            value2,
            fieldType,
            displayState: 1,
            editable,
            cellStyle,
            tooltip
        });
    }

    return orderWebData;
};

export const setHeaderWidth = (state, ind, width) => {
    const header = state.headers[ind];
    header.width = width;
    return {
        ...state, ...{
            headers: [
                ...state.headers.slice(0, ind),
                ...[header],
                ...state.headers.slice(ind + 1)
            ],
            loadsAssignedOrders: state.loadsAssignedOrders,
        }
    };
};

export const getChangeRequestData = (state, data) => {
    return {
        ...state, ...{
            changeRequestData: data.data,
            loading: false,
        }
    };
};

export const setOrderId = (state, orderId) => {
    return {
        ...state, ...{
            orderId: orderId,
        }
    };
};

export const setChecked = (state, orderId) => {
    const loadsAssignedOrders = state.loadsAssignedOrders;
    if (orderId?.length) {
        loadsAssignedOrders.map(order => order.rowState.checked = true);
        return {
            ...state, ...{
                loadsAssignedOrders: loadsAssignedOrders
            }
        }
    } else if (orderId?.length === 0) {
        loadsAssignedOrders.map(order => order.rowState.checked = false);
        return {
            ...state, ...{
                loadsAssignedOrders: loadsAssignedOrders
            }
        }
    } else {
        const index = loadsAssignedOrders.findIndex(i => i.rowState.orderObjectId === orderId);
        const item = loadsAssignedOrders[index];
        loadsAssignedOrders.map(order => order.rowState.checked = false);
        item.rowState.checked = true;
        return {
            ...state, ...{
                loadsAssignedOrders:
                [
                    ...state.loadsAssignedOrders.slice(0, index),
                    ...[item],
                    ...state.loadsAssignedOrders.slice(index + 1)
                ],
            }
        }
    };
};

export const filterDeliveredTrucks = (state, type) => {
    return {
        ...state,
        ...{
            filterType: type,
        },
    }
};

export const sortBy = (state, sortValue) => ({
    ...state,
    ...{
        sortType: sortValue,
    },
});

export const reactedAssigned = (state, type, id) => {
    const loadsAssignedOrders = state.loadsAssignedOrders;
    const index = loadsAssignedOrders.findIndex(i => i.rowState.id === id && i.rowState.type === type);
    const item = loadsAssignedOrders[index];

    if (item && item.rowState.type !== 'order') {
        item.rowState.reacted = !item.rowState.reacted;

        if (type === 'load') {
            const stops = loadsAssignedOrders.filter(i => i.rowState.loadId === id && i.rowState.type === 'stop');
            const orders = loadsAssignedOrders.filter(i => i.rowState.loadId === id && i.rowState.type === 'order');

            stops.forEach(stop => {
                if (stop.rowState.visible) {
                    orders.forEach(o => o.rowState.visible = false);
                    stop.rowState.visible = false;
                } else {
                    orders.forEach(o => o.rowState.visible = true);
                    stop.rowState.visible = true;
                }
            })

            return {
                ...state, ...{
                    headers: state.headers,
                    loadsAssignedOrders:
                    [
                        ...state.loadsAssignedOrders.slice(0, index),
                        ...[item],
                        ...state.loadsAssignedOrders.slice(index + 1)
                    ],
                }
            }
        }
        if (type === 'stop') {
            const stopOrders = state.loadsAssignedOrders.filter(i => i.rowState.stopId === id && i.rowState.type === 'order');
            stopOrders.forEach(o => o.rowState.visible = !o.rowState.visible);
            return {
                ...state, ...{
                    headers: state.headers,
                    loadsAssignedOrders:
                    [
                        ...state.loadsAssignedOrders.slice(0, index),
                        ...[item],
                        ...state.loadsAssignedOrders.slice(index + 1)
                    ],
                }
            }
        }
    }
    return state;
};

export const updateLoadOrderGroupData = (state, loadData, changedOrder, header, newValue, newOrders) => {
    let stopsOrders;
    let orderLoad;

    const orderStops = state.loadsAssignedOrders.filter(order => order.rowState?.orderData?.id === changedOrder.id);
    const changedOrderLoadType = loadData.loadType;
    const orderStopId = orderStops.find(stop => stop.rowState.loadData.loadType === changedOrderLoadType).rowState.stopId;
    
    const stopOrdersState = state.loadsAssignedOrders.filter(order => order.rowState.stopId === orderStopId);
    const stopOrdersIds = stopOrdersState.map(order => order.rowState.orderData.id);
    stopsOrders = newOrders.filter(order => stopOrdersIds.includes(order.id));
    orderLoad = state.loadsAssignedOrders.find(l => l.rowState.type === 'load' && l.rowState.loadData.id === loadData.id);

    if (orderLoad) {
        stopsOrders.forEach(order => {

            if (header.key === 'orderStatusByCall') {
                if (orderLoad.rowState.loadData.loadType === 3) {
                    order['outOrderStatusByCall'] = newValue;
                    if (newValue === 0 || newValue === 1) {
                        order['outDelayedReason'] = null;
                    }
                } else if (orderLoad.rowState.loadData.loadType === 1) {
                    if (newValue === 0 || newValue === 1) {
                        order['inDelayedReason'] = null;
                    }
                    order['inOrderStatusByCall'] = newValue;
                }
            } else
            if (header.key === 'delayedReason') {
                if (orderLoad.rowState.loadData.loadType === 3) {
                    order['outDelayedReason'] = newValue;
                } else if (orderLoad.rowState.loadData.loadType === 1) {
                    order['inDelayedReason'] = newValue;
                }
            }
            order[header.key] = newValue;
        });
    }
};

export const setData = (state, newValue, rowState, header) => {
    if (rowState.orderData || rowState.firstOrderData) {
        const newOrders = [...state.orders];
        const changedOrder = rowState.firstOrderData ? 
                newOrders.find(o => o.id === rowState.firstOrderData.id) :
                newOrders.find(o => o.id === rowState.orderData.id);
        if (changedOrder) {
            if (header.key === 'orderStatusByCall') {
                    changedOrder['outOrderStatusByCall'] = newValue;
                    if (newValue !== 2) {
                        changedOrder['outDelayedReason'] = null;
                    }
            } else if (header.key === 'delayedReason') {
                    changedOrder['outDelayedReason'] = newValue;
            } else if (header.key === 'orderNotesByCall') {
                    changedOrder['outNotes'] = newValue;
            } else if (header.key === 'podRequestStatus') {
                    changedOrder['outPodRequestStatus'] = newValue;
            } else if (header.key === 'podDoc') {
                    changedOrder['callCheckOutAttachment'] = newValue;
            }
            changedOrder[header.key] = newValue;
        }
        // updateLoadOrderGroupData(state, rowState?.loadData, changedOrder, header, newValue, newOrders);
        return updateOrdersLoadState(state, newOrders,state.hasError, state.message, state.filterType, state.sortType);
    }
    else {
        return state;
    }
};

export const updatePrice = (state, responce) => {
    const data = responce.responce.data || [];
    const id = responce.id;
    const orders = state.orders;
    const selectedOrder = orders.filter(order => order.id === id)[0];
    const selectedOrderIndex = orders.findIndex(order => order.id === id);
    selectedOrder.price = data.price;
    selectedOrder.priceTemplateType = data.priceTemplateType;

    const updatedOrders = [
        ...state.orders.slice(0, selectedOrderIndex),
        ...[selectedOrder],
        ...state.orders.slice(selectedOrderIndex + 1)
    ];
    return updateOrdersLoadState(state, updatedOrders, state.hasError, state.message, state.filterType, state.sortType);
};

export const reCalculatePrice = (state, responce) => {
    const price = responce.price;
    const id = responce.id;
    const priceTemplateType = responce.priceTemplateTypeId;
    const orders = state.orders;
    const selectedOrder = orders.filter(order => order.id === id)[0];
    const selectedOrderIndex = orders.findIndex(order => order.id === id);
    selectedOrder.price = price;
    selectedOrder.priceTemplateType = priceTemplateType;
    const updatedOrders = [
        ...state.orders.slice(0, selectedOrderIndex),
        ...[selectedOrder],
        ...state.orders.slice(selectedOrderIndex + 1)
    ];
    return updateOrdersLoadState(state, updatedOrders, responce.endpoint, state.hasError, state.message, state.filterType, state.sortType);
};

export const setPriceAsCalculated = (state, responce) => {
    const list = state.priceIsCalculated;
    list.push(responce);
    return {
        ...state, 
        ...{
            priceIsCalculated: list,
        }
    }
};

export const setDisplayState = (state, rowState, header, enable) => ({
    ...state, ...{
        lastSelected: {
            id: rowState.id,
            headerKey: header.key,
            enable: enable
        }
    }
});

export const setError = (state, err) => ({
    ...state,
    ...{
        hasError: true,
        message: 'Something went wrong, please try again',
    },
});

export const resetError = state => ({
    ...state,
    ...{
        hasError: false,
        message: ''
    }
});

export const setInLoading = (state, endpoint) => ({
    ...state, ...{
        loading: true,
        changeRequestListLoading: endpoint === 'orderschangerequestslist' ? true : false,
        openOrdersLoading: endpoint === 'availableorderlist' ? true : false,
    }
});
