import React, { useRef } from 'react';

import { ElementContent } from '../../../helper/element';
import { useValidation } from '../../../hook/data/useValidation';
import { useElementChange } from '../../../hook/action/useElementChange';
import { useElementOut } from '../../../hook/action/useElementOut';
import { useElementValidationFocus } from '../../../hook/action/useElementFocus';
import { ColumnType } from '../../../config/list.config';
import { InvertColor } from '../../../helper/common';
import { getNormalizedAttributes } from '../../../config/element.config';

const SheetCell = props => {
    const ref = useRef();
    const messageIdentity = props.messageIdentityPrefix ? `${props.messageIdentityPrefix}_${props.field}` : props.field;
    useElementValidationFocus(props.messages, ref, messageIdentity);
    const valudationMessages = props.messages && props.messages[messageIdentity];
    const validate = useValidation(props.messageDispatch, props.column.Validations, props.column.Title, messageIdentity, props.object, props.column.Warnings);

    const change = (event, data) => {
        
        props.onChange({
            nativeEvent: event,
            column: props.column,
            field: event.target.field || props.field,
            dataField: props.dataField,
            keyField: props.keyField,
            parentKeyField: props.parentKeyField,
            newData: data
        });
    };

    const attributes = getNormalizedAttributes(props.column.Attributes, props.object, props.data);

    const fieldChangeHandler = useElementChange(validate, change);
    const fieldOutHandler = useElementOut(validate, change);

    if(props.column.Type === ColumnType.Empty) {
        return <td><wrap></wrap></td>;
    }

    const field = ElementContent({
        ...props.column,
        object: props.object,
        val: props.data,
        fieldChange: fieldChangeHandler,
        fieldOut: fieldOutHandler,
        ref: ref,
        valudationMessages: valudationMessages,
        warningMessages: props?.messages?.warnings?.[messageIdentity] || [],
        onActionRecord: props.onActionRecord,
        parentData: props.parentData
    });

    const color = props.color;

    return (
        <td {...attributes.td} style={props.coloring ? {
            backgroundColor: color,
            color: InvertColor(color, true)
        } : null}>
            <wrap {...attributes.wrap}>
                {field}
            </wrap>
        </td>
    );
};

export default React.memo(SheetCell);