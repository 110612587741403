import { DELIVERED_LOADS_BUILD as actionTypes } from '../../actions/deliveredLoads/deliveredLoadsActionTypes';
import * as loadArea from './deliveredLoadsReducer';

const defaultState = {
  headers: [
    {
      key: 'left_action',
      title: '',
      width: 36,
      leftAction: true,
      visible: true,
    },
    {
      key: 'pickInDate',
      title: 'Pick IN',
      width: 120,
      leftAction: false,
      visible: true,
      pickGroup: true,
    },
    {
      key: 'pickOutDate',
      title: 'Pick OUT',
      width: 120,
      leftAction: false,
      visible: true,
      pickGroup: true,
    },
    {
      key: 'readyDate',
      title: 'Ready date',
      width: 120,
      leftAction: false,
      visible: true,
      pickGroup: true,
    },
    {
      key: 'inboundPickAppointment',
      title: 'Pick Appt',
      width: 120,
      leftAction: false,
      visible: true,
      dropGroup: true,
    },
    {
      key: 'dueDate',
      title: 'Due Date',
      width: 60,
      leftAction: false,
      rightAction: false,
      visible: true,
    },
    {
      key: 'dellAppt',
      title: 'Del Appt',
      width: 60,
      leftAction: false,
      rightAction: false,
      visible: true,
    },
    {
      key: 'dropInDate',
      title: 'Del In',
      width: 120,
      leftAction: false,
      visible: true,
      pickGroup: true,
    },
    {
      key: 'dropOutDate',
      title: 'Del Out',
      width: 120,
      leftAction: false,
      visible: true,
      pickGroup: true,
    },
    {
      key: 'customerCodeOrName',
      title: 'Cust',
      width: 60,
      leftAction: false,
      rightAction: false,
      visible: true,
    },
    {
      key: 'pickLocation',
      title: 'Pick',
      width: 200,
      leftAction: false,
      rightAction: false,
      visible: true,
    },
    {
      key: 'pickLocation',
      title: 'Delivery',
      width: 200,
      leftAction: false,
      rightAction: false,
      visible: true,
    },

    {
      key: 'poNumber',
      title: 'PO#',
      width: 43,
      leftAction: false,
      visible: true,
    },
    {
      key: 'soNumber',
      title: 'SO#',
      width: 57,
      leftAction: false,
      visible: true,
    },
    {
      key: 'palletCount',
      title: 'Pal',
      width: 37,
      leftAction: false,
      visible: true,
    },
    {
      key: 'space',
      title: 'SPA',
      width: 37,
      leftAction: false,
      visible: true,
    },
    {
      key: 'cases',
      title: 'CS',
      width: 60,
      leftAction: false,
      visible: true,
    },
    {
      key: 'weight',
      title: 'LBS',
      width: 50,
      leftAction: false,
      visible: true,
    },
    {
      key: 'loadNumber',
      title: 'LOAD #',
      width: 90,
      leftAction: false,
      visible: true,
    },
    {
      key: 'wk',
      title: 'WK',
      width: 40,
      leftAction: false,
      visible: true,
    },
    {
      key: 'in_load',
      title: 'In Load',
      width: 50,
      leftAction: false,
      rightAction: false,
      visible: true,
    },
    {
      key: 'out_load',
      title: 'Out Load',
      width: 60,
      leftAction: false,
      rightAction: false,
      visible: true,
    },
    {
      key: 'carrierName',
      title: 'Carrier',
      width: 80,
      leftAction: false,
      rightAction: false,
      visible: true,
    },
    {
      key: 'rate',
      title: 'Rate',
      width: 40,
      leftAction: false,
      rightAction: false,
      visible: true,
    },
    {
      key: 'driver_truck_Trailer_VIN',
      title: 'Driver/Truck',
      width: 160,
      leftAction: false,
      rightAction: false,
      visible: true,
    },
    {
      key: 'lumper',
      title: 'Lumper',
      width: 50,
      leftAction: false,
      rightAction: false,
      visible: true,
    },
    {
      key: 'efs',
      title: 'EFS',
      width: 40,
      leftAction: false,
      rightAction: false,
      visible: true,
    },
    {
      key: 'instructions',
      title: 'Notes',
      width: 60,
      leftAction: false,
      rightAction: false,
      visible: true,
    },
    {
      key: 'podRequestStatus',
      title: 'POD Request',
      width: 95,
      leftAction: false,
      visible: true,
      sortable: true,
    },
    {
      key: 'podDoc',
      title: 'POD Doc.',
      width: 100,
      leftAction: false,
      visible: true,
    },
    {
      key: 'orderNotesByCall',
      title: 'POD Status',
      width: 110,
      leftAction: false,
      visible: true,
    },
  ],
  lastSelected: {
    id: null,
    headerKey: null,
    enable: false,
  },
  orders: null,
  loads: null,
  loadsAssignedOrders: null,
  loading: true,
  hasError: false,
  message: '',
  filterType: '',
  sortType: { type: '', ind: '', sort: null },
};

  export default (state = defaultState, action) => {
    switch (action.type) {
      case actionTypes.GET_DELIVERED_LOADS_LOADING:
        return loadArea.setInLoading(state);
      case actionTypes.GET_DELIVERED_LOADS:
        return loadArea.loadContent(state, action.data);
      case actionTypes.DELIVERED_LOADS_ERROR:
        return loadArea.setError(state, action.data);
      case actionTypes.RESET_ERROR:
        return loadArea.resetError(state);
      case actionTypes.REACTED_ASSIGNED_DELIVERED_LOADS:
        return loadArea.reactedAssigned(state, action.data.type, action.data.id);
      case actionTypes.FILTER_DELIVERED_LOADS:
        return loadArea.filterDeliveredLoads(state, action.data);
      case actionTypes.SORT_DELIVERED_LOADS:
        return loadArea.sortBy(state, action.data);
      case actionTypes.RELOAD_ON_ERROR_DELIVERED_LOADS:
        return loadArea.loadContent(state, action.data, true, 'Something go wrong, data reloaded!');
      case actionTypes.SET_HEADER_WIDTH_DELIVERED_LOADS:
        return loadArea.setHeaderWidth(state, action.data.ind, action.data.width);
      case actionTypes.SET_DISPLAY_STATE_DELIVERED_LOADS:
        return loadArea.setDisplayState(state, action.data.rowState, action.data.header, action.data.enable);
      case actionTypes.SET_DELIVERED_LOADS_DATA:
        return loadArea.setData(state, action.data.value, action.data.rowState, action.data.header);
      default:
        return state;
    }
};
