export const Action = {
    Edit: 'Edit',
    Clone: 'Clone',
    View: 'eye',
    BeforeSave: 'BeforeSave',
    Save: 'Save',
    SaveClose: 'SaveClose',
    SaveSend: 'SaveSend',
    SaveNew: 'SaveNew',
    SaveBack: 'SaveBack',
    ForceUpdate: 'ForceUpdate',
    Delete: 'Delete',
    Cancel: 'Cancel',
    CancelReason: 'CancelReason',
    Close: 'Close',
    ChangePassword: 'ChangePassword',
    Book: 'play',
    Booked: 'play_filled',
    Invoice: 'play',
    Invoiced: 'play_filled',
    Complete: 'done',
    Done: 'done',
    InTransit: 'send',
    RemoveInTransit: 'remove',
    Dispatch: 'pin',
    Custom: 'custom',
    CustomWithoutValidate: 'CustomWithoutValidate',
    NeedToClarify: 'NeedToClarify',
    CreateGroup: 'CreateGroup',
};

export const ActionStatus = {
    Open: 'OPEN',
    Valid: 'VALID',
    NotValid: 'NOT-VALID',
    Deleted: 'DELETED'
};

export const ButtonActionTypes = {
    Table: 'Table',
    FormField: 'FormField'
}