import {
    ElementTypes
} from '../../config';
import { appointmentStatusColor } from '../Order/colorConfig';
import { InvertColor, HexToRGBAFormat } from '../../helper/common';

export const Group1 = [
    {
        useSeparator: false,
        Fields: {
            inboundLoadNumber: {
                Title: 'Load Number',
                Field: ElementTypes.LabelField()
            },
            truckNumber: {
                Title: 'Truck Number',
                Field: ElementTypes.LabelField()
            },
            carrierName: {
                Title: 'Carrier',
                Field: ElementTypes.LabelField()
            }
        }
    }
];

export const Group2 = [
    {
        useSeparator: false,
        Fields: {
            driverName: {
                Title: 'Driver',
                Field: ElementTypes.LabelField()
            },
            driverPhone: {
                Title: 'Driver Phone',
                Field: ElementTypes.Phone()
            }
        }
    }
];

export const Group3 = [
    {
        useSeparator: false,
        Fields: {
            inboundAppointment: {
                Title: 'Pick Appointment',
                Field: ElementTypes.DateTimeLabel()
            },
            dropInboundAppointment: {
                Title: 'Drop Appointment',
                Field: ElementTypes.DateTimeLabel()
            }
        }
    }
];

export const Group4 = [
    {
        useSeparator: false,
        Fields: {
            inbioundAppointmentStatus: {
                Title: 'Pick Appointment Status',
                Field: ElementTypes.Generic(ElementTypes.LabelField,
                    {
                        style: (_, data) => {
                            return {
                                color: InvertColor(HexToRGBAFormat(appointmentStatusColor(data), 100), true),
                                backgroundColor: appointmentStatusColor(data),
                                fontWeight: 'bold',
                                width: '100px',
                                textAlign: 'center'
                            };
                        }
                    })
            },
            dropInboundAppointmentStatus: {
                Title: 'Drop Appointment Status',
                Field: ElementTypes.Generic(ElementTypes.LabelField,
                    {
                        style: (_, data) => {
                            return {
                                color: InvertColor(HexToRGBAFormat(appointmentStatusColor(data), 100), true),
                                backgroundColor: appointmentStatusColor(data),
                                fontWeight: 'bold',
                                width: '100px',
                                textAlign: 'center'
                            };
                        }
                    })
            }
        }
    }
];