import { createSeparator } from './helper/separator';
import { formatPhoneNumber } from "../../../codeless/helper/common";

const itemDefaultSize = 20;
const defRowHeight = 40;
const statusList = {
  currentlyInDoor: 5,
  nextUp: 1,
};

export const createWebOrder = (oData, headers) => {
  const data = { ...oData };
  const loadStatus = data.loadStatus;
  const orderWebData = {
    row: [],
    rowState: {
      id: `load_${data.loadId}`,
      loadId: data.loadId,
      type: data.loadType,
      loadData: data,
      height: itemDefaultSize,
      loadStatus,
    },  
  };

  for (let headerIndex = 0; headerIndex < headers.length; headerIndex++) {
    const headerItem = headers[headerIndex];
    let value = null;
    let color = '#080E0E';
    let fieldType = 'text';
    let editable = false;
    let isBold = false;
    let cellStyle = {};
    let subType = null;
    let statusValue = null;
    let priorityValue = null;
    let delayedValue = null;
    let valueText = null;
    switch (headerItem.key) {
      case 'loadNum':
        value = data.loadvIdentifier;
        color = data.loadStatus === 5 ? '#FF0000' : '#FFACAC';
        break;
      case 'carrierName':
        value = data.carrierName;
        break;
      case 'driverPhone':
        value = formatPhoneNumber(data.driverPhone);
        break;
      case 'driverName':
        value = data.driverName;
        break;
      case 'st':
        value = data.state;
        fieldType = 'text';
        break;
      case 'palIn':
        value = data.loadType === 1 ? data.palletCountSum : '';
        fieldType = 'text';
        break;
      case 'palOut':
        value = data.loadType === 3 ? data.palletCountSum : '';
        fieldType = 'text';
        break;
      case 'day':
        value = data.previousDt;
        fieldType = 'date';
        break;
      case 'time':
        value = data.previousDt;
        fieldType = 'date';
        break;
      case 'customer':
        value = data.customer;
        fieldType = 'text';
        break;
      case 'in':
        value = data.previousIn;
        fieldType = 'date';
        break;
      case 'out':
        value = data.previousOut;
        fieldType = 'date';
        break;
      case 'whDay':
        value = data.whDt;
        fieldType = 'date';
        break;
      case 'whTime':
        value = data.whDt;
        fieldType = 'date';
        break;
      case 'eta':
        value = data.whDour;
        fieldType = 'select';
        editable = true;
        break;
      case 'checkIn':
        value = data.whIn;
        fieldType = 'date';
        break;
      case 'checkOut':
        value = data.whOut;
        fieldType = 'date';
        break;
      case 'firstDrop':
        value = data.firstDrop;
        fieldType = 'text';
        break;
      case 'truckVerified':
        value = data.isTruckVerifaed;
        fieldType = 'select';
        editable = true;
        color = data.isTruckVerifaed === 2 ? '#008000' : data.isTruckVerifaed === 3 ? '#FF0000': '#000000';
        break;
      case 'inboundsNeeded':
        value = data.inLoadNeeds;
        break;
      case 'trafficNote':
        value = data.trafficNote;
        fieldType = 'textInput';
        editable = true;
        break;
      case 'status':
        fieldType = (loadStatus === 6 || loadStatus === 7) ? 'text' : 'checkbox';
        editable = loadStatus === 6 ? false : true;
        valueText = loadStatus === 6 ? 'DONE' : 'REOPEN';
        statusValue = loadStatus;
        priorityValue = data.isPriority;
        delayedValue = data.isDelayed;
        value = loadStatus;
        color = loadStatus === 7 ? '#FF0000' : 'black' ;
        break;
      case 'status2':
        valueText = loadStatus === 6 ? 'REOPEN' : loadStatus === 7 ? 'DONE' : loadStatus === 1 ? 'CURRENTLY_IN_DOOR' : loadStatus === 5 ? 'SEND_DONE' : '';
        fieldType = loadStatus === 6 ? 'select' : 'text';
        editable = loadStatus === 6 || loadStatus === 7 ? true : false;
        statusValue = loadStatus;
        value = loadStatus;
        break;
      case 'status3':
        valueText = loadStatus === 5 ? 'NEXT_UP' : loadStatus === 6 || loadStatus === 7 ? 'COMPLETED' : '' ;
        fieldType = 'text';
        editable = false;
        color = data.loadStatus === 6 ? '#29CABA' : '#DEDEDE';
        statusValue = data.loadStatus;
        value = loadStatus;
        break;
      default:
        break;
    }
    orderWebData.row.push({
      key: headerItem.key,
      value,
      color,
      fieldType,
      displayState: 1,
      isBold,
      editable,
      cellStyle,
      subType,
      statusValue,
      priorityValue,
      delayedValue,
      valueText,
    });
  }
  return orderWebData;
};

export const createWebSubHeader = (currentAndNextHeaders, subHeaderName) => {
  const subHeaderData = {
    row: [],
    ordersData: [],
    rowState: {
      type: 'subHeader',
      id: subHeaderName,
      visible: true,
      height: itemDefaultSize,
    },
  };
  for (let Index = 0; Index < currentAndNextHeaders.length; Index++) {
    const regionHeader = currentAndNextHeaders[Index];
    subHeaderData.row.push({
      key: regionHeader.key,
      value: Index === 0 ? subHeaderName : '',
      editable: false,
    });
  }
  return subHeaderData;
};

const createDoneTrafficList = (state, doneTrafficList) => doneTrafficList.map(data => createWebOrder(data, state.headers));
const createCurrentTrafficList = (state, currentTrafficList) => currentTrafficList.map(data => createWebOrder(data, state.headers));
const createNextTrafficList = (state, nextTrafficList) => nextTrafficList.map(data => createWebOrder(data, state.headers));

const createHeaderForTrafficList = (state, loadType) => createWebSubHeader(state.currentAndNextHeaders, loadType);

const createCurrentTrafficListWithSeparator = (state, curentTrafficList) => {
  const currentTraffics = [];
  currentTraffics.push(createHeaderForTrafficList(state, 'Currently in door'));
  curentTrafficList.map(item => currentTraffics.push(item));
  return currentTraffics;
};
const createNextTrafficListWithSeparator = (state, nextTrafficList) => {
  const nextTraffics = [];
  nextTraffics.push(createSeparator(defRowHeight, state.headers, 'nextTraffic'));
  nextTraffics.push(createHeaderForTrafficList(state, 'Next Up'));
  nextTrafficList.map(item => nextTraffics.push(item));
  return nextTraffics;
};

const currentAndNextTrafficList = (state, currentTrafficListState, nextTrafficListState) =>
    createCurrentTrafficListWithSeparator(state, currentTrafficListState).concat(
    createNextTrafficListWithSeparator(state, nextTrafficListState),
);

const allTrafficListJoined = (state, doneTrafficList, currentTrafficList, nextTrafficList) => (
  createDoneTrafficList(state, doneTrafficList).concat(createCurrentTrafficList(state, currentTrafficList), createNextTrafficList(state, nextTrafficList)
));

export const setTrafficControl = (state, responce, hasError = false, message = '') => {
  const { data } = responce;
  const allTrafficList = [...data.doneTrafficList, ...data.curentTrafficList, ...data.nextTrafficList];
  const doneTrafficList = allTrafficList.filter(load => load.loadStatus === 6 || load.loadStatus === 7);
  const currentTrafficList = allTrafficList.filter(load => load.loadStatus === 5);
  const nextTrafficList = allTrafficList.filter(load => load.loadStatus === 1);
  const allTrafficListState = allTrafficListJoined(state, doneTrafficList, currentTrafficList, nextTrafficList);
  const currentTrafficListState = allTrafficListState.filter(load => load.rowState.loadStatus === 5);
  const nextTrafficListState = allTrafficListState.filter(load => load.rowState.loadStatus === 1);
  const doneTrafficListState = allTrafficListState.filter(load => load.rowState.loadStatus === 6 || load.rowState.loadStatus === 7);
  return {
    ...state,
    ...{
      loading: false,
      hasError,
      message,
      allTrafficList: allTrafficListJoined(state, doneTrafficList, currentTrafficList, nextTrafficList),
      doneTrafficList: doneTrafficListState.sort((load1, load2) => load1.rowState.loadId - load2.rowState.loadId),
      currentTrafficList: currentTrafficListState,
      nextTrafficList: nextTrafficListState,
      currentAndNextTrafficList: currentAndNextTrafficList(state, currentTrafficListState, nextTrafficListState),
      currentAndNextRowList: createHeaderForTrafficList(state),
      palletCountSummary: data.paletCountSum,
    },
  };
};

export const setData = (state, newValue, rowState, header, subType) => {
  if (rowState.loadData) {
    const trafficList = rowState.loadStatus === 6 || rowState.loadStatus === 7 ? [...state.doneTrafficList] : [...state.currentAndNextTrafficList];

    const changedLoad = trafficList.find(o => o.rowState.id === rowState.id);
    if (changedLoad) {
      changedLoad.row.forEach(row => {
        if (row.key === header.key && header.key === 'truckVerified') {
          if (newValue === 1) {
            row.color = '#000000';
          } else if (newValue === 2) {
            row.color = '#008000';
          } else if (newValue === 3) {
            row.color = '#FF0000';
          }
        }

        if (row.key === header.key && header.key === 'status') {
          if (subType === 'priority') {
            row.priorityValue = newValue;
          } else if (subType === 'delayed') {
            row.delayedValue = newValue;
          }
        }

        if (row.key === header.key) {
          return (row.value = newValue);
        }
      });
    }
    return state;
  } else {
    return state;
  }
};

export const updateLoadStatus = (state, newStatus, rowState, header, loadId) => {
  if (rowState.loadData) {
    const changedLoad = state.allTrafficList.find(o => o.rowState.loadId === loadId);
    if (changedLoad) {
      changedLoad.row.forEach(row => {
        if (row.key === 'status' && newStatus === 6) {
          row.value = newStatus;
          row.statusValue = newStatus;
          row.valueText = 'DONE';
          row.fieldType = 'text';
        }
        if (row.key === 'status' && newStatus === 7) {
          row.value = newStatus;
          row.statusValue = newStatus;
          row.valueText = 'REOPEN';
          row.fieldType = 'text';
          row.color = '#FF0000';
        }
        if (row.key === 'status' && newStatus === 5) {
          row.value = newStatus;
          row.statusValue = newStatus;
          row.fieldType = 'checkbox';
        }
        if (row.key === 'status2' && newStatus === 6) {
          row.value = newStatus;
          row.statusValue = newStatus;
          row.valueText = 'REOPEN';
          row.color = 'FF0000';
          row.fieldType = 'select';
          row.editable = true;
        }
        if (row.key === 'status2' && newStatus === 7) {
          row.value = newStatus;
          row.statusValue = newStatus;
          row.valueText = 'DONE';
          row.color = 'FF0000';
          row.fieldType = 'text';
          row.editable = false;
        }
        if (row.key === 'status2' && newStatus === 5) {
          row.valueText = 'SEND_DONE';
          row.value = newStatus;
          row.statusValue = newStatus;
          row.fieldType = 'text';
        }
        if (row.key === 'status2' && newStatus === 1) {
          row.value = newStatus;
          row.statusValue = newStatus;
          row.valueText = 'CURRENTLY_IN_DOOR';
        }
        if (row.key === 'status3' && newStatus === 5) {
          row.value = newStatus;
          row.statusValue = newStatus;
          row.valueText = 'NEXT_UP';
        }
        if (row.key === 'status3' && (newStatus === 7)) {
          row.value = newStatus;
          row.statusValue = newStatus;
          row.valueText = 'COMPLETED';
          row.color = '#DEDEDE';
        }
        if (row.key === 'status3' && (newStatus === 6)) {
          row.value = newStatus;
          row.statusValue = newStatus;
          row.valueText = 'COMPLETED';
          row.color = '#29CABA';
        }
        if (row.key === 'status3' && newStatus === 1) {
          row.value = newStatus;
          row.statusValue = newStatus;
          row.valueText = '';
        }
      });
    }
    changedLoad.rowState.loadStatus = newStatus;

    return {
      ...state,
      doneTrafficList: state.allTrafficList.filter(load => load.rowState.loadStatus === 6 || load.rowState.loadStatus === 7),
      currentTrafficList: state.allTrafficList.filter(load => load.rowState.loadStatus === 5),
      nextTrafficList: state.allTrafficList.filter(load => load.rowState.loadStatus === 1),
      currentAndNextTrafficList: currentAndNextTrafficList(state, 
        state.allTrafficList.filter(load => load.rowState.loadStatus === 5),
        state.allTrafficList.filter(load => load.rowState.loadStatus === 1),
      )
    };
    } else {
    return state;
  }
};

export const reOrderLoads = (state, newList) => {
  const currentTrafficOrderedLoadsIDs = state.currentAndNextTrafficList
    ?.filter(load => load.rowState.loadStatus === statusList.currentlyInDoor)
    .map(load => load.rowState.loadId);

  const dragOnCurrentlyInDoor = currentTrafficOrderedLoadsIDs?.filter(loadId => loadId === newList[0])[0];

  const sortByDragAndDrop = (trafficListState, newList) => {
    const reSortedList = [];
    for (let i = 0; i < newList.length; i++) {
      for (let j = 0; j < trafficListState.length; j++) {
        if (newList[i] === trafficListState[j].rowState.loadId) {
            reSortedList.push(trafficListState[j]);
        }
      }
    }
    return reSortedList;
  };

  const sortedCurrentTrafficListState = dragOnCurrentlyInDoor ? sortByDragAndDrop(state.currentTrafficList, newList) : state.currentTrafficList;
  const sortedNextTrafficListState = !dragOnCurrentlyInDoor ? sortByDragAndDrop(state.nextTrafficList, newList) : state.nextTrafficList;

  return {
    ...state,
    currentAndNextTrafficList: currentAndNextTrafficList(state, sortedCurrentTrafficListState, sortedNextTrafficListState),
    currentTrafficList: dragOnCurrentlyInDoor ? sortByDragAndDrop(state.currentTrafficList, newList) : state.currentTrafficList,
    nextTrafficList: !dragOnCurrentlyInDoor ? sortByDragAndDrop(state.nextTrafficList, newList) : state.nextTrafficList,
  };
};

export const setDisplayState = (state, rowState, header, enable) => ({
  ...state,
  ...{
    lastSelected: {
      id: rowState.id,
      headerKey: header.key,
      enable: enable,
    },
  },
});

export const setHeaderWidth = (state, ind, width) => {
  const header = state.headers[ind];
  header.width = width;

  let index = 0;
  let len = 0;
  let start = 0;
  if (ind < 5) { // length of first subHeader
    index = 0;
    len = 5;
  } else if (ind >= 5 && ind < 6) {
    index = 1;
    len = 6; // length of first and second subHeaders
    start = 5;
  } else if (ind >= 6 && ind < 7) {
    index = 2;
    len = 7; // length of first, second and third subHeaders
    start = 6;
  } else if (ind >= 7 && ind < 12) {
    index = 3;
    len = 12; // length of first, second, third and forth subHeaders
    start = 7;
  } else if (ind >= 12 && ind < 17) {
    index = 4;
    len = 17;
    start = 12;
  } else if (ind >= 17 && ind < 24) {
    index = 5;
    len = 24;
    start = 17;
  }

  const subHeader = state.subHeaders[index];
  subHeader.width = 0;
  let subHeaderWidth = 0;
  
  for (let i = 0; i < ind+1; i++) {
    subHeaderWidth = subHeaderWidth + state.headers[i].width;
  }

  for (let i = start; i < len; i++) {
    if (i === ind) {
      subHeader.width = subHeader.width + width;
    } else {
      subHeader.width = subHeader.width + state.headers[i].width;
    }
  }

  return {
    ...state,
    ...{
      headers: [...state.headers.slice(0, ind), ...[header], ...state.headers.slice(ind + 1)],
      subHeaders: [...state.subHeaders.slice(0, index), ...[subHeader], ...state.subHeaders.slice(index+1)],
    },
  };
};

export const loadContent = (state, response, hasError = false, message = '') => {
    return setTrafficControl(state, response, hasError, message);
  };


export const setLoadNeedId = (state, id) => ({
  ...state,
  ...{
    inLoadNeedId: id,
  },
});
  

export const setError = (state, error) => ({
  ...state,
  ...{
    hasError: true,
    message: error?.statusText ? error?.statusText : 'Something went wrong, try again',
  },
});

export const resetError = state => ({
  ...state,
  ...{
    hasError: false,
    message: '',
  },
});

export const setInLoading = state => ({
  ...state,
  ...{
    loading: true,
  },
});
