import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { DatePicker, InputNumber, Select, Tooltip } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';
import { enabled } from 'store/dist/store.legacy';
import { Defines } from '../../../../project/Defines';
import { setItemData, setItemDisplayState, updateLoadStatus, setLoadNeedId } from '../../../../store/actions/trafficControl/trafficControl';
import { setItemDataUpcoming } from '../../../../store/actions/trafficControlUpcoming/trafficControlUpcoming';
import WarningModal from './warningModal';  

const Cell = ({
  state,
  cellData,
  itemSize,
  rowState,
  resource,
  startOnScroll,
  setItemData,
  setItemDataUpcoming,
  setItemDisplayState,
  updateLoadStatus,
  setLoadNeedId,
  doneTrafficList,
  currentAndNextTraffic,
}) => {
  const traffic = state[resource];
  const redonly = 1;
  const editable = 2;
  const header = traffic.headers.find(h => h.key === cellData.key);
  // const headerIndex = traffic.headers.findIndex(h => h.key === cellData.key);

  const selectDoorsNameOptions = [ 
    { name: 'DOOR 1', id: 1 },
    { name: 'DOOR 2', id: 2 },
    { name: 'DOOR 3', id: 3 },
    { name: 'DOOR 4', id: 4 },
    { name: 'DOOR 5', id: 5 }
  ];
  const selectDoorsOptions = { 1: 'DOOR 1', 2: 'DOOR 2', 3: 'DOOR 3', 4: 'DOOR 4', 5: 'DOOR 5' };
  
  const selectVerifiedNameOptions = [
    { name: '-', id: 1 },
    { name: 'YES', id: 2 },
    { name: 'NO', id: 3 },
  ];
  const selectVerifiedOptions = { 1: '-', 2: 'YES', 3: 'NO' };
  
  const statusListSmallOptions = { 6: 'REOPEN', 7: 'REOPEN', 5: 'CURRENTLY IN DOOR' };
  const statusListSmallNameOptions = [
    { name: 'REOPEN', id: 7 },
    { name: 'CURRENTLY IN DOOR', id: 5 },
  ];

    const statusTextList = {
    'NEXT_UP': 'NEXT UP',
    'CURRENTLY_IN_DOOR': 'CURRENTLY IN DOOR',
    'DONE': 'DONE',
    'REOPEN': 'REOPEN',
    // 'COMPLETED': 'COMPLETED',
    'SEND_DONE': 'SEND DONE',
  };

  const width = header.width;
//   const offsetWidth = traffic.headers.reduce((r, h, index) => {
//     if (h.visible && index < (headerIndex - 3)) {
//         r += h.width;
//     }
//     return r;
// }, 0);

const lastSelectId = traffic.lastSelected?.id;
const lastSelectHeaderKey = traffic.lastSelected?.headerKey;
const lastSelectenable = traffic.lastSelected?.enable;
const rowStateId = rowState.loadId;
const headerKey = header.key;
const [displayState, setDisplayState] = useState(redonly);
const [tempData, setTempData] = useState(null);
const [tempDataPriority, setTempDataPriority] = useState(null);
const [tempDataDelayed, setTempDataDelayed] = useState(null);
const [warningModalIsOpen, setWarningModalIsOpen] = useState(null);

useEffect(() => {
  if (lastSelectId === rowStateId && lastSelectHeaderKey === headerKey && lastSelectenable) {
      setDisplayState(enabled);
  }
}, [lastSelectId, lastSelectHeaderKey, lastSelectenable, rowStateId, headerKey]);

useEffect(() => {
  if (cellData.key === 'status' && resource === 'trafficUpcoming') {
    setDisplayState(editable);
  }  
}, [cellData.key, resource]);

useEffect(() => {
  if (cellData.key === 'status' && resource === 'traffic' && (cellData.statusValue !== 6 || cellData.statusValue !== 7)) {
    setDisplayState(editable);
  }  
}, [cellData.key, cellData.statusValue, resource]);

const cellStyle = cellData.cellStyle;
let data = (cellData.valueText && cellData.fieldType === 'text') ? cellData.valueText : cellData.value;
  useEffect(() => {
    setTempData(data);
    setTempDataPriority(cellData.priorityValue);
    setTempDataDelayed(cellData.delayedValue);
  }, [data, cellData.priorityValue, cellData.delayedValue, rowState.loadStatus]);

  const inputReference = useRef(null);

  const dataCompare = (newData, data) => {
    switch (cellData.fieldType) {
      case 'date':
      case 'dateTime':
        if (newData instanceof Date && data instanceof Date) {
          return newData.getTime() === data.getTime();
        }
        return newData === data;
      default:
        return newData === data;
    }
  };

  const setData = (value, subType) => {
    onSetBlur();
    let newValue = value;
    if (value !== '') {
      switch (cellData.fieldType) {
        case 'date':
          newValue = new Date(value);
          break;
        case 'dateTime':
          newValue = new Date(value);
          break;
        case 'text':
          newValue = value;
          break;
        case 'textInput':
          newValue = value;
          break;
        case 'select':
          newValue = value;
          break;
        case 'checkbox':
          newValue = value;
          break;
        default:
          newValue = value;
          break;
      }
    }
    const valueForCompare = subType === 'priority' ? cellData.priorityValue : subType === 'delayed' ? cellData.delayedValue : tempData;
    if (!dataCompare(newValue, valueForCompare)) {
      setTimeout(() => {
          if (resource === 'traffic') {
            setItemData(resource, newValue, rowState, header, subType);
          }
          if (resource === 'trafficUpcoming') {
            setItemDataUpcoming(resource, newValue, rowState, header, subType);
          }
      }, 100);
      setTimeout(() => {
        setTempData(newValue);
      }, 100);
    }
  };

  useEffect(() => {
    if (inputReference && inputReference.current) {
      inputReference.current.focus();
    }
  }, [displayState]);

  const updateStatus = value => {
    let loadStatus = rowState.loadStatus;
    let dontSend = null;
    if (cellData.key === 'status') {
      dontSend = true;
    } else if (cellData.key === 'status2' && loadStatus === 1) {
      loadStatus = 5; // CURRENTLY IN DONE
    } else if (cellData.key === 'status2' && loadStatus === 5) {
      loadStatus = 6; // SEND DONE
    } else if (cellData.key === 'status2' && loadStatus === 6 && value === 5) {
      loadStatus = 5; // CURRENTLY IN DOOR
    } else if (cellData.key === 'status2' && loadStatus === 6) {
      loadStatus = 7; // REOPEN
    } else if (cellData.key === 'status2' && loadStatus === 7) {
      loadStatus = 6; // DONE
    } else if (cellData.key === 'status3' && loadStatus === 6) {
      loadStatus = 8; // COMPLETED FOR DONE
    } else if (cellData.key === 'status3' && loadStatus === 7) {
      dontSend = true; // COMPLETED FOR REOPEN
    } else if (cellData.key === 'status3' && loadStatus === 5) {
      loadStatus = 1; // NEXT_UP
    };
    
    if (!dontSend) updateLoadStatus(resource, loadStatus, rowState, header, rowStateId);
  };

  useEffect(() => {
    return () => {
      setLoadNeedId(resource, '');
    };
  }, [setLoadNeedId, resource]);

  const handleOnclickInLoadNeeds = (elem) => {
    const isLoadInDone = doneTrafficList?.filter(load => load?.rowState?.loadId === elem.inId).length > 0;
    const isLoadInCurrentAndNext = currentAndNextTraffic?.filter(load => load?.rowState?.loadId === elem.inId).length > 0;

    if (isLoadInDone || isLoadInCurrentAndNext) {
      setLoadNeedId(resource, elem.inId);
    } else {
      setWarningModalIsOpen(true);
    }
  };

  const getData = () => {
    if (tempData !== '' && tempData !== null) {
      switch (cellData.fieldType) {
        case 'date':
          let localTime
          if (cellData.key === 'day' || cellData.key === 'whDay') {
            localTime = moment.utc(tempData).toDate();
            localTime = moment(localTime).format('MM/DD');
          }
          if (cellData.key === 'time' || cellData.key === 'in' || cellData.key === 'out' || cellData.key === 'whTime' || cellData.key === 'checkOut' || cellData.key === 'checkIn') {
            localTime = moment.utc(tempData).toDate();
            localTime = moment(localTime).format('HH:mm');
          }
          return localTime;
        case 'dateTime':
          return tempData ? moment(tempData).format(Defines.Format.PrintDateTime) : '';
        case 'text':
          if (cellData.key === 'inboundsNeeded') {
            if (!tempData.length) {
                return <div onClick={() => handleOnclickInLoadNeeds('')} style={{ cursor: "pointer"}}>&nbsp;</div>
            } else {
                return tempData.map(elem => {
                    const color = elem.status === 1 ? '#008000' : elem.status === 5 ? '#080E0E' : elem.status === 6 ? '#FF0000' : '';
                    return <span onClick={() => handleOnclickInLoadNeeds(elem)} key={elem.inLoadNum} style={{ color: color, cursor: "pointer"}}>In{elem.inLoadNum} </span>
                  });
                }
          }          
          if (cellData.key === 'status') {
            let className = '';
            let style = {};
            if (tempData === 'DONE') {
              className = 'btnDone';
              style = {
                ...style,
                color: rowState.loadStatus === 6 ? 'black' : 'white',
                fontWeight: rowState.loadStatus === 6 ? '600' : '400',
              }
            };
            if (tempData === "REOPEN") {
              className = 'btnReopen';
              style = {
                ...style,
                backgroundColor: rowState.loadStatus === 7 ? '#FFF8EF' : '#FF0000',
                fontWeight: rowState.loadStatus === 7 ? '600' : '400',
                color: cellData.color,
              }
            }
            
            return cellData.isBold ? <b>{statusTextList[`${tempData}`]}</b> : <button onClick={updateStatus} style={style} className={className}>{statusTextList[`${tempData}`]}</button>;
          };
          if (cellData.key === 'status2') {
            let className = '';
            let style = {};
            if (tempData === 'CURRENTLY_IN_DOOR') {
              style = {
                position: 'absolute',
                display: 'flex',
                padding: '0 16px',
                backgroundColor: '#D2D2D2',
                borderRadius: '4px',
                fontSize: '9px',
                color: '#ffffff',
                lineHeight: '12px',
                top: '4px',
                right: '24px',
              }  
            };
            if (tempData === 'DONE') {
              className = 'btnDone';
              style = {
                ...style,
                color: '#FFF8EF',
                backgroundColor: '#29CABA',
              }
            };
            if (tempData === "REOPEN") {
              className = 'btnReopen';
            }
            if (tempData === 'SEND_DONE') {
              className = 'btnCompleted';
            };
            return cellData.isBold ? <b>{statusTextList[`${tempData}`]}</b> : <button onClick={updateStatus} style={style} className={className}>{statusTextList[`${tempData}`]}</button>;
          };
          if (cellData.key === 'status3') {
            let className = '';
            let style = {};
            if (tempData === 'NEXT_UP') {
              className = 'btnCompleted';
              style = {
                ...style,
                backgroundColor: '#FF0000',
              };
            };
            if (tempData === 'COMPLETED') {
              className = 'btnCompleted';
              style = {
                ...style,
                backgroundColor: cellData.color,
              }
            };
            return cellData.isBold ? <b>{statusTextList[`${tempData}`]}</b> : <button onClick={updateStatus} style={style} className={className}>{statusTextList[`${tempData}`]}</button>;
          };
          return cellData.isBold ? <b>{tempData}</b> : tempData;
        case 'textInput':
          function getTextLength(text) {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            return context.measureText(text).width;
          }
          if (cellData.key === 'trafficNote' && getTextLength(tempData) > 70 ) {

            return <Tooltip title={tempData} placement="left">
              {tempData}
            </Tooltip>
          }
          return tempData;
        case 'select':
          if (cellData.key === 'eta') {
            return (
            <Select
              suffixIcon={<CaretDownFilled />}
              ref={inputReference}
              value={selectDoorsOptions[`${tempData}`]}
              className={'rowSelect-door'}
              onChange={handleSelectChange}
              defaultValue={selectDoorsOptions[`${tempData}`]}>
              {selectDoorsNameOptions.map((item, index) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>)
          } else if (cellData.key === 'truckVerified') { 
            return (
              <Select
                suffixIcon={<CaretDownFilled />}
                ref={inputReference}
                value={selectVerifiedOptions[`${tempData}`]}
                className={`rowSelect-verified ${cellData.color === '#FF0000' && 'red'} ${cellData.color === '#008000' && 'green'}`}
                onChange={handleSelectChange}
                defaultValue={selectVerifiedOptions[`${tempData}`]}>
                {selectVerifiedNameOptions.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>)
          } else if (cellData.key === 'status2' && rowState.loadStatus === 6) {
            return (
              <Select
                suffixIcon={<CaretDownFilled />}
                ref={inputReference}
                value={statusListSmallOptions[`${tempData}`]}
                className={'rowSelect-status2'}
                onChange={updateStatus}
                defaultValue={statusListSmallOptions[`${tempData}`]}>
                {statusListSmallNameOptions.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>)
          } else {
            return <></>
          };
        default:
          return tempData;
      }
    }
    return tempData;
  };

  const handleSelectChange = value => {
    setData(value);
  };

  const getInput = () => {
    switch (cellData.fieldType) {
      case 'dateTime':
        return (
          <DatePicker
            open={true}
            ref={inputReference}
            onBlur={value => {
              setTimeout(() => {
                if (inputReference.current) {
                  setDisplayState(redonly);
                }
              }, 0);
              setData(value.currentTarget.value);
            }}
            onChange={value => {
              if (inputReference.current) {
                setDisplayState(redonly);
              }
              setData(value === null ? '' : value._d);
            }}
            suffixIcon={null}
            showTime
            className='spreadsheet-picker'
            value={tempData ? moment(tempData, Defines.Format.PrintDateTime) : ''}
            format={Defines.Format.PrintDateTime}
            onKeyDown={e => {
              if (e.key === 'Escape') {
                if (inputReference.current) {
                  setDisplayState(redonly);
                }
              }
            }}
            showToday={false}
          />
        );
      case 'date':
        return (
          <DatePicker
            open={true}
            ref={inputReference}
            onBlur={value => {
              if (inputReference.current) {
                setDisplayState(redonly);
              }
              setData(value.currentTarget.value);
            }}
            onChange={value => {
              if (inputReference.current) {
                setDisplayState(redonly);
              }
              setData(value === null ? '' : value._d);
            }}
            suffixIcon={null}
            className='spreadsheet-picker'
            value={tempData ? moment(tempData, Defines.Format.PrintDate) : ''}
            format={Defines.Format.PrintDate}
            onKeyDown={e => {
              if (e.key === 'Escape') {
                if (inputReference.current) {
                  setDisplayState(redonly);
                }
              }
            }}
            showToday={false}
          />
        );
      case 'integer':
        return (
          <InputNumber
            type='number'
            min={0}
            ref={inputReference}
            value={tempData ? tempData : ''}
            onBlur={e => {
              if (inputReference.current) {
                setDisplayState(redonly);
              }
              setData(e.target.value);
            }}
            className='spreadsheet-number-input'
          />
        );
      case 'text': 
        return <button className='btnDone' onClick={updateStatus}>{statusTextList[`${tempData}`]}</button>;
      case 'textInput':
        return (
          <input
            defaultValue={tempData ? tempData : ''}
            ref={inputReference}
            onBlur={value => {
              setData(value.currentTarget.value);
              if (inputReference.current) {
                setTimeout(() => {
                  setDisplayState(redonly);
                }, 100);
              }
            }}
            format={Defines.Format.PrintDate}
            style={{ paddingLeft: '4px' }}
          />
        );
      case 'checkbox':
        if (cellData.key === 'status' && resource === 'trafficUpcoming') {
          return (
            <div style={{display: 'flex'}}>
              <Tooltip title="Priority" placement="right" >
                <label className="container">
                  <input
                    type="checkbox"
                    checked={tempDataPriority}
                    onClick={() => setData(!tempDataPriority, 'priority')}
                    />
                    <span className="checkmark checkmark-one priority"></span>
                </label>
              </Tooltip>
            </div>
          );
        }
        if (cellData.key === 'status' && resource === 'traffic') {
          return (
            <div style={{display: 'flex'}}>
              <Tooltip title="Delayed" placement="left" >
                <label className="container">
                  <input
                    type="checkbox"
                    defaultChecked={tempDataDelayed}
                    onClick={() => setData(!tempDataDelayed, 'delayed')}
                    />
                    <span className="checkmark delayed"></span>
                </label>
                </Tooltip>
                <Tooltip title="Priority" placement="right" >
                <label className="container">
                  <input 
                    type="checkbox"
                    defaultChecked={tempDataPriority}
                    onClick={() => setData(!tempDataPriority, 'priority')}
                    />
                    <span className="checkmark priority"></span>
                </label>
                </Tooltip>
            </div>
          );
        } else {
          return <></>;
        };
      case 'select':
        if (cellData.key === 'eta') {
          return (
            <Select
              suffixIcon={<CaretDownFilled />}
              ref={inputReference}
              value={selectDoorsOptions[`${tempData}`]}
              className={'rowSelect-door'}
              onChange={handleSelectChange}
              defaultValue={selectDoorsOptions[`${tempData}`]}>
              {selectDoorsNameOptions.map((item, index) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
        )} else if (cellData.key === 'truckVerified') {
          return (
            <Select
              suffixIcon={<CaretDownFilled />}
              ref={inputReference}
              value={selectVerifiedOptions[`${tempData}`]}
              className={`rowSelect-verified ${cellData.color === '#008000' && 'green'} ${cellData.color === '#FF0000' && 'red'} `}
              onChange={handleSelectChange}
              defaultValue={selectVerifiedOptions[`${tempData}`]}>
              {selectVerifiedNameOptions.map((item, index) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          )} else if (cellData.key === 'status2' && (rowState.loadStatus === 6)) {
          return (
            <Select
              suffixIcon={<CaretDownFilled />}
              ref={inputReference}
              value={statusListSmallOptions[`${tempData}`]}
              className={'rowSelect-status2'}
              onChange={updateStatus}
              defaultValue={statusListSmallOptions[`${tempData}`]}>
              {statusListSmallNameOptions.map((item, index) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
        )} else {
          return <></>
        };
      default:
        return (
          <input
            style={{ backgroundColor: 'yellow', boxSizing: 'inherit', width: '100%' }}
            value={getData()}
            ref={inputReference}
            onChange={event => setData(event.target.value)}
            onBlur={() => {
              if (inputReference.current) {
                setDisplayState(redonly);
              }
            }}
          />
        );
    }
  };

  const dataDisplay = () => displayState === redonly ? getData() : getInput();

  const cellDefaultStyle = {
      maxWidth: `${width}px`,
      width: `${width}px`,
      minWidth: `${width}px`,
      height: `${itemSize}px`,
      color: cellData.color,
      borderRight: (cellData.key === 'status2' || cellData.key === 'status3') && (rowState.loadStatus === 1 || rowState.loadStatus === 5) ? 'none' : '',
  };

  const onSetFocus = () => {
    // startOnScroll && startOnScroll(offsetWidth);
      if (cellData.editable) {
          setDisplayState(editable);
      }
      setItemDisplayState(resource, rowState, header, cellData.editable);
  };

  const onSetBlur = () => {
      setItemDisplayState(resource, rowState, header, false);
  };

  const editableFunction = (cellData) => cellData.editable ? setDisplayState(editable) : null;

  return (
    <>
      <>
      {warningModalIsOpen &&
        <WarningModal
            warningModalIsOpen={warningModalIsOpen}
            setWarningModalIsOpen={setWarningModalIsOpen}
        />
      }
      </>
      <div  
            onFocus={() => onSetFocus()}
            onClick={() => { editableFunction(cellData)} }
            className='table-body-cell'
            style={{ ...cellDefaultStyle, ...cellStyle }}
            tabIndex={displayState === redonly ? 0 : -1}>
          {dataDisplay()}
      </div>
    </>
);
};
export default connect(state => ({
  state: state
  }), dispatch => ({
  setItemData: (resource, value, rowState, header, subType) => dispatch(setItemData(resource, value, rowState, header, subType)),
  setItemDataUpcoming: (resource, value, rowState, header, subType) => dispatch(setItemDataUpcoming(resource, value, rowState, header, subType)),
  updateLoadStatus: (resource, newStatus, rowState, header, loadId) => dispatch(updateLoadStatus(resource, newStatus, rowState, header, loadId)),
  setItemDisplayState: (resource, rowState, header, enable) => dispatch(setItemDisplayState(resource, rowState, header, enable)),
  setLoadNeedId: (resource, id) => dispatch(setLoadNeedId(resource, id)),
}))(Cell);
