import { OUTBOUND_LOAD_BUILD as actionTypes } from '../../actions/loadArea/loadActionTypes';
import { getContentWidthExceptLeftAction } from './helper/general';
import * as loadArea from './loadArea';
//import {
//    extractWebRegionsToList,
//    createWebRegionArray,
//    extractWebLoadsToList,
//    createWebLoadArray
//} from './helper/inbound/data';

const allHeders = [
    {
        key: 'left_action',
        title: '',
        width: 20,
        leftAction: true,
        rightAction: false,
        visible: true
    },
    {
        key: 'id',
        title: 'ID',
        width: 40,
        leftAction: false,
        rightAction: false,
        visible: process.env.REACT_APP_SHOW_ID_FIELD === 'true'
    },
    {
        key: 'inboundDeliveryAPPT',
        title: 'Inbound Del Appt',
        width: 110,
        leftAction: false,
        rightAction: false,
        visible: true,
        pickGroup: true
    },
    {
        key: 'outboundPickIn',
        title: 'Pick IN',
        width: 110,
        leftAction: false,
        rightAction: false,
        visible: true,
        pickGroup: true,
        hideForLoadPlanning: true,
    },
    {
        key: 'outboundPickOut',
        title: 'Pick OUT',
        width: 110,
        leftAction: false,
        rightAction: false,
        visible: true,
        pickGroup: true,
        hideForLoadPlanning: true,
    },
    {
        key: 'outboundPickAppointment',
        title: 'Pick Appt',
        width: 110,
        leftAction: false,
        rightAction: false,
        visible: true,
        pickGroup: true
    },
    {
        key: 'outboundAppointmentStatus',
        title: 'Appt Status',
        width: 80,
        leftAction: false,
        rightAction: false,
        visible: false,
        dropGroup: true
    },
    {
        key: 'pickOutboundAppointmentStatus',
        title: 'Appt Status',
        width: 80,
        leftAction: false,
        rightAction: false,
        visible: false,
        dropGroup: true
    },
    {
        key: 'readyDate',
        title: 'Ready Date',
        width: 70,
        leftAction: false,
        rightAction: false,
        visible: true
    },
    {
        key: 'puDate',
        title: 'P/U Date',
        width: 60,
        leftAction: false,
        rightAction: false,
        visible: true
    },
    {
        key: 'dueDate',
        title: 'Due Date',
        width: 60,
        leftAction: false,
        rightAction: false,
        visible: true
    },
    {
        key: 'outboundDropAppointment',
        title: 'Del Appt',
        width: 110,
        leftAction: false,
        rightAction: false,
        visible: true,
        dropGroup: true
    },
    {
        key: 'deliverDate',
        title: 'D/L Date',
        width: 70,
        leftAction: false,
        rightAction: false,
        visible: true
    },
    {
        key: 'outboundDropIn',
        title: 'Del IN',
        width: 110,
        leftAction: false,
        rightAction: false,
        visible: true,
        dropGroup: true,
        hideForLoadPlanning: true,
    },
    {
        key: 'outboundDropOut',
        title: 'Del Out',
        width: 110,
        leftAction: false,
        rightAction: false,
        visible: true,
        dropGroup: true,
        hideForLoadPlanning: true,
    },
    {
        key: 'customerCodeOrName',
        title: 'Cust',
        width: 40,
        leftAction: false,
        rightAction: false,
        visible: true
    },
    {
        key: 'pickLocation',
        title: 'Pick',
        width: 200,
        leftAction: false,
        rightAction: false,
        visible: true
    },
    {
        key: 'dropLocation',
        title: 'Delivery',
        width: 200,
        leftAction: false,
        rightAction: false,
        visible: true
    },
    {
        key: 'dropState',
        title: 'State',
        width: 80,
        leftAction: false,
        rightAction: false,
        visible: true
    },
    {
        key: 'poNumber',
        title: 'PO#',
        width: 90,
        leftAction: false,
        rightAction: false,
        visible: true
    },
    {
        key: 'soNumber',
        title: 'SO#',
        width: 80,
        leftAction: false,
        rightAction: false,
        visible: true
    },
    {
        key: 'palletCount',
        title: 'Pal',
        width: 50,
        leftAction: false,
        rightAction: false,
        visible: true
    },
    {
        key: 'space',
        title: 'SPA',
        width: 50,
        leftAction: false,
        rightAction: false,
        visible: true
    },
    {
        key: 'cases',
        title: 'Cs',
        width: 50,
        leftAction: false,
        rightAction: false,
        visible: true
    },
    {
        key: 'weight',
        title: 'Lbs',
        width: 60,
        leftAction: false,
        rightAction: false,
        visible: true
    },
    {
        key: 'temperature',
        title: 'Temp',
        width: 50,
        leftAction: false,
        rightAction: false,
        visible: true
    },
    {
        key: 'dropRestriction',
        title: 'Sequence',
        width: 70,
        leftAction: false,
        visible: true
    },
    {
        key: 'loadNumber',
        title: 'Load #',
        width: 70,
        leftAction: false,
        rightAction: false,
        visible: true
    },
    {
        key: 'wk',
        title: 'WK',
        width: 50,
        leftAction: false,
        rightAction: false,
        visible: true
    },
    {
        key: 'in_load',
        title: 'In Load',
        width: 50,
        leftAction: false,
        rightAction: false,
        visible: true
    },
    {
        key: 'out_load',
        title: 'Out Load',
        width: 60,
        leftAction: false,
        rightAction: false,
        visible: true
    },
    // {
    //     key: 'carrier',
    //     title: 'Carrier',
    //     width: 80,
    //     leftAction: false,
    //     rightAction: false,
    //     visible: true
    // },
    {
        key: 'rate',
        title: 'Rate',
        width: 40,
        leftAction: false,
        rightAction: false,
        visible: true,
        hideForLoadPlanning: true,
    },
    {
        key: 'target',
        title: 'Target',
        width: 50,
        leftAction: false,
        rightAction: false,
        visible: true,
        hideForLoadPlanning: true,
    },
    {
        key: 'driver_truck_Trailer_VIN',
        title: 'Driver/Truck#/Trailer#/VIN#',
        width: 160,
        leftAction: false,
        rightAction: false,
        visible: true,
        hideForLoadPlanning: true,
    },
    {
        key: 'outboundLumper',
        title: 'Lumper',
        width: 50,
        leftAction: false,
        rightAction: false,
        visible: true
    },
    {
        key: 'efs',
        title: 'EFS',
        width: 40,
        leftAction: false,
        rightAction: false,
        visible: true,
        hideForLoadPlanning: true,
    },
    {
        key: 'notes',
        title: 'Notes',
        width: 60,
        leftAction: false,
        rightAction: false,
        visible: true
    },
    {
        key: 'outPickNummber',
        title: 'Pick up #',
        width: 60,
        leftAction: false,
        rightAction: false,
        visible: false
    },
    {
        key: 'outDeliverNummber',
        title: 'Del #',
        width: 50,
        leftAction: false,
        rightAction: false,
        visible: false
    },
        // the next for just load Datepicker fields update (we don't have such headers) 
    {
        key: 'lastOutboundPickFromData',
        title: '',
        width: 50,
        leftAction: false,
        rightAction: false,
        visible: false
    },
    {
        key: 'lastOutboundPickToData',
        title: '',
        width: 50,
        leftAction: false,
        rightAction: false,
        visible: false
    },
    {
        key: 'lastOutboundDropFromData',
        title: '',
        width: 50,
        leftAction: false,
        rightAction: false,
        visible: false
    },
    {
        key: 'lastOutboundDropToData',
        title: '',
        width: 50,
        leftAction: false,
        rightAction: false,
        visible: false
    },
    {
        key: 'actions',
        title: '',
        width: 80,
        leftAction: false,
        rightAction: true,
        visible: true
    }
];

const defaultState = {
    headers: allHeders.filter(h => h.visible),
    allHeaders: allHeders,
    regionHeaders: [
        {
            key: 'left_action',
            width: (headers) => (headers && headers.length > 0) ? headers[0].width : 0
        },
        {
            key: 'region_name',
            width: () => 200
        },
        {
            key: 'empty',
            width: (headers) => getContentWidthExceptLeftAction(headers) - 200
        }
    ],
    lastSelected: {
        id: null,
        headerKey: null,
        enable: false
    },
    orders: null,
    loads: null,
    regionsAvailableOrders: null,
    loadsAssignedOrders: null,
    loading: true,
    hasError: false,
    message: '',
    loadIdField: 'outboundLoadId',
    loadType: 'outbound',
    addNewInProcess: false,
    refreshInProcess: false,
    createInProcess: false,
    createSubInProcess: false,
    groupingPickUpdateFields: ['outboundPickIn', 'outboundPickOut', 'outboundPickAppointment'],
    groupingDropUpdateFields: ['outboundDropIn', 'outboundDropOut', 'outboundDropAppointment', 'outboundAppointmentStatus'],
    loadsSortedBy: '',
    filterOptions: {
        pick: 'All',
        drop: 'All',
        del: 'All',
        apptStatus: 'All',
        stateName: 'All',
    },
};


export default (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.SET_IN_LOADING:
            return loadArea.setInLoading(state);
        case actionTypes.LOAD:
            return loadArea.loadContent(state, action.data);
        case actionTypes.RELOAD_ON_ERROR:
            return loadArea.loadContent(state, action.data.responce, true, action.data.errorText ? action.data.errorText : 'Something go wrong, data reloaded!');
        case actionTypes.SET_HEADER_WIDTH:
            return loadArea.setHeaderWidth(state, action.data.ind, action.data.width);
        case actionTypes.REACTED_ASSIGNED:
            return loadArea.reactedAssigned(state, action.data.type, action.data.id);
        case actionTypes.REACTED_ASSIGNEDS:
            return loadArea.reactedAssigneds(state, action.data.type, action.data.ids);
        case actionTypes.REACTED_AVAILABLE:
            return loadArea.reactedAvailable(state, action.data.type, action.data.id);
        case actionTypes.REACTED_AVAILABLES:
            return loadArea.reactedAvailables(state, action.data.type, action.data.ids);
        case actionTypes.UNREACT_ORDERS:
            return loadArea.unreactOrders(state);
        case actionTypes.UNASSIGN_ORDERS:
            return loadArea.unassignOrders(state, action.data);
        case actionTypes.ASSIGN_ORDERS_TO_LOAD:
            return loadArea.assignOrdersToLoad(state, action.data.loadData, action.data.orders, action.data.openModal, action.data.locationObjects);
        case actionTypes.DELETE_LOAD:
            return loadArea.deleteLoad(state, action.data.rowState);
        case actionTypes.ADD_NEW_LOAD:
            return loadArea.addNewLoad(state, action.data.loadData);
        case actionTypes.ASSIGN_ORDERS_TO_NEW_LOAD:
            return loadArea.assignOrdersToNewLoad(state, action.data.orders, action.data.loadData);
        case actionTypes.ASSIGN_ORDERS_TO_NEW_LOAD_WITH_MODAL:
            return loadArea.assignOrdersToNewLoadWithModal(state, action.data.orders, action.data.openModalNewLoad);
        case actionTypes.SET_DATA:
            return loadArea.setData(state, action.data.value, action.data.rowState, action.data.header);
        case actionTypes.SET_LOAD_DATA:
            return loadArea.setLoadData(state, action.data.value, action.data.rowState, action.data.header);
        case actionTypes.SET_DISPLAY_STATE:
            return loadArea.setDisplayState(state, action.data.rowState, action.data.header, action.data.enable);
        case actionTypes.UPDATE_EFS_DATA:
            return loadArea.setDataEfs(state, action.data.value, action.data.rowState, action.data.header);
        case actionTypes.UPDATE_LOAD_DATA:
            return loadArea.updateLoadData(state, action.data);
        case actionTypes.RESET_ERROR:
            return loadArea.resetError(state);
        case actionTypes.CREATE_SUB_LOAD_ASSIGN_ORDERS:
            return loadArea.createSubLoadAssignOrders(state, action.data.orders, action.data.loadData, action.data.dropLocationId);
        case actionTypes.SET_ADD_NEW_IN_PROCESS:
            return loadArea.setAddNewInInProcess(state, action.data);
        case actionTypes.SET_REFRESH_IN_PROCESS:
            return loadArea.setRefreshInProcess(state, action.data);
        case actionTypes.SET_CREATE_IN_PROCESS:
            return loadArea.setCreateInProcess(state, action.data);
        case actionTypes.SET_CREATE_SUB_IN_PROCESS:
            return loadArea.setCreateSubInProcess(state, action.data);
        case actionTypes.SET_SORT_LOAD_BY_CARRIER:
            return loadArea.setSortLoadsByCarrier(state, action.data);
        case actionTypes.SET_SORT_LOAD_BY_PICK_APPTS:
            return loadArea.setSortLoadsByPickAppts(state, action.data);
        case actionTypes.SET_SORT_LOAD_BY_PICK_LOCATION:
            return loadArea.setSortLoadsByPickLocation(state, action.data);
        case actionTypes.SET_SORT_LOAD_BY_DROP_LOCATION:
            return loadArea.setSortLoadsByDropLocation(state, action.data);
        case actionTypes.SET_SORT_LOAD_BY_DELIVER_NUMBER:
            return loadArea.setSortLoadsByDeliverNumber(state, action.data);
        case actionTypes.FILTER_IN_OUT_ORDERS:
            return loadArea.filterInOutOrders(state, action.data);
        case actionTypes.GET_HISTORY:
            return loadArea.getHistoryList(state, action.data);
        case actionTypes.GET_HISTORY_LOADING:
            return loadArea.setHistoryLoading(state, action.data);
        case actionTypes.SET_RE_ORDER_LOAD:
            return loadArea.setReOrderLoad(state, action.data);
        default:
            return state;
    }
};