import {
    ElementTypes,
    ElementValidations,
    Action,
    ListDefaults,
    ColumnType,
    FilterType,
    GenerateToolBars,
    DefaultPagination,
    ColumnValidation,
    ComponentTypes
} from '../../config';

import TrailerTypeConfig from '../TrailerType/config';
import TrailerSizeConfig from '../TrailerSize/config';

import BlackReasonConfig from '../BlackReason/config';
import { CloneObject } from '../../helper/common';

const carrierListViewConfig = CloneObject(ListDefaults);
carrierListViewConfig.View.AllowNewButton = false;

export const CarrierTabs = [
    {
        Title: 'Truck',
        Resource: 'Truck',
        Master: 'number',
        Key: 'id',
        IsRest: true,
        ParentField: 'carrierId',
        UsePopup: true,
        List: {
            useLocalState: true,
            Defaults: ListDefaults,
            ShowRefreshButton: true,
            isHiddenSwitch: true,
            Search: '_all',
            Filter: {
                number: {
                    Type: FilterType.Text,
                    Title: 'Number'
                },
                licensePlate: {
                    Type: FilterType.Text,
                    Title: 'License Plate'
                },
                vinNumber: {
                    Type: FilterType.Text,
                    Title: 'VIN'
                }
            },
            Pagination: DefaultPagination,
            Columns: {
                number: {
                    Title: 'Truck Number',
                    Type: ColumnType.Text,
                    Sorted: 'ascend',
                    Width: 150
                },
                vinNumber: {
                    Type: ColumnType.Text,
                    Title: 'VIN',
                    Width: 150
                },
                licensePlate: {
                    Title: 'License Plate',
                    Type: ColumnType.Text,
                    Width: 150
                },
                comment: {
                    Title: 'Comment',
                    Type: ColumnType.Text,
                    Width: 250
                }
            },
            Actions: {
                Width: 60,
                Items: [
                    Action.Edit,
                    Action.Delete
                ]
            }
        },
        Form: {
            SectionGroups: [
                {
                    Sections: [
                        {
                            Fields: {
                                number: {
                                    Title: 'Truck Number',
                                    Field: ElementTypes.TextField(),
                                    Validations: [ElementValidations.Required()]
                                },
                                vinNumber: {
                                    Title: 'VIN',
                                    Field: ElementTypes.TextField(),
                                    Attributes: {
                                        input: {
                                            maxLength: 17
                                        }
                                    }
                                },
                                licensePlate: {
                                    Title: 'License Plate',
                                    Field: ElementTypes.TextField(),
                                    Attributes: {
                                        input: {
                                            maxLength: 20
                                        }
                                    }
                                },
                                comment: {
                                    Title: 'Comment',
                                    Field: ElementTypes.Comment()
                                }
                            }
                        }
                    ]
                }
            ],
            ToolBars: GenerateToolBars(['Save', 'SaveClose', 'Cancel'])
        }
    },
    {
        Title: 'Driver',
        Resource: 'Driver',
        Master: 'driverName',
        Key: 'id',
        IsRest: true,
        ParentField: 'carrierId',
        UsePopup: true,
        List: {
            useLocalState: true,
            Defaults: ListDefaults,
            ShowRefreshButton: true,
            isHiddenSwitch: true,
            Search: '_all',
            Pagination: DefaultPagination,
            Filter: {
                driverName: {
                    Type: FilterType.Text,
                    Title: 'Name'
                },
                driverPhone: {
                    Title: 'Phone',
                    Type: FilterType.Phone
                },
                expirationDate: {
                    Type: FilterType.Date,
                    Title: 'Expired'
                }
            },
            Columns: {
                driverName: {
                    Title: 'Name',
                    Type: ColumnType.Text,
                    Sorted: 'ascend',
                    Width: 150
                },
                driverPhone: {
                    Title: 'Phone',
                    Type: ColumnType.Phone,
                    Width: 150
                },
                expirationDate: {
                    Title: 'Expired',
                    Type: (text) => ColumnValidation.MonthExpiration(text, 1, ColumnType.Date, 'orange', 'red'),
                    Width: 150
                }
            },
            Actions: {
                Width: 60,
                Items: [
                    Action.Edit,
                    Action.Delete
                ]
            }
        },
        Form: {
            SectionGroups: [
                {
                    Sections: [
                        {
                            Title: null,
                            Fields: {
                                driverName: {
                                    Title: 'Name',
                                    Field: ElementTypes.TextField(),
                                    Validations: [ElementValidations.Required()]
                                },
                                driverPhone: {
                                    Title: 'Phone',
                                    Field: ElementTypes.Phone(),
                                    Validations: [ElementValidations.Required()]
                                },
                                driverEmail: {
                                    Title: 'Email',
                                    Field: ElementTypes.TextField(),
                                    Validations: [ElementValidations.Email()]
                                },
                                licenseNumber: {
                                    Title: 'License Number',
                                    Field: ElementTypes.TextField(),
                                    Validations: [ElementValidations.Required()]
                                },
                                expirationDate: {
                                    Title: 'Expiration Date',
                                    Field: ElementTypes.DateField(),
                                    Validations: [ElementValidations.Required(), ElementValidations.DateIsExpired('License Number')]
                                },
                                active: {
                                    Field: ElementTypes.CheckBox('Active'),
                                }
                            }
                        }
                    ]
                }
            ],
            ToolBars: GenerateToolBars(['Save', 'SaveClose', 'Cancel'])
        }
    },
    {
        Title: 'Trailer',
        Resource: 'Trailer',
        Master: 'number',
        Key: 'id',
        IsRest: true,
        ParentField: 'carrierId',
        UsePopup: true,
        List: {
            useLocalState: true,
            Defaults: ListDefaults,
            ShowRefreshButton: true,
            isHiddenSwitch: true,
            Search: '_all',
            Filter: {
                name: {
                    Type: FilterType.Text,
                    Title: 'Name'
                },
                vinNumber: {
                    Type: FilterType.Text,
                    Title: 'VIN'
                },
                trailerTypeId: {
                    Type: FilterType.Select,
                    Valuelist: '/api/TrailerType/valuelistempty',
                    Title: 'Trailer'
                },
                trailerSizeId: {
                    Type: FilterType.Select,
                    Valuelist: '/api/TrailerSize/valuelistempty',
                    Title: 'Size'
                },
                active: {
                    Type: FilterType.Radio,
                    Title: 'Active'
                }
            },
            Pagination: DefaultPagination,
            Columns: {
                name: {
                    Title: 'Name',
                    Type: ColumnType.Text,
                    Sorted: 'ascend',
                    Width: 150
                },
                vinNumber: {
                    Type: ColumnType.Text,
                    Title: 'VIN',
                    Width: 150
                },
                type: {
                    Title: 'Type',
                    Type: ColumnType.Text,
                    Width: 80
                },
                size: {
                    Title: 'Size',
                    Type: ColumnType.Text,
                    Width: 80
                },
                active: {
                    Title: 'Active',
                    Type: ColumnType.Bool,
                    Width: 80
                },
                notes: {
                    Type: FilterType.Text,
                    Title: 'Notes',
                    Width: 150,
                },
            },
            Actions: {
                Width: 60,
                Items: [
                    Action.Edit,
                    Action.Delete
                ]
            }
        },
        Form: {
            SectionGroups: [
                {
                    Sections: [
                        {
                            Fields: {
                                name: {
                                    Title: 'Name',
                                    Field: ElementTypes.TextField(),
                                    Validations: [ElementValidations.Required()]
                                },
                                vinNumber: {
                                    Title: 'VIN',
                                    Field: ElementTypes.TextField(),
                                    Attributes: {
                                        input: {
                                            maxLength: 17
                                        }
                                    }
                                },
                                trailerTypeId: {
                                    Title: 'Type',
                                    Field: ElementTypes.Generic(ElementTypes.ComboAdd, {
                                        dataSource: '/api/TrailerType/valuelistempty',
                                        addparams: {
                                            TitlePrefix: '',
                                            PopupConfig: TrailerTypeConfig
                                        },
                                        defaultValue: true,
                                        selectedValueType: 'type'
                                    }),
                                    Validations: [ElementValidations.Required()]
                                },
                                trailerSizeId: {
                                    Title: 'Size',
                                    Field: ElementTypes.Generic(ElementTypes.ComboAdd, {
                                        dataSource: '/api/TrailerSize/valuelistempty',
                                        addparams: {
                                            TitlePrefix: '',
                                            PopupConfig: TrailerSizeConfig
                                        },
                                        selectedValueType: 'size'
                                    }),
                                    Validations: [ElementValidations.Required()]
                                },
                                notes: {
                                    Title: 'Notes',
                                    Field: ElementTypes.Comment()
                                },
                                active: {
                                    Field: ElementTypes.CheckBox('Active'),
                                }
                            }
                        }
                    ]
                }
            ],
            ToolBars: GenerateToolBars(['Save', 'SaveClose', 'Cancel'])
        }
    },
    {
        Title: 'Rate Confirmation',
        Resource: 'CarrierRateConfirmation',
        Master: 'fileName',
        Key: 'id',
        IsRest: true,
        ParentField: 'carrierId',
        UsePopup: true,
        List: {
            useLocalState: true,
            Defaults: carrierListViewConfig,
            ShowRefreshButton: true,
            isHiddenSwitch: true,
            Search: '_all',
            Pagination: DefaultPagination,
            Columns: {
                guid: {
                    Title: 'Download',
                    Type: ColumnType.File,
                    FileConfig: {
                        FileFieldName: 'fileName',
                        Width: 40,
                        Height: 40
                    },
                    Width: 100,
                    Attributes: {
                        wrap: {
                            center: ''
                        }
                    }
                },
                loadNumber: {
                    Title: 'Load Number',
                    Type: ColumnType.Text,
                    Width: 120,
                    Attributes: {
                        wrap: {
                            left: ''
                        }
                    }
                },
                versionStr: {
                    Title: 'Version',
                    Type: ColumnType.Text,
                    Width: 120,
                    Attributes: {
                        wrap: {
                            left: ''
                        }
                    }
                },
                pick: {
                    Title: 'Pick',
                    Type: ColumnType.MultilineText,
                    Width: 200,
                    Attributes: {
                        wrap: {
                            left: ''
                        }
                    }
                },
                drop: {
                    Title: 'Drop',
                    Type: ColumnType.MultilineText,
                    Width: 200,
                    Attributes: {
                        wrap: {
                            left: ''
                        }
                    }
                },
                rate: {
                    Title: 'Rate',
                    Type: ColumnType.Currency,
                    Width: 120,
                    Attributes: {
                        wrap: {
                            left: ''
                        }
                    }
                },
                createDate: {
                    Title: 'Created Date',
                    Type: ColumnType.Date,
                    Width: 120,
                    Attributes: {
                        wrap: {
                            left: ''
                        }
                    }
                },
                fullName: {
                    Title: 'Created By',
                    Type: ColumnType.Label,
                    Width: 100,
                    Attributes: {
                        wrap: {
                            left: ''
                        }
                    }
                }
            }
        }
    }
];

export const CarrierFormConfig = {
    Form: {
        SectionGroups: [
            {
                Sections: [
                    {
                        Title: null,
                        Fields: {
                            name: {
                                Field: ElementTypes.TextField(),
                                Title: 'Name',
                                Validations: [ElementValidations.Required()]
                            },
                            Group1: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        dot: {
                                            Field: ElementTypes.TextField(),
                                            Title: 'DOT',
                                            Validations: [ElementValidations.IntField()]
                                        },
                                        mc: {
                                            Field: ElementTypes.TextField(),
                                            Title: 'MC Number',
                                            Validations: [ElementValidations.Required(), ElementValidations.IntField()]
                                        },
                                    },
                                },
                            ],
                            address: {
                                Field: ElementTypes.TextField(),
                                Title: 'Address',
                                Validations: [ElementValidations.Required()]
                            },
                            additionalAddress: {
                                Field: ElementTypes.TextField(),
                                Title: 'Additional Address',
                            },
                            Group2: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        city: {
                                            Field: ElementTypes.TextField(),
                                            Title: 'City',
                                            Validations: [ElementValidations.Required()]
                                        },
                                        stateId: {
                                            Title: 'State',
                                            Dependencies: ['city'],
                                            Validations: [ElementValidations.Required()],
                                            Field: ElementTypes.Generic(ElementTypes.SelectWithCache, [
                                                [ElementTypes.Params.dataSource, '/api/location/statekeyvaluelist/'],
                                                [ElementTypes.Params.parentValueType, 'countryId'],
                                                [ElementTypes.Params.reloadOnParentChange, true],
                                                [ElementTypes.Params.selectedValueType, 'stateCode'],
                                                [ElementTypes.Params.editability, o => o && o['countryId'] > 0]
                                            ])
                                        },
                                    },
                                },
                            ],
                            Group3: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        zipCode: {
                                            Field: ElementTypes.IntField(),
                                            Title: 'Zip code',
                                            Validations: [ElementValidations.Required()]
                                        },
                                        countryId: {
                                            Title: 'Country',
                                            Dependencies: ['stateId', 'city'],
                                            Validations: [ElementValidations.Required()],
                                            Field: ElementTypes.Generic(ElementTypes.SelectWithCache, {
                                                dataSource: '/api/location/countryvaluelist',
                                                selectedValueType: 'countryName'
                                            })
                                        },
                                    },
                                },
                            ],
                            mainContact: {
                                Field: ElementTypes.TextField(),
                                Title: 'Main Contact',
                            },
                            email: {
                                Field: ElementTypes.TextField(),
                                Title: 'Dispatch Email',
                                Validations: [ElementValidations.Required(), ElementValidations.Email()]
                            },
                            accountingEmail: {
                                Field: ElementTypes.TextField(),
                                Title: 'Accounting Email',
                                Validations: [ElementValidations.Email()]
                            },
                            Group4: [{
                                useSeparator: false,
                                Fields: {
                                    mainPhone: {
                                        Title: 'Main Phone',
                                        Field: ElementTypes.Phone(),
                                        Attributes: {
                                            input: {
                                                maxLength: 15
                                            }
                                        },
                                        Validations: [ElementValidations.Phone()]
                                    },
                                    mainPhoneExt: {
                                        fieldClasses: ['mini'],
                                        Title: 'Ext.',
                                        Field: ElementTypes.TextField()
                                    }
                                }
                            }],
                        }
                    },
                    {
                        Fields: {                            
                            insuranceCompany: {
                                Field: ElementTypes.TextField(),
                                Title: 'Insurance Company',
                            },
                            agency: {
                                Field: ElementTypes.TextField(),
                                Title: 'Insurance Agency',
                                Validations: [ElementValidations.OnlyLettersAndSpaceBetween()]
                            },
                            insuranceEmail: {
                                Field: ElementTypes.TextField(),
                                Title: 'Insurance Email',
                                Validations: [ElementValidations.Email()]
                            },
                            insurancePhone: {
                                Field: ElementTypes.Phone(),
                                Title: 'Insurance Phone',
                                Attributes: { input: {
                                                    maxLength: 15
                                                }
                                            },
                                Validations: [ElementValidations.Phone()]
                            },
                            commercialPolicy: {
                                Field: ElementTypes.TextField(),
                                Title: 'Commercial Policy #',
                                Validations: [ElementValidations.AlphanumericAndMinusSign()]
                            },
                            commercialPolicyExpirationDate: {
                                Field: ElementTypes.Generic(ElementTypes.DateField, {
                                    editability: o => o.commercialPolicy,
                                }),
                                Title: 'Expiration Date',
                                Validations: [  ElementValidations.DateIsExpired('Commercial Policy'),                                                
                                                ElementValidations.RequiredIf('', o => o.commercialPolicy)]
                            },
                            autoPolicy: {
                                Field: ElementTypes.TextField(),
                                Title: 'Auto Policy #',
                                Validations: [ElementValidations.AlphanumericAndMinusSign()]
                            },
                            autoPolicyExpirationDate: {
                                Field: ElementTypes.Generic(ElementTypes.DateField, {
                                    editability: o => o.autoPolicy,
                                }),
                                Title: 'Expiration Date',
                                Validations: [ ElementValidations.DateIsExpired('Auto Policy'), 
                                                ElementValidations.RequiredIf('', o => o.autoPolicy)
                                            ]
                            },
                            cargoPolicy: {
                                Field: ElementTypes.TextField(),
                                Title: 'Cargo Policy #',
                                Validations: [ElementValidations.AlphanumericAndMinusSign()]
                            },
                            cargoPolicyExpirationDate: {
                                Field: ElementTypes.Generic(ElementTypes.DateField, {
                                    editability: o => o.cargoPolicy,
                                }),
                                Title: 'Expiration Date',
                                Validations: [  ElementValidations.DateIsExpired('Cargo Policy'), 
                                                ElementValidations.RequiredIf('', o => o.cargoPolicy)]
                            },
                            coiGuid: {
                                Title: 'COI Upload',
                                Field: ElementTypes.Generic(ElementTypes.Avatar, {
                                    fileFieldName: 'coiFileName',
                                    noImage: true
                                }),
                                Dependencies: [
                                    ['coiFileName', 'originalName']
                                ],
                                Validations: [ElementValidations.Required()]
                            },
                            note: {
                                Title: 'Notes',
                                Field: ElementTypes.Comment(),
                                Attributes: {
                                    textarea: {
                                        note: ''
                                    }
                                }
                            },
                        }
                    },
                    {
                        Fields: {
                            factoringCompanyId: {
                                Title: 'Factoring Company',
                                Field: ElementTypes.Generic(ElementTypes.SelectWithCache, {
                                    dataSource: '/api/FactoringCompany/factoringcompanyvaluelist',
                                    selectedValueType: 'companyName',
                                })
                            },
                            Group5: [{
                                useSeparator: false,
                                Fields: {
                                    nca: {
                                        Field: ElementTypes.CheckBox('N.Ca'),
                                    },
                                    west: {
                                        Field: ElementTypes.CheckBox('West'),
                                    },
                                    midWest: {
                                        Field: ElementTypes.CheckBox('Mid West'),
                                    },
                                }
                            }],
                            Group6: [{
                                useSeparator: false,
                                Fields: {
                                    east: {
                                        Field: ElementTypes.CheckBox('East'),
                                    },
                                    southEast: {
                                        Field: ElementTypes.CheckBox('South East'),
                                    },
                                }
                            }],
                            Components: {
                                carrierPackage: {
                                    Component: ComponentTypes.CarrierPackage('id')
                                }
                            },
                            packageDate: {
                                Title: 'Carrier Package Date',
                                Field: ElementTypes.Generic(ElementTypes.DateField),
                                Validations: [ElementValidations.RequiredIf('', o => o.packageUploadGuid)]
                            },
                            packageUploadDate: {
                                Title: 'Carrier Package Upload Date',
                                Field: ElementTypes.Generic(ElementTypes.DateField),
                                Validations: [ElementValidations.RequiredIf('', o => o.packageDate), ElementValidations.DateShouldBeLater()]
                            },
                            Group: [{
                                Title: 'Carrier Package Attachment',
                                Fields: {
                                    packageUploadGuid: {
                                        fieldClasses: ['mini'],
                                        Field: ElementTypes.Generic(ElementTypes.Avatar, {
                                            fileFieldName: 'packageUploadFileName',
                                            noImage: true
                                        }),
                                        Dependencies: ['isApproved',
                                            ['packageUploadFileName', 'originalName']
                                        ],
                                        // Validations: [ElementValidations.Required()]
                                    },
                                    isApproved: {
                                        Title: '',
                                        Field: ElementTypes.Button({
                                            Action: 'RequestCarrierPacket',
                                            Template: 'CarrierPacket'
                                        },
                                        o => o.packageUploadGuid)
                                    },
                                },
                            }],
                            denyReason: {
                                Title: 'Deny Reason',
                                Field: ElementTypes.Comment(),
                                Validations: [ElementValidations.RequiredIf('', o => o.isApproved === false)],
                                Attributes: {
                                    textarea: {
                                        note: ''
                                    }
                                }
                            },
                        }
                    },
                    {
                        Fields: {
                            scheduleVerification: {
                                Field: ElementTypes.CheckBox('Verification', () => true, () => false)
                            },
                            verifiedByName: {
                                Title: 'Verified By',
                                Field: ElementTypes.LabelField(o => o.verifiedById)
                            },
                            verifiedDateTime: {
                                Title: 'Verified Date and Time',
                                Field: ElementTypes.DateTimeLabel(o => o.verifiedById)
                            },
                            active: {
                                Field: ElementTypes.CheckBox('Active'),
                            },
                            blackList: {
                                Field: ElementTypes.CheckBox('Black List', () => true, () => false)
                            },
                            blackByName: {
                                Title: 'Blacklist By',
                                Field: ElementTypes.LabelField(o => o.blackById)
                            },
                            blackDate: {
                                Title: 'Blacklist Date',
                                Field: ElementTypes.DateTimeLabel(o => o.blackById)
                            },
                            blackReasonId: {
                                //Field: ElementTypes.ComboAdd('/api/blackreason/valuelistempty', {
                                //    TitlePrefix: '',
                                //    PopupConfig: BlackReasonConfig
                                //}, null, false, o => o.blackList, () => false),
                                Field: ElementTypes.Generic(ElementTypes.ComboAdd, {
                                    dataSource: '/api/blackreason/valuelistempty',
                                    addparams: {
                                        TitlePrefix: '',
                                        PopupConfig: BlackReasonConfig,
                                    },
                                    displayability: o => o.blackList,
                                    editability: () => false,
                                    selectedValueType: 'blackReasonName'
                                }),
                                Title: 'Blacklist Reason',
                                Validations: [ElementValidations.Required()]
                            }
                        }
                    }
                ]
            }
        ],
        ToolBars: GenerateToolBars()
    }
};

export const CarrierListConfig = {
    List: {
        Defaults: ListDefaults,
        ShowRefreshButton: true,
        Search: '_all',
        Filter: {
            email: {
                Type: FilterType.Text,
                Title: 'Email'
            },
            mainPhone: {
                Type: FilterType.Phone,
                Title: 'Main Phone'
            },
            accountNumber: {
                Type: FilterType.Text,
                Title: 'Account Number'
            }
        },
        Pagination: DefaultPagination,
        Columns: {
            name: {
                Type: ColumnType.Text,
                Title: 'Name',
                Sorted: 'ascend',
                LinkAppPage: true,
                DataTitle: false,
                Width: 150
            },
            email: {
                Type: ColumnType.Text,
                Title: 'Email',
                Width: 180
            },
            mainPhone: {
                Type: ColumnType.Phone,
                Title: 'Main Phone',
                Width: 180
            },
            dot: {
                Type: ColumnType.Text,
                Title: 'DOT',
                Width: 150
            },
            mc: {
                Type: ColumnType.Text,
                Title: 'MC Number',
                Width: 150
            },
            note: {
                Type: ColumnType.Text,
                Title: 'Note',
                Width: 150
            }
        },
        Actions: {
            Width: 60,
            Items: [
                Action.Delete
            ]
        }
    }
};

const Carrier = {
    Title: 'Carrier',
    Resource: 'Carrier',
    Master: 'name',
    Key: 'id',
    IsRest: true,
    ...CarrierListConfig,
    Form: {
        Tabstrip: [
            {
                Title: 'Carrier',
                Resource: 'Carrier',
                Master: 'name',
                Key: 'id',
                IsRest: true,
                ...CarrierFormConfig
            },
            ...CarrierTabs
        ]
    }
};

export default Carrier;