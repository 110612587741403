import React, { useMemo } from 'react';
import { Dropdown, message } from 'antd';
import { connect } from "react-redux";
import store from '../../../../store';
import axios from '../../../axios/axios';

import Logo from '../logo';
import MainNavigation from '../navigation/mainNavigation';
import UserMenu from '../userMenu/userMenu';
import { PopupTypes } from '../../custom/popup/popup';
import Organization from '../../../screens/Organization/config';
import { Profile as UserProfile, ChangePassword } from '../../../screens/UserProfile/config';
import { openPopup, closePopup, closePopupAsync } from '../../../../store/actions/popup/popup';
import { updateProfile } from '../../../../store/actions/authentication/authentication';
import { removeValueList } from '../../../../store/actions/valueList/valueList';
import { CloneObject } from '../../../helper/common';
import { useCacheHub } from '../../../hook/hub/useClientHub';
import { ValueListUrls } from '../../../config/list.config';

const MainToolbar = props => {
    const openChangePassword = () => {
        const windowKey = "wndChangePassword";
        ChangePassword.Form.DefaultId = store.getState().auth.userId;
        props.openPopup({
            windowKey,
            fullScreen: false,
            title: "Change Password",
            type: PopupTypes.ConfigForm,
            bodyProps: { windowKey },
            config: ChangePassword,
            changeAction(model) {
                axios({
                    url: `${ChangePassword.Resource}/changepassword`,
                    method: 'POST',
                    data: {
                        id: model.id,
                        oldPassword: model.oldPassword,
                        password: model.password,
                        confirmPassword: model.confirmPassword
                    },
                })
                    .then((response) => {
                        response && message.success(response.data.message);
                    }).finally(() => {
                        props.closePopup(windowKey);
                    })
            },
            closeAction: () => {
                props.closePopup(windowKey);
            }
        });
    };

    const profiles = {
        openOrgProfile() {
            const windowKey = "wndOrgProfile";
            props.openPopup({
                windowKey,
                fullScreen: false,
                title: "Edit",
                type: PopupTypes.ConfigForm,
                bodyProps: { windowKey },
                config: Organization,
                saveAction: () => {
                    props.closePopup(windowKey);
                },
                closeAction: () => {
                    props.closePopup(windowKey);
                }
            });
        },
        openUserProfile() {
            const windowKey = "wndUserProfile";
            const storeData = store.getState().auth;
            const ProfileConfig = CloneObject(UserProfile);
            ProfileConfig.Form.DefaultId = storeData.userId;
            if (storeData.userRole === 'Customer') {
                ProfileConfig.Form.SectionGroups.forEach(sg => {
                    sg.Sections.forEach(s => {
                        for (const propName in s.Fields) {
                            if (propName === 'loginName' || propName === 'workPhone') {
                                delete s.Fields[propName];
                            }
                            if (propName === 'email') {
                                s.Fields[propName].Title = 'Login'
                            }
                        }
                    });
                })
            }
            props.openPopup({
                windowKey,
                fullScreen: false,
                title: "Profile",
                type: PopupTypes.ConfigForm,
                bodyProps: { windowKey },
                config: ProfileConfig,
                saveAction: (data) => {
                    props.closePopupAsync(windowKey);

                    const newState = {
                        loginName: data.loginName,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email,
                    };
                    props.updateProfile(newState);
                },
                forgotAction() {
                    openChangePassword();
                },
                closeAction: () => {
                    props.closePopup(windowKey);
                }
            });
        }
    }
    
    useCacheHub((message) => {
        if(message?.cacheName) {
            props.removeValueList(ValueListUrls[message.cacheName]);
        }
    }, 'notification');

    return (
        <toolbar id="mainHeader" invert="" className="main_header">
            <Logo />
            <separator vertical="vertical"></separator>
            {useMemo(() => <MainNavigation
                managedMenu={props.managedMenu}
                location={props.location}
                closeAllPopups={props.closeAllPopups} />, [props.closeAllPopups, props.location, props.managedMenu])}
            <Dropdown
                className=""
                trigger={['click']}
                overlay={UserMenu(profiles)}
                placement="topRight">
                <div
                    command="userMenu"
                    effect="material"
                    className="button adaptive">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 4C11.93 4 13.5 5.57 13.5 7.5C13.5 9.43 11.93 11 10 11C8.07 11 6.5 9.43 6.5 7.5C6.5 5.57 8.07 4 10 4ZM10 18C7.97 18 5.57 17.18 3.86 15.12C5.55 13.8 7.68 13 10 13C12.32 13 14.45 13.8 16.14 15.12C14.43 17.18 12.03 18 10 18Z" fill="white"/>
                        </svg>
                        <text>{props.userFLName}</text>
                </div>
            </Dropdown>
        </toolbar>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        openPopup: (data) => dispatch(openPopup(data)),
        closePopup: (key) => dispatch(closePopup(key)),
        closePopupAsync: (key) => dispatch(closePopupAsync(key)),
        updateProfile: (data) => dispatch(updateProfile(data)),
        removeValueList: (valueKey) => dispatch(removeValueList(valueKey))
    };
};

export default connect(null, mapDispatchToProps)(MainToolbar);
