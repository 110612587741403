import React from 'react';
import AuthenticatedLink from './authenticatedLinkDeliveredTrucks';

const Uploader = ({ rowState, setData, setUploadModalIsOpen, onlyDownload, headerWidth }) => {
  const orderGuid = rowState.loadData.loadType === 1 ? rowState.orderData.callCheckInAttachment?.guid : rowState.loadData.loadType === 3 ? rowState.orderData.callCheckOutAttachment?.guid : '';

  const onChange = result => {
    let callCheckAttachment;
    if (result === null) {
      const imageId = rowState.loadData.loadType === 1 ? rowState.orderData.callCheckInAttachment.id : rowState.loadData.loadType === 3 ? rowState.orderData.callCheckOutAttachment.id : '';
      callCheckAttachment = {
        id: imageId,
        guid: orderGuid,
        isNew: false,
        deleted: true,
      }
    }
    setData(callCheckAttachment);
  };

  const uploadButton = (
    <div>
      <div style={{ display: 'flex', cursor: 'pointer' }}>
        <div style={{ margin: '3px 4px 0 0' }}>
          <svg width='14' height='17' viewBox='0 0 18 22 ' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M2.4 7H3.99V12C3.99 12.55 4.44 13 4.99 13H8.99C9.54 13 9.99 12.55 9.99 12V7H11.58C12.47 7 12.92 5.92 12.29 5.29L7.7 0.700001C7.31 0.310001 6.68 0.310001 6.29 0.700001L1.7 5.29C1.07 5.92 1.51 7 2.4 7ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z'
              fill='#626D6D'
            />
          </svg>
        </div>
        <span>Upload</span>
      </div>
    </div>
  );

  const fileName = rowState.loadData.loadType === 1 ? rowState.orderData.callCheckInAttachment?.fileName : rowState.loadData.loadType === 3 ? rowState.orderData.callCheckOutAttachment?.fileName : '';
  if(!onlyDownload){
    if (fileName) {
      return (
          <>
            <AuthenticatedLink url={`/attachment/download/${orderGuid}`} filename={fileName}>
              <span className='file-name' style={{ width: headerWidth - 26 }}>{fileName}</span>
            </AuthenticatedLink>
            <span onClick={() => onChange(null)} className='x-btn'>X</span>
          </>
      );
    } else {
      return <div onClick={() => setUploadModalIsOpen(true)} style={{ display: 'flex', justifyContent: 'center' }}>{uploadButton}</div>;
    }
  } else {
    if(fileName){
      return (
          <AuthenticatedLink url={`/attachment/download/${orderGuid}`} filename={fileName}>
            <span className='file-name' style={{ width: headerWidth - 26 }}>{fileName}</span>
          </AuthenticatedLink>
      )
    }
  }

};

export default Uploader;
