import { EmailTest, EmailListTest, TimeTest, PhoneTest, ColumnMonthExpiration, ColumnValues } from '../helper/validation';

export const getDatesDifference = data => Math.ceil((new Date(data) - new Date()) / (1000 * 3600 * 24));

export const ElementValidations = {
    Required: (message = '') => (data, fieldName) => !((data || data === 0 || data === false) && ((typeof data === 'string' || typeof data === 'boolean' || (typeof data === 'number' && data > 0)) && String(data)?.trim() !== '')) && (message || `${fieldName} is required`),
    RequiredForSpace: (message = '') => (data, fieldName) => !((data || data === 0) && ((typeof data === 'string' || typeof data === 'boolean' || (typeof data === 'number')) && String(data)?.trim() !== '')) && (message || `${fieldName} is required`),
    RequiredIfNew: (key = 'ID', message = '') => (data, fieldName, o) => (o && o[key] === 0 && !(data && data !== '')) && (message || `${fieldName} is required`),
    RequiredIf: (message = '', condition) => (data, fieldName, o) => !((data || data === 0) && data !== '') && condition(o, data) && (message || `${fieldName} is required`),
    MinLength: (min, message = '') => (data, fieldName) => !(!data || (data && data.length >= min)) && (message || `Min length for ${fieldName} is ${min}`),
    MaxLength: (max, message = '') => (data, fieldName) => !(!data || (data && data.length <= max)) && (message || `Max length for ${fieldName} is ${max}`),
    MinValue: (min, message = '') => (data, fieldName) => !(!data || (data && data >= min)) && (message || `Min value for ${fieldName} is ${min}`),
    MaxValue: (max, message = '') => (data, fieldName) => !(!data || (data && data <= max)) && (message || `Max value for ${fieldName} is ${max}`),
    Email: (message = '') => (data, fieldName) => !(!data || (data && EmailTest(data))) && (message || `Field ${fieldName} should be an email`),
    EmailList: (message = '') => (data) => !(!data || (data && EmailListTest(data))) && (message || `Field should be comma separated email addresses`),
    Phone: (message = '') => (data, fieldName) => !(!data || (data && PhoneTest(data))) && (message || `Field ${fieldName} should be a phone number`),
    PasswordConfirm: (confirmField, message = '') => (data, fieldName, o) =>
        (o && o[confirmField] !== data) && (message || `${fieldName} not matching`),
    IntField: (message = '') => (data, fieldName) =>
        !(!data || (data && Number.isInteger(Number(data)))) && (message || `Field ${fieldName} should be whole number`),
    IntPositiveField: (message = '') => (data, fieldName) =>
        !(!data || (data && Number.isInteger(Number(data)) && data >= 0)) && (message || `Field ${fieldName} should be whole number and can't be negative`),
    PositiveField: (message = '') => (data, fieldName) => {
            data += '';
            data = +(data.replace(/,/g, ''));
        return !(!data || (data && data >= 0)) && (message || `Field ${fieldName} can't be negative`)
    },
    Time: (message = '') => (data, fieldName) => {
        return !(!data || (data && TimeTest(data))) && (message || `Field ${fieldName} should be in HH:MM format`);
    },
    Alphanumeric: (message = '') => (data, fieldName) => {
        return /[^0-9a-zA-Z]/.test(data) && (message || `Field ${fieldName} should be alphanumeric`);
    },
    AlphanumericAndMinusSign: (message = '') => (data, fieldName) => {
        return /[^0-9a-zA-Z-]/.test(data) && (message || `Field ${fieldName} should be alphanumeric`);
    },
    NoEmptySpace: (message = '') => data => (data && data.indexOf(' ') >= 0) && (message || `Field can't contain empty space`),
    OnlyLetters: (message = '') => data => (data && /[^a-z]/i.test(data)) && (message || `Field should contain only letters`),
    OnlyLettersAndSpace: (message = '') => data => !(data && /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/.test(data) && String(data)?.trim() !== '') && (message || `Field should contain only letters`),
    OnlyLettersAndSpaceBetween: (message = '') => data => (data && !(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/.test(data))) && (message || `Field should contain only letters`),
    DateShouldBeLater: (message = '') => (data, fieldName, o) => ((data || data === 0) && data !== '') && (new Date(o.packageDate) > new Date(o.packageUploadDate)) && (message || `${fieldName} must be no sooner than Carrier Package Date`),
    DateIsExpired: (type, message = '') => data => 
        (data && getDatesDifference(data) < 11) && 
        (message || getDatesDifference(data) > 0 ? 
        `Expiration date of ${type} should be more than 10 days` :
        `Your license is expired`),
};

export const ColumnValidation = {
    MonthExpiration: (text, month, type, warningClass, errorClass) => text ? ColumnMonthExpiration(text, month, type, warningClass, errorClass) : [type, null],
    MaxValue: (message, type, condition, dependency) =>
        (data, title, object) => message ? ColumnValues(type, message, data, condition, dependency, object) : [type, null]
};

export const ColumnWarnings = {
    DateCompare: (message, type, condition, dependency, fieldType) =>
    (data, title, object) => message ? ColumnValues(type, message, data, condition, dependency, object, fieldType) : [type, null]
}