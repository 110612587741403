import React, { useState, useRef, useEffect } from 'react';
import { connect } from "react-redux";
import {Link, useLocation} from 'react-router-dom';
import moment from 'moment';
import { Defines } from '../../../../project/Defines';
import { DatePicker, InputNumber, Select, Tooltip, Popover } from 'antd';
import { setItemData, setItemDisplayState, billButtonAction, getOrderChangeRequestData, setOrderId, setChecked } from '../../../../store/actions/accountReceivableActions/accountReceivableActions';
import { enabled } from 'store/dist/store.legacy';
import { GenerateMessages } from '../../../helper/element';
import Uploader from './Uploader';
import './index.css';
import AuthenticatedLink from './authenticatedLinkAcoountReceivable';

const Cell = ({
        state,
        cellData,
        itemSize,
        billButtonAction,
        rowState,
        setItemData,
        setItemDisplayState,
        resource,
        setUploadRowState,
        setUploadModalIsOpen,
        setCommentsModalIsOpen,
        setChangeRequestModalIsOpen,
        getOrderChangeRequestData,
        setChangeRequestAcceptModalIsOpen,
        setOrderId,
        setChecked,
        userRole,
        setPriceRecalculationModalIsOpen,
        priceIsCalculated,
        }) => {
    const loadArea = state[resource];
    const redonly = 1;
    const editable = 2;
    const header = loadArea.headers.find(h => h.key === cellData.key);
    // const headerIndex = loadArea.headers.findIndex(h => h.key === cellData.key);
    const selectNameOptionsStatus = [{ name: '-', id: 0 }, { name: 'On time', id: 1 }, { name: 'Delayed', id: 2 }];
    const selectNameOptionsReason = [{ name: '-', id: 0 }, { name: 'Mechanical', id: 1 }, { name: 'Delivery conflict', id: 2 }, { name: 'Internal reason', id: 3 }, { name: 'Weather', id: 4 }, { name: 'Other', id: 5 } ];
    const selectNameOptionsPOD = [{ name: '-', id: 0 }, { name: 'Received', id: 1 }, { name: 'Requested', id: 2 }, { name: 'Don’t Split', id: 3}];
    const selectNameOptionsPODStatus = [ { name: 'Delivered', id: 'Delivered' }, { name: 'Shortage', id: 'Shortage' }, { name: 'Overage', id: 'Overage' }, { name: 'Reject', id: 'Reject' }];
    const selectOptionsStatus = { 0: '-', 1: 'On time', 2: 'Delayed' };
    const selectOptionsReason = { null: '-', 0: '-', 1: 'Mechanical', 2: 'Delivery conflict', 3: 'Internal reason', 4: 'Weather', 5: 'Other' };
    const selectOptionsPOD = { null: '-', 0: '-', 1: 'Received', 2: 'Requested', 3: 'Don’t Split' };
    const selectOptionsPalletTypes = { null: '', 1: 'Straight', 2: 'Chimney', 3: 'Other', 4: 'Sideways' };
    const selectOptionsPODStatus = { 'Delivered': 'Delivered', 'Shortage': 'Shortage', 'Overage': 'Overage', 'Reject': 'Reject' };
    const orderGuid =  rowState?.orderData?.callCheckOutAttachment?.guid;
    const isAdmin = userRole === 'Admin';

    const width = header.width;
    // const offsetWidth = loadArea.headers.reduce((r, h, index) => {
    //     if (h.visible && index < (headerIndex - 3)) {
    //         r += h.width;
    //     }
    //     return r;
    // }, 0);
    const lastSelectId = loadArea.lastSelected.id;
    const lastSelectHeaderKey = loadArea.lastSelected.headerKey;
    const lastSelectenable = loadArea.lastSelected.enable;
    const rowStateId = rowState.id;
    const headerKey = header.key;
    const [displayState, setDisplayState] = useState(redonly);
    const [tempData, setTempData] = useState(null);

    useEffect(() => {
        if (lastSelectId === rowStateId && lastSelectHeaderKey === headerKey && lastSelectenable) {
            setDisplayState(enabled);
        }
    }, [lastSelectId, lastSelectHeaderKey, lastSelectenable, rowStateId, headerKey]);
    const {pathname} = useLocation()

    useEffect(() => {
        if ((cellData.key === 'orderStatusByCall' ||
            cellData.key === 'delayedReason' ||
            cellData.key === 'loadCallCheckNote' ||
            cellData.key === 'podDoc' ||
            cellData.key === 'orderNotesByCall' ||
            cellData.key === 'podRequestStatus')
            &&
            resource === "AccountReceivable" && pathname === '/ar/deliverednopod') {
            setDisplayState(editable);
        }
    }, [cellData.key, pathname, resource]);

    const data = cellData.value;
    const podNotesHistory = cellData?.value2;
    const cellStyle = cellData.cellStyle;
    const tooltip = cellData.tooltip;
    const color = rowState.type === 'load' ? 'white' : '#101C1C';

    useEffect(() => {
        setTempData(data);
    }, [data]);

    const inputReference = useRef(null);
    const dataCompare = (newData, data) => {
        switch (cellData.fieldType) {
            case 'date':
            case 'dateTime':
                if (newData instanceof Date && data instanceof Date) {
                    return newData.getTime() === data.getTime();
                }
                return newData === data;
            default:
                return newData === data;
        }
    }

    const setData = value => {
        onSetBlur();
        let newValue = value;
        if (value !== '') {
            switch (cellData.fieldType) {
                case 'date':
                    newValue = new Date(value);
                    break;
                case 'dateTime':
                    newValue = new Date(value);
                    break;
                case 'text':
                    newValue = value;
                    break;
                case 'checkbox':
                    newValue = value;
                    break;
                default:
                    newValue = value;
                    break;
            }
        }
        if (!dataCompare(newValue, tempData)) {
            setTempData(newValue);  
            setTimeout(() => {
                setItemData(resource, newValue, rowState, header);
            }, 100);
        }
    };
    const changeImage =  (data) => {
        setTimeout(() => {
            setUploadRowState(rowState);
        }, 100);
    }
    const billButtonClick = () => {
        billButtonAction(resource, "bill", rowState)
    }

    const handleEdit = () => {
        setTimeout(() => {   
            setUploadRowState(rowState);
            setCommentsModalIsOpen(true);
        }, 100);
    };

    const handleChangeRequestModalOpen = () => {
        const orderId = rowState.orderObjectId;
        setTimeout(() => {
            setChangeRequestModalIsOpen(true);
            getOrderChangeRequestData(resource, orderId);
        }, 100);
    };

    const handleChangeRequestAcceptModalOpen = () => {
        const orderId = rowState.orderObjectId;
        setTimeout(() => {
            setChecked(resource, orderId);
            setOrderId(resource, orderId);
            setChangeRequestAcceptModalIsOpen(true);
        }, 100);
    };

    const handleRecalculation = () => {
        setPriceRecalculationModalIsOpen(true);
    };

    const getData = () => {        
        if (tempData !== '') {
            switch (cellData.fieldType) {
                case 'date':
                    return tempData ? moment(tempData).format(Defines.Format.PrintDate) : '';
                case 'button':
                    if (header.key === 'changeHistory') {
                        return <button className="ar-bg__button calculation-button" onClick={handleChangeRequestModalOpen}>{tempData}</button>;
                    } else if (header.key === 'recalculation') {
                        return <button className={`ar-bg__button calculation-button ${isAdmin ? '' : 'btn-disabled'}`} disabled={!isAdmin} onClick={handleRecalculation}>{tempData}</button>;
                    } else if (header.key === 'confirm') {
                        return <button className={`ar-bg__button calculation-button ${isAdmin ? '' : 'btn-disabled'}`} disabled={!isAdmin} onClick={handleChangeRequestAcceptModalOpen}>{tempData}</button>;
                    } else {
                        return <button className="revision__accept-button" onClick={billButtonClick}>{tempData}</button>;
                    }
                case 'buttonText':
                    return <button className="revision__accept-button mod-disabled">{tempData}</button>;
                case 'dateTime':
                    return <div className='date-time__container'>
                        <div className='date__part'>{tempData ? moment(tempData).format(Defines.Format.Date) : ''}</div>
                        <div className='time__part'>{tempData ? moment(tempData).format(Defines.Format.Time) : ''}</div>
                    </div>;
                case 'link':
                    return <Link style={{ color, textDecoration: 'underline' }} to={'/orderprocess/outboundloadbuld'}>{tempData}</Link>
                case 'text':
                    if (header.key === 'loadPositionType') {
                        return selectOptionsPalletTypes[tempData];
                    } else if (header.key === 'price') {
                        return <span className={`${priceIsCalculated.includes(rowState.orderData.id) ? 'red-price' : ''} `}>{tempData}</span>
                    }
                    else {
                        return tempData;
                    }
                case 'actions':
                    return (
                        <div className='edit_icons-callcheck'>
                        <div onClick={handleEdit}>
                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.1965 6.02L12.1165 6.94L3.05647 16H2.13647V15.08L11.1965 6.02ZM14.7965 0C14.5465 0 14.2865 0.1 14.0965 0.29L12.2665 2.12L16.0165 5.87L17.8465 4.04C18.2365 3.65 18.2365 3.02 17.8465 2.63L15.5065 0.29C15.3065 0.09 15.0565 0 14.7965 0ZM11.1965 3.19L0.136475 14.25V18H3.88647L14.9465 6.94L11.1965 3.19Z" fill="#3C8680"/>
                            </svg>
                        </div>
                        </div>
                    );
                case 'download':
                    const fileName =  rowState.orderData.callCheckOutAttachment?.fileName;
                    return <div style={{position: 'relative'}}>
                        {fileName?
                            <AuthenticatedLink url={`/attachment/download/${orderGuid}`} filename={fileName}>
                                <span className='file-name' style={{ width: header.width - 26 }}>{fileName}</span>
                            </AuthenticatedLink>
                            : ''}
                    </div>
                case 'popover':
                    return (
                        <Popover content={content} title="POD status list">
                            {tempData}
                        </Popover>
                    );
                default:
                return tempData;
            }
        }
        return tempData
    };

    const handleSelectChange = (value) => {
        if (header.key === 'orderNotesByCall') {
            setData(value);
        } else {
            setData(parseInt(value));
        }
    };

    let splittedData = [];
    if (header.key === 'orderNotesByCall' && podNotesHistory && podNotesHistory?.[0]) {
        splittedData = podNotesHistory?.split(',');
    };

    const content = splittedData.length ? (
        <div>
            <table className='pod-status-modal__table'>
                <tr className='pod-status-modal__tr'>
                    <th className='pod-status-modal__th'>
                        Created By &nbsp; &nbsp; &nbsp; Data Time &nbsp; &nbsp; Status note</th>
                </tr>
                {splittedData?.map(data => (
                    <tr className='pod-status-modal__tr' >
                        <td className='pod-status-modal__td'>{data}</td>
                    </tr>
                ))}
            </table>
        </div>
    ) : <div>You don't have any notes</div>;

    const getInput = () => {
        switch (cellData.fieldType) {
            case 'dateTime':
                return <DatePicker 
                    open={true}
                    ref={inputReference}
                    onBlur={(value) => {
                        setTimeout(() => {
                            if (inputReference.current) {
                                setDisplayState(redonly);
                            }
                        }, 0);
                        setData(value.currentTarget.value)
                    }}
                    onChange={(value) => {
                        if (inputReference.current) {
                            setDisplayState(redonly);
                        }
                        setData(value === null ? '' : value._d)
                    }}
                    suffixIcon={null}
                    showTime
                    minuteStep={15}
                    className='spreadsheet-picker'
                    value={tempData ? moment(tempData, Defines.Format.PrintDateTime) : ''}
                    format={Defines.Format.PrintDateTime}
                    onKeyDown={e => {
                        if (e.key === 'Escape') {
                            if (inputReference.current) {
                                setDisplayState(redonly);
                            }
                        }
                    }}
                    showToday={false} />;
            case 'date':
                return <DatePicker
                    open={true}
                    ref={inputReference}
                    onBlur={(value) => {
                        if (inputReference.current) {
                            setDisplayState(redonly);
                        }
                        setData(value.currentTarget.value)
                    }}
                    onChange={(value) => {
                        if (inputReference.current) {
                            setDisplayState(redonly);
                        }
                        setData(value === null ? '' : value._d)
                    }}
                    suffixIcon={null}
                    className='spreadsheet-picker'
                    value={tempData ? moment(tempData, Defines.Format.PrintDate) : ''}
                    format={Defines.Format.PrintDate}
                    onKeyDown={e => {
                        if (e.key === 'Escape') {
                            if (inputReference.current) {
                                setDisplayState(redonly);
                            }
                        }
                    }}
                    showToday={false}
                />;
            case 'integer':
                return <InputNumber type="number" min={0}
                    ref={inputReference}
                    value={tempData ? tempData : ''}
                    onBlur={(e) => {
                        if (inputReference.current) {
                            setDisplayState(redonly);
                        }
                        setData(e.target.value)
                    }}
                    className='spreadsheet-number-input'
                />;
            case 'textInput':
                return <input
                    defaultValue={tempData ? tempData : ''}
                    ref={inputReference}
                    onBlur={(value) => {
                        setData(value.currentTarget.value)
                    }}
                    style={{ cursor: 'pointer', textAlign: 'center', width: 'inherit' }}
                    className={header.key === 'loadCallCheckNote' ? 'green__BG' : ''}
                />;
            case 'select':
                let selectOption;
                let selectNameOptions;
                if (header.key === 'orderStatusByCall') {
                    selectOption = selectOptionsStatus;
                    selectNameOptions = selectNameOptionsStatus;
                } else if (header.key === 'delayedReason') {
                    selectOption = selectOptionsReason;
                    selectNameOptions = selectNameOptionsReason;
                } else if (header.key === 'podRequestStatus') {
                    selectOption = selectOptionsPOD;
                    selectNameOptions = selectNameOptionsPOD;
                }
                const statusByCell =  rowState?.firstOrderData?.outOrderStatusByCall;
                return <Select
                    ref={inputReference} 
                    value={header.key === 'delayedReason' && statusByCell !== 2 ? selectOption[0] : selectOption[`${tempData}`]}
                    disabled={header.key === 'delayedReason' && statusByCell !== 2}
                    className={`rowSelect ${rowState.type === 'stop' ? 'stop_cell': ''} ${rowState.type === 'order' ? 'order_cell' : ''}`}
                    onChange={handleSelectChange}
                    defaultValue={selectOption[`${tempData}`]}>
                    {selectNameOptions.map((item, index) => (
                        <Select.Option key={index} value={item.id}>
                            {item.name}
                        </Select.Option>
                    ))}
                </Select>;
            case 'select_and_popover':
                return (
                <Popover content={content} title="POD status list">
                    <Select
                        ref={inputReference}
                        value={selectOptionsPODStatus[`${tempData}`]}
                        className={`rowSelect ${rowState.type === 'stop' ? 'stop_cell': ''} ${rowState.type === 'order' ? 'order_cell' : ''}`}
                        onChange={handleSelectChange}
                        defaultValue={selectOptionsPODStatus[`${tempData}`]}>
                        {selectNameOptionsPODStatus.map((item, index) => (
                            <Select.Option key={index} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Popover>
                );
            case 'upload':
                return <div style={{position: 'relative'}}>
                    <div onClick={
                        ()=> changeImage(rowState)}
                    >
                        <Uploader
                            rowState={rowState}
                            setData={setData}
                            setUploadModalIsOpen={setUploadModalIsOpen}
                            headerWidth={header.width}>
                        </Uploader>
                    </div>
                </div>
            default: return <></>;
        }
    };

    const getDataWithTooltip = () => tooltip ? (<Tooltip title={GenerateMessages([tooltip])}>
        {displayState === redonly ? getData() : getInput()}
    </Tooltip>) : displayState === redonly ? getData() : getInput();

    const dataDisplay = () =>  getDataWithTooltip();

    const cellDefaultStyle = {
        maxWidth: `${width}px`,
        width: `${width}px`,
        minWidth: `${width}px`,
        height: `${itemSize}px`,
        lineHeight: 'inherit',
    };

    const onSetFocus = () => {
        // startOnScroll && startOnScroll(offsetWidth);
        if (cellData.editable) {
            setDisplayState(editable);
        }
        // setItemDisplayState(resource, rowState, header, cellData.editable);
    };

    const onSetBlur = () => {
        setItemDisplayState(resource, rowState, header, false);
    };

    const editableFunction = (cellData) => (
        cellData.editable ? setDisplayState(editable) : null
    );

    const backgroundColor = pathname === '/ar/changerequestsar' && 
        (   (header.key === 'palletCount' && rowState.orderData.isChangedPalletCount) || 
            (header.key === 'space' && rowState.orderData.isChangedSpace) || 
            (header.key === 'weight' && rowState.orderData.isChhangedWeight)) 
        ? 'yellow' : 'inherit';

    return (
        <div
            onFocus={() => onSetFocus()}
            onClick={() => { editableFunction(cellData)}}
            className='table-body-cell'
            style={{ ...cellDefaultStyle, ...cellStyle, color: color, backgroundColor, padding: '0 2px' }}
            tabIndex={displayState === redonly ? 0 : -1}
            >
            {dataDisplay()}
        </div>
);
};

const mapStateToProps = state => ({
    state: state,
    loadsAssignedOrders: state.AccountPayable.loadsAssignedOrders,
    checked: state.AccountReceivable.checked,
    userRole: state.auth.userRole,
    priceIsCalculated: state.AccountReceivable.priceIsCalculated,
});

const mapDispatchToProps = (dispatch) => ({
    setOrderId: (resource, orderId) => dispatch(setOrderId(resource, orderId)),
    setChecked: (resource, orderId) => dispatch(setChecked(resource, orderId)),
    setItemData: (resource, value, rowState, header) => dispatch(setItemData(resource, value, rowState, header)),
    billButtonAction: (resource, method, rowState) => dispatch(billButtonAction(resource, method, rowState)),
    getOrderChangeRequestData: (resource, orderId) => dispatch(getOrderChangeRequestData(resource, orderId)),
    setItemDisplayState: (resource, rowState, header, enable) => dispatch(setItemDisplayState(resource, rowState, header, enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cell);