import React, { useRef, useEffect } from 'react';
import { connect } from "react-redux";
import Cell from './cell';
import { Draggable } from "react-beautiful-dnd";
import CurrentAndNextContentCell from './CurrentAndNextContentCell';
import CurrentAndNextCell from './CurrentAndNextCell';
import { getContentWidth } from '../../../../store/reducers/loadArea/helper/general';

const Row = (
    {
        data,
        style,
        itemSize,
        listRef,
        state,
        dndRowIndex,
        startOnScroll,
        resource,
        onDrugStartMouseX,
        doneTrafficList,
        currentAndNextTraffic,
    }) => {
    const rowRef = useRef({});
    const traffic = state[resource];

    const color = data.rowState.loadId === traffic.inLoadNeedId ? 'lightgray' :
                data.rowState.loadStatus === 5 ?'#BFF4D5' :
                data.rowState.loadStatus === 1 ? '#F9FFFF' :
                data.rowState.type === 'separator' ? '#325353' :
                '#FFF8EF';

    useEffect(() => {
        if (rowRef.current && data.rowState.loadStatus !== 6) {
            listRef.current.resetAfterIndex(0);
        }
    }, [rowRef, listRef, data.rowState.loadStatus]);

    style.width = 'max-content';
    const rowStyle = { ...style, ...{ height: `${itemSize}px`, backgroundColor: color } };
    
    const cellContent = (i, ind) => {

    const contentWidth = getContentWidth(traffic.headers);
    if (data.rowState.type === 'separator') {
        return <div key={ind}
            style={{
                width: `${contentWidth}px`,
                height: '5px',
                backgroundColor: '#325353',
                borderBottom: '1px solid darkgray'
            }} />
    }
    
    if (data.rowState.type === 'subHeader') {
        if (i.value) {
            return (<CurrentAndNextContentCell
                key={ind}
                cellData={i}
                itemSize={itemSize}
                resource={resource}
            />);
        }
        return (<CurrentAndNextCell
            key={ind}
            cellData={i}
            itemSize={itemSize}
            resource={resource}
        />);
    }
    
    return (<Cell
            key={ind}
            cellData={i}
            type={data.rowState.type}
            itemSize={itemSize}
            startOnScroll={startOnScroll}
            rowState={data.rowState}
            resource={resource}
            doneTrafficList={doneTrafficList}
            currentAndNextTraffic={currentAndNextTraffic}
        />);
    };

    const myOnMouseDown = e => {
        onDrugStartMouseX.current = e.screenX;
    };

    const content = (data.rowState.loadStatus === 6 || data.rowState.loadStatus === 7 || data.rowState.type === 'subHeader') ? (
        <div className='resp-table-row' style={{ ...rowStyle, display:'flex' }}>
            {data.row.map(cellContent)}
        </div>
    ) : data.rowState.loadStatus === 1 && resource === "trafficUpcoming"? (
        <div className='resp-table-row' style={{ ...rowStyle, display:'flex' }}>
            {data.row.map(cellContent)}
        </div>
    ) : <Draggable 
            key={data.rowState.id}
            draggableId={data.rowState.id}
            index={dndRowIndex}>
            {provided => {
                return (
                    <div
                        onMouseDown={e => myOnMouseDown(e)}
                        className='resp-table-row'
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{ ...rowStyle, ...provided.draggableProps.style, display:'flex' }}
                    >
                        {data.row.map(cellContent)}
                    </div>
                );
            }}
        </Draggable>
    ;
    return data ? content : null;
};

export default connect(state => ({
    state: state
}), dispatch => ({
}))(Row);