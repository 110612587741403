import moment from 'moment';
import { createSeparator } from '../separator';
import { getEfsStatusTooltip, getEfsBGColor } from '../dataGeneral';
export const itemDefaultSize = 18;

const regionGroupField = 'dropRegionCode';

export const extractWebLoadsToList = (loads, headers, doNotrenderSeparator, renderLastSep) => {
    let loadsAssignedOrders = [];
    loads.forEach((loadItem, i) => {
        loadsAssignedOrders.push(loadItem);
        loadItem.ordersData.forEach(order => {
            loadsAssignedOrders.push(order);
        });
        loadsAssignedOrders = [...loadsAssignedOrders, ...extractWebLoadsToList(loadItem.subLoads, headers, true, false)];
    });
    return loadsAssignedOrders;
};

export const createSubOrder = (so, originalOrder, childSubOrder) => {
    return {
        ...originalOrder, 
        ...{
            parentId: so.parentId,
            subOrderId: so.id,
            loadId: so.loadId,
            originalOrderId: so.orderId,
            pickLocationId: so.dropLocationId,
            pickLocation: so.dropLocation,
            dropLocationId: childSubOrder ? childSubOrder?.dropLocationId : originalOrder?.dropLocationId,
            dropLocation: childSubOrder ? childSubOrder?.dropLocation : originalOrder?.dropLocation,
            outboundPickWeek:so.pickWeek,
            outboundPickIn: so.pickInDate,
            outboundPickOut: so.pickOutDate,
            outboundPickAppointment: so.pickAppointmenteDate,
            pickOutboundAppointmentStatus: so.pickAppointmentStatus,
            puDate: so.puDate,
            dueDate: so.dueDate,
            deliverDate: so.deliverDate,
            outboundDropAppointment: so.dropAppointmenteDate,
            outboundAppointmentStatus: so.dropAppointmentStatus,
            outboundDropIn: so.dropInDate,
            outboundDropOut: so.dropOutDate,
            outboundLumper: so.lumper,
            dropRestriction: so.dropRestriction,
            pickRestriction: so.pickRestriction,
            stepCount: so.stepCount,
            carrierName: so.carrierName,
            palletCount: so.palletCount,
            cases: so.cases,
            space: so.space,
            weight: so.weight,
            poNumber: so.poNumber,
        }}
};

export const createWebLoadArray = (loadIdField, loadsData, assignedOrders, subOrders, headers, oldLoadsAssignedOrders) => {
    //create sub orders
    const subOrdersData = [];
    subOrders.forEach(so => {
        subOrdersData.push(createSubOrder(so, assignedOrders.find(o => o.id === so.orderId),
            subOrders.find(fso => fso.orderId === so.orderId && fso.parentId === so.id)));
    });
    const subLoads = loadsData.filter(l => l.parentLoadId);
    const loads = [];
    loadsData.filter(l => !l.parentLoadId).forEach(l => loads.push(createWebLoad(l, assignedOrders.filter(o => o[loadIdField] === l.id),
        subLoads,
        subOrdersData,
        headers,
        (oldLoadsAssignedOrders || []).find(ol => ol.rowState.type === 'load' && ol.rowState.loadData.id === l.id), false)));
    if (assignedOrders.length !== loads.reduce((r, o) => r += o.ordersData.length, 0)) {
        console.log('Some assigned orders not found there loads');
    }

    // render subLoads/subOrders without Main Load
    const subOrdersOrderIDs = subOrdersData.map(subO => subO.loadId);
    const allLoadIDs = loadsData.map(load => load.id);
    if (subLoads.length > 0) {
        subLoads
            .filter(subL => subOrdersOrderIDs.includes(subL.id))
            .filter(subL => !allLoadIDs.includes(subL.parentLoadId))
            .forEach(subLoad => loads.push(createWebLoad (
                subLoad, 
                subOrdersData.filter(subOrder => subOrder.loadId === subLoad.id),
                subLoads,
                subOrdersData,
                headers,
                (oldLoadsAssignedOrders || []).find(ol => ol.rowState.type === 'load' && ol.rowState.loadData.id === subLoad.id),
                true
            )));
    }

    return loads;
};

export const createWebLoad = (loadData, loadOrders, subLoads, subOrdersData, headers, oldWebLoad, isSub) => {
    
    const loadId = `load_${loadData.id}`;
    const loadWebData = {
        row: [],
        ordersData: [],
        subLoads: [],
        rowState: {
            type: 'load',
            isSub,
            subId: subLoads ? subLoads.find(l => l.parentLoadId === loadData.id) : null,
            id: loadId,
            loadId: loadData.id,
            loadData,
            visible: true,
            reacted: oldWebLoad !== undefined && oldWebLoad.rowState.reacted,
            available: false,
            createDropKey: order => {
                let createKey = '0';
                if (order.outboundLoadId) {
                    createKey = `${order.outboundLoadId}_${order.dropLocationId}`;
                }
                return createKey;
            },
            createPickKey: order => {
                let createKey = '0';
                if (order.outboundLoadId) {
                    createKey = `${createKey}`;// _${order.pickLocationId}_0`;
                }
                return createKey;
            },
            groups: (orders, keyFunction) =>  orders.reduce((r, o) => {
                    const groupBy = keyFunction(o.rowState.orderData);
                    r[groupBy] = [...r[groupBy] || [], o.rowState.orderData];
                    return r;
                }, {}),
            findOrderGroup: (groups, order, createKey) => {
                return Object.keys(groups).find(go => {
                    const oG = groups[go];
                    if (oG.some(e => e.id === order.id)) {
                        return groups[go];
                    }
                    if (createKey && go === createKey(order)) {
                        return groups[go];
                    }
                    return null;
                });
            },
            height: itemDefaultSize
        }
    };
    
    let loadHasSub = false;
    if (!loadData.parentLoadId) {
        const loadOrdersIds = loadOrders?.map(order => order.id)
        const subOrdersIDs = subOrdersData?.map(sub => sub.originalOrderId);
        loadOrdersIds.forEach(id => {
            if (subOrdersIDs.includes(id)) {
                loadHasSub = true;
            }
        })
    }

    if (loadData.parentLoadId) {
        const loadsParentIds = subLoads?.map(load => load.parentLoadId);
        if (loadsParentIds.includes(loadData.id)) {
            loadHasSub = true;
        }
    }

    const sortedOrdersByOrderPositionIndex = loadOrders.sort((a,b) => a.outOrderPossitionIndex - b.outOrderPossitionIndex);
    sortedOrdersByOrderPositionIndex.forEach(lo => loadWebData.ordersData.push(createWebOrder(lo, headers, loadData, null, loadId, loadWebData.rowState.reacted, isSub, subOrdersData, loadHasSub)));
    
    loadWebData.ordersData =
        loadWebData.ordersData.sort((a, b) => new Date(a.rowState.orderData.outboundDropAppointment)
            - new Date(b.rowState.orderData.outboundDropAppointment ?? '')).reverse();

    const pickOutboundGroups = loadWebData.rowState.groups(loadWebData.ordersData, loadWebData.rowState.createPickKey);
    const dropOutboundGroups = loadWebData.rowState.groups(loadWebData.ordersData, loadWebData.rowState.createDropKey);
    let stopNumber = Object.keys(dropOutboundGroups).length;
    if (!isSub) {
        Object.keys(dropOutboundGroups).forEach(key => {
            
            const groupOrders = dropOutboundGroups[key];
            groupOrders.forEach(o => {
                loadWebData.ordersData.find(wo => wo.rowState.orderData.id === o.id).row.find(f => f.key === 'left_action').value = stopNumber;
            });
            stopNumber--;
        });
    }

    loadWebData.ordersData =
        loadWebData.ordersData.sort((a, b) => +a.row.find(f => f.key === 'left_action').value - +b.row.find(f => f.key === 'left_action').value)
            .reverse();

    for (let hederIndex = 0; hederIndex < headers.length; hederIndex++) {
        const headerItem = headers[hederIndex];
        let value = null;
        let secondValue = null;
        let fieldType = 'text';
        let editable = false;
        let isBold = false;
        let cellStyle = {};
        let tooltip = null;
        if (!headerItem.leftAction && !headerItem.rightAction) {
            switch (headerItem.key) {
                case 'id':
                    value = loadData.id;
                    break;
                case 'outboundPickIn': 
                    if (!isSub) {
                        value = `${Object.keys(pickOutboundGroups).length}P`;
                    }
                    break;
                case 'readyDate':
                    if (!isSub) {
                        value = '';
                        if (loadWebData.ordersData.length !== 0) {
                            const filterData = loadWebData.ordersData.filter(o => o.rowState.orderData[headerItem.key]);
                            if (filterData && filterData.length > 0) {
                                const maxDate = Math.max.apply(null,
                                    filterData.map(order => {
                                        return new Date(order.rowState.orderData[headerItem.key]);
                                    }));
                                if (maxDate) {
                                    value = new Date(maxDate);
                                }
                            }
                        }
                        fieldType = 'date';
                    }
                    break;
                case 'outboundPickAppointment':
                    value = '';
                    if (loadWebData.ordersData.length !== 0) {
                        const order = loadWebData.ordersData[loadWebData.ordersData.length - 1];
                        if (order) {
                            value = order.row.find(f => f.key === headerItem.key).value;
                        }
                    }
                    fieldType = 'dateTime';
                    break;
                case 'puDate':
                    value = '';
                    if (loadWebData.ordersData.length !== 0) {
                        const filterData = loadWebData.ordersData.filter(o => o.rowState.orderData[headerItem.key]);
                        if (filterData && filterData.length > 0) {
                            const minDate = Math.min.apply(null,
                                filterData.map(order => {
                                    return new Date(order.rowState.orderData[headerItem.key]);
                                }));
                            if (minDate) {
                                value = new Date(minDate);
                            }
                        }
                    }
                    fieldType = 'date';
                    break;
                case 'dueDate':
                    value = '';
                    fieldType = 'date';
                    break;
                case 'deliverDate':
                    value = '';
                    if (loadWebData.ordersData.length !== 0) {
                        const order = loadWebData.ordersData[loadWebData.ordersData.length - 1];
                        if (order) {
                            value = order.row.find(f => f.key === headerItem.key).value;
                        }
                    }
                    fieldType = 'date';
                    break;
                case 'outboundDropAppointment':
                    value = '';
                    if (loadWebData.ordersData.length !== 0) {
                        const order = loadWebData.ordersData[loadWebData.ordersData.length - 1];
                        if (order) {
                            value = order.row.find(f => f.key === headerItem.key).value;
                        }
                    }
                    fieldType = 'dateTime';
                    break;
                case 'pickLocation':
                    value = 'San Fernando, CA (TST)'; //??? need to change
                    fieldType = 'text';
                    break;
                case 'dropLocation':
                    if (loadWebData.ordersData.length !== 0) {
                        const order = loadWebData.ordersData[0];
                        if (order) {
                            value = order.row.find(f => f.key === headerItem.key).thirdValue;
                        }
                    }
                    break;
                case 'poNumber':
                    value = loadWebData.ordersData.length;
                    fieldType = 'text';
                    break;
                case 'palletCount':
                    if (loadWebData.ordersData.length > 0) {
                        value = loadWebData.ordersData.reduce((a, b) => {
                            return a + (b ?
                                +b.row.find(f => f.key === headerItem.key).value : 0);
                        }, 0);
                    }
                    if (loadWebData?.rowState?.subId) {
                        const subLoadId = loadWebData.rowState.subId.id;
                        const subOrdersPalletCount = subOrdersData.filter(order => order.loadId === subLoadId)
                            ?.reduce((sub, acc) => Number(sub) + Number(acc.palletCount), 0);
                        secondValue = subOrdersPalletCount;
                    }
                    fieldType = 'text';
                    break;
                case 'space':
                    if (loadWebData.ordersData.length > 0) {
                        value = loadWebData.ordersData.reduce((a, b) => {
                            return a + (b ?
                                +b.row.find(f => f.key === headerItem.key).value : 0);
                        }, 0);
                    }
                    if (loadWebData?.rowState?.subId) {
                        const subLoadId = loadWebData.rowState.subId.id;
                        const subOrdersSpace = subOrdersData.filter(order => order.loadId === subLoadId)
                            ?.reduce((sub, acc) => Number(sub) + Number(acc.space), 0);
                        secondValue = subOrdersSpace;
                    }
                    fieldType = 'text';
                    break;
                case 'weight':
                    if (loadWebData.ordersData.length > 0) {
                        value = loadWebData.ordersData.reduce((a, b) => {
                            return a + (b ?
                                +b.row.find(f => f.key === headerItem.key).value : 0);
                        }, 0);
                    }
                    if (loadWebData?.rowState?.subId) {
                        const subLoadId = loadWebData.rowState.subId.id;
                        const subOrdersWeight = subOrdersData.filter(order => order.loadId === subLoadId)
                            ?.reduce((sub, acc) => Number(sub) + Number(acc.weight), 0);
                        secondValue = subOrdersWeight;
                    }
                    fieldType = 'text';
                    break;
                case 'loadNumber':
                    value = loadData.loadNumber;
                    fieldType = 'text';
                    break;
                case 'wk':
                    value = loadData.pickWeek;
                    fieldType = 'selectManifest';
                    editable = true;
                    break;
                case 'in_load':
                    //value = isSub ? "" : loadData.loadNum;
                    break;
                case 'out_load':
                    value = isSub ? "" : loadData.loadNum;
                    break;
                case 'carrier':
                    value = loadData.carrierName;
                    fieldType = 'text';
                    //editable = true;
                    break;
                case 'rate':
                    value = loadData.rate ? loadData.rate:'';
                    fieldType = 'currency';
                    //editable = true;
                    break;
                case 'outboundLumper':
                    value = Math.round(loadData.outboundLumper);
                    fieldType = 'currency';
                    //editable = true;
                    break;
                case 'driver_truck_Trailer_VIN':
                    value = `${(loadData.driverName ? loadData.driverName : '')} ${(loadData.truckNumber ? loadData.truckNumber : '')} ${(loadData.trailer ? loadData.trailer : '')}`;//??? + vin
                    break;
                case 'notes':
                    value = loadData.loadCommentsCount > 0 ? loadData.loadCommentsCount : '0' ;
                    fieldType = 'text';
                    break;
                case 'target':
                    value = loadData.target ? loadData.target : '';
                    fieldType = 'currency';
                    //editable = true;
                    break;
                case 'efs':
                    //value = `${loadData.inPaymentMethod ? loadData.inPaymentMethod : ''}  ${loadData.inAmount ? '$' + loadData.inAmount : ''} ${loadData.inNote ? loadData.inNote : ''} ${`${loadData.inCardNumber ? `(${loadData.inCardNumber})` : ''}`} ${loadData.inEfsCreateDate ? (moment(loadData.inEfsCreateDate).format('L')) : ''}`;
                    value = loadData.efsSum;
                    fieldType = 'currency';
                    cellStyle = getEfsBGColor(loadData.efsStatusMin);
                    tooltip = getEfsStatusTooltip(loadData.efsStatusMin);
                    break;
                case 'outPickNummber':
                    value = loadData.outPickNummber;
                    fieldType = 'textInput';
                    break;
                case 'outDeliverNummber':
                    value = loadData.outDeliverNummber;
                    fieldType = 'textInput';
                    break;
                // case 'loadPositionType':
                //     value = loadData.loadPositionType;
                //     fieldType = 'select';
                //     editable = true;
                //     break;
                default:
                    break;
            }
        }
        else {
            value = '';
            secondValue = '';
            fieldType = 'text';
        }

        loadWebData.row.push({
            key: headerItem.key,
            value,
            secondValue,
            fieldType,
            displayState: 1,
            isBold,
            editable,
            cellStyle,
            tooltip
        });
    }
    if (subLoads) {
        subLoads.filter(sl => sl.parentLoadId === loadData.id).forEach(sl => {
            loadWebData.subLoads.push(createWebLoad(sl, subOrdersData.filter(so => so.loadId === sl.id), subLoads, subOrdersData, headers, oldWebLoad, true));
        });
    }
    return loadWebData;
};

export const extractWebRegionsToList = (regions, headers) => {
    const regionsAvailableOrders = [];
    regions.forEach((regionItem, i) => {
        extractWebRegionToList(regionItem, headers, regionsAvailableOrders, i);
    });
    return regionsAvailableOrders;
};

export const extractWebRegionToList = (region, headers, regionsAvailableOrders, index) => {
    regionsAvailableOrders.push(region);
    region.ordersData.forEach(order => {
        regionsAvailableOrders.push(order);
    });
    regionsAvailableOrders.push(createSeparator(headers, 'region', index, null, region.rowState.id));
};

export const regionOrderGrouping = orders => orders.reduce((r, o) => {
    const groupBy = o[regionGroupField];
    r[groupBy] = [...r[groupBy] || [], o];
    return r;
}, {});

export const createWebRegionArray = (availableOrders, headers, regionHeaders) => {
    const regionsOrders = regionOrderGrouping(availableOrders);
    const regions = [];
    Object.keys(regionsOrders).forEach(r => {
        regions.push(createWebRegion(r, regionsOrders[r], headers, regionHeaders));
    });
    return regions;
};

export const createRegionWebId = regionName => `region_${regionName}`;

export const createWebRegion = (regionData, regionsOrders, headers, regionHeaders) => {
    const regionWebId = createRegionWebId(regionData);
    const regionWebData = {
        row: [],
        ordersData: [],
        rowState: {
            type: 'region',
            id: regionWebId,
            visible: true,
            reacted: false,
            available: true,
            height: itemDefaultSize
        }
    };
    for (let rIndex = 0; rIndex < regionHeaders.length; rIndex++) {
        const regionHeader = regionHeaders[rIndex];
        regionWebData.row.push({
            key: regionHeader.key,
            value: rIndex === 1 ? regionData : '',
            editable: false
        });
    }
    regionsOrders.forEach(o => regionWebData.ordersData.push(createWebOrder(o, headers, null, regionWebId, null, false, false, null)));
    return regionWebData;
};

const toDate = value => value ? moment.utc(value).toDate() : '';
const toDateTime = value => value ? moment.utc(value).toDate() : '';

const findFirstSubOrder = (selfId, subOrders, originalOrderId) =>
    subOrders.find(o => o.originalOrderId === originalOrderId && o.parentId === selfId);

//created spradesheet order row
export const createWebOrder = (oData, headers, loadData, regionId, loadId, isHidden, isSub, subOrders, loadHasSub) => {
    const orderData = { ...oData };
    let allowCreateSubOrder = false;
    let firstSubOrder = null;
    if (!isSub && loadData !== null) {
        firstSubOrder = subOrders ? subOrders.find(o => o.originalOrderId === orderData.id && !o.parentId) : null;
        allowCreateSubOrder = firstSubOrder === undefined;
        if (firstSubOrder) {
            orderData.dropLocationId = firstSubOrder.pickLocationId;
            orderData.dropLocation = firstSubOrder.pickLocation;
        }
    }
    else if (isSub && subOrders) {
        firstSubOrder = findFirstSubOrder(orderData.subOrderId, subOrders, orderData.id);
        allowCreateSubOrder = firstSubOrder === undefined;
    }
    if (regionId && loadId) {
        console.warn(`regionId and loadId can not have value at same time -> ${regionId}, ${loadId}`);
    }

    const orderHasSub = subOrders?.find(sub => sub.id === orderData.id)?.id;

    const orderWebData = {
        row: [],
        rowState: {
            type: 'order',
            isSub,
            orderHasSub: orderHasSub,
            subId: firstSubOrder && firstSubOrder.id,
            id: `${(isSub ? `sub_${orderData.parentId ? orderData.parentId : ''}` : '')}order_${orderData.id}`,
            orderObjectId: orderData.id,
            loadObjectId: loadData && loadData.id,
            orderData: orderData,
            loadData,
            loadId: loadId && loadId,
            regionId: regionId && regionId,
            visible: !isHidden,
            reacted: false,
            available: loadData === null || !loadData.id,
            height: itemDefaultSize,
            allowCreateSubOrder,
        }
    };
    for (let hederIndex = 0; hederIndex < headers.length; hederIndex++) {
        const headerItem = headers[hederIndex];
        let value = null;
        let secondValue = null;
        let thirdValue = null;
        let fieldType = 'text';
        let editable = false;
        let isBold = false;
        let cellStyle = {};
        let tooltip = null;
        if (!headerItem.leftAction && !headerItem.rightAction) {
            switch (headerItem.key) {
                case 'id':
                    value = orderData.id || orderData.originalOrderId;
                    break;
                case 'outboundPickIn':
                    value = toDateTime(orderData.outboundPickIn);
                    fieldType = 'dateTime';
                    editable = false;
                    break;
                case 'inboundDeliveryAPPT':
                    value = toDateTime(orderData.inboundDropAppointment);
                    fieldType = 'dateTime';
                    editable = false;
                    break;
                case 'outboundPickOut':
                    value = toDateTime(orderData.outboundPickOut);
                    fieldType = 'dateTime';
                    editable = false;
                    break;
                case 'readyDate':
                    value = toDate(orderData.readyDate);
                    fieldType = 'date';
                    editable = !isSub;
                    break;
                case 'outboundPickAppointment':
                    value = [toDateTime(orderData.outboundPickAppointment), Number(orderData.pickOutboundAppointmentStatus), orderData.outPickNummber, orderData.lastOutboundPickFromData, orderData.lastOutboundPickToData, orderData.outPickAuthorFullName];
                    fieldType = 'appointmentSelector';
                    editable = true;
                    break;
                case 'puDate':
                    value = toDate(orderData.puDate);
                    fieldType = 'date';
                    editable = true;
                    break;
                case 'dueDate':
                    value = toDate(orderData.dueDate);
                    fieldType = 'date';
                    editable = true;
                    break;
                case 'deliverDate':
                    value = toDate(orderData.deliverDate);
                    fieldType = 'date';
                    editable = true;
                    break;
                case 'outboundDropAppointment':
                    value = [toDateTime(orderData.outboundDropAppointment), Number(orderData.outboundAppointmentStatus), orderData.outDeliverNummber, orderData.lastOutboundDropFromData, orderData.lastOutboundDropToData, orderData.outDropAuthorFullName];
                    fieldType = 'appointmentSelector';
                    editable = true;
                    break;
                case 'outboundAppointmentStatus':
                    value = orderData.outboundAppointmentStatus;
                    fieldType = 'select';
                    editable = true;
                    break;
                case 'outboundDropIn':
                    value = toDateTime(orderData.outboundDropIn);
                    fieldType = 'dateTime';
                    editable = false;
                    break;
                case 'outboundDropOut':
                    value = toDateTime(orderData.outboundDropOut);
                    fieldType = 'dateTime';
                    editable = false;
                    break;
                case 'customerCodeOrName':
                    value = orderData.customerCodeOrName;
                    fieldType = 'textInputReadOnly';
                    editable = true;
                    break;
                case 'pickLocation':
                    value = orderData.pickLocation;
                    fieldType = 'text';
                    editable = false;
                    break;
                case 'dropLocation':
                    value = orderWebData.rowState.available || isSub || orderHasSub ? orderData.dropLocation : orderData.dropLocationId;
                    fieldType = orderWebData.rowState.available || isSub || orderHasSub ? 'text' : 'selectLocations';
                    isBold = !orderData.warehouse;
                    editable = orderWebData.rowState.available || isSub || orderHasSub ? false : true;
                    secondValue = orderData.pickWorkingDaysStr;
                    thirdValue = orderData.dropLocation;
                    break;
                case 'dropState':
                    value = orderData.dropStateName;
                    fieldType = 'text';
                    editable = false;
                    break;
                case 'poNumber':
                    value = orderData.poNumber;
                    secondValue = isSub ? orderData.stepCount : '';
                    fieldType = 'textInputPO';
                    editable = isSub ? true : false;
                    break;
                case 'soNumber':
                    value = orderData.soNumber;
                    fieldType = 'textInputReadOnly';
                    editable = true;
                    break;
                case 'palletCount':
                    value = orderData.palletCount;
                    secondValue = orderHasSub ? firstSubOrder?.palletCount : '';
                    thirdValue = orderData?.outOrderHistoryDict?.palletCount;
                    fieldType = 'integer';
                    editable = !loadHasSub;
                    break;
                case 'space':
                    value = orderData.space;
                    secondValue = orderHasSub ? firstSubOrder?.space : '';
                    thirdValue = orderData?.outOrderHistoryDict?.space;
                    fieldType = 'integer';
                    editable = !loadHasSub;
                    break;
                case 'cases':
                    value = orderData.cases;
                    secondValue = orderHasSub ? firstSubOrder?.cases : '';
                    thirdValue = orderData?.outOrderHistoryDict?.cases;
                    fieldType = 'integer';
                    // editable = !loadHasSub;
                    editable = false;
                    break;
                case 'weight':
                    value = orderData.weight;
                    secondValue = orderHasSub ? firstSubOrder?.weight : '';
                    thirdValue = orderData?.outOrderHistoryDict?.weight;  
                    fieldType = 'integer';
                    editable = !loadHasSub;
                    break;
                case 'temperature':
                    value = orderData.temperature;
                    fieldType = 'integer';
                    editable = false;
                    break;
                case 'dropRestriction':
                    value = orderData.dropRestriction;
                    fieldType = 'select';
                    editable = true;
                    break;
                case 'loadNumber':
                    value = loadData ? loadData.loadNumber : '';
                    secondValue = loadData ? loadData.rateConfirmed : '';
                    thirdValue = loadData ? loadData.carrierInformed : '';
                    fieldType = 'text';
                    break;
                case 'wk':
                    value = orderData.puDate && new Date(orderData.puDate).getWeek();
                    fieldType = 'text';
                    break;
                case 'in_load':
                    value = orderData.inLoad;
                    break;
                case 'out_load':
                    value = orderData.outLoad;
                    break;
                case 'carrier':
                    //??? 'Single, Straight', 'SIDEWAYS', I think this should be order field
                    break;
                case 'outboundLumper':
                    value = Math.round(orderData?.outboundLumper);
                    fieldType = 'currency';
                    editable = orderData.outboundDropIn;
                    break;
                case 'efs':
                    //value = `${orderData.outPaymentMethod ? orderData.outPaymentMethod : ''}  ${orderData.outAmount ? '$' + orderData.outAmount : ''} ${orderData.outNote ? orderData.outNote : ''} ${`${orderData.outCardNumber ? `(${orderData.outCardNumber})` : ''}`} ${orderData.outEfsCreateDate ? (moment(orderData.outEfsCreateDate).format('L')) : ''}`;
                    break;
                case 'ap_notes':
                    value = orderData.lastComment && orderData.lastComment.Comment;
                    fieldType = 'text';
                    break;
                case 'outPickNummber':
                    value = orderData.outPickNummber;
                    fieldType = 'textInput';
                    editable = true;
                    break;
                case 'outDeliverNummber':
                    value = orderData.outDeliverNummber;
                    fieldType = 'textInput';
                    editable = true;
                    break;
                default:
                    break;
            }
        }
        else {
            value = '';
            secondValue = '';
            thirdValue = '';
            fieldType = 'text';
        }

        orderWebData.row.push({
            key: headerItem.key,
            value,
            secondValue,
            thirdValue,
            fieldType,
            displayState: 1,
            isBold,
            editable,
            cellStyle,
            tooltip
        });
    }
    return orderWebData;
};


