import React, { useEffect, useCallback, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { message } from 'antd';
import { Project } from '../../../project/Project';
import axios from '../../axios/axios';
import Popup from '../custom/popup/popup';
import { closeAllPopups } from '../../../store/actions/popup/popup';
import { logout, check, autoLogin } from '../../../store/actions/authentication/authentication';
import { AppPages } from '../../../project/Pages';
import Loading from '../screen/loading';
import MainToolbar from './toolbar/mainToolbar';
import SubToolbar from './toolbar/subToolbar';
import ScriptLoader from './scriptLoader';
import Content from './content';

const Main = props => {
    const [managedMenu, setManagetMenu] = useState(null);
    const location = '/' + window.location.pathname.toLowerCase().split('/')[1];
    const redirectUrl = window.location.pathname;
    const [autoLoginInProcess, setAutoLoginInProcess] = useState(false);
    const { isLoggedIn, exp, refrasheToken, check } = props;
    const startCheck = isLoggedIn && exp && exp > new Date().getTime();

    const goLoginPage = useCallback(() => {
        props.logout();
        const history = props.history;
        if (redirectUrl !== '/login' && redirectUrl !== '/') {
            history.push(Project.getPageUrl(AppPages.Login) + (redirectUrl ? "?redirectUrl=" + redirectUrl : ''));
        }
        else {
            history.push(Project.getPageUrl(AppPages.Login));
        }
    }, [props, redirectUrl]);

    useEffect(() => {
        message.config({
            top: 100,
            duration: 2
        });
        setManagetMenu(Project.createMenu(props.menu, props.parentUrl));
    }, [props.menu, props.parentUrl]);

    useEffect(() => {
        if (startCheck) {
            check(exp, refrasheToken);
        }
    }, [startCheck, check, exp, refrasheToken]);

    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + props.userToken;
    }, [props.userToken]);

    useEffect(() => {
        const expired = (props.exp && props.exp < new Date().getTime());
        if ((!props.isLoggedIn || expired) && !Project.skipHeader(location)) {
            const userRememberMeToken = props.userRememberMeToken;
            if (userRememberMeToken) {
                setAutoLoginInProcess(true);
                props.autoLogin(redirectUrl, userRememberMeToken);
            }
            else {
                goLoginPage();
            }
        }
    }, [goLoginPage, location, props, redirectUrl]);

    useEffect(() => {
        if (props.error) {
            message.error(props.error);
            goLoginPage();
        }
    }, [props.error, goLoginPage]);

    useEffect(() => {
        if (isLoggedIn) {
            setAutoLoginInProcess(false);
        }
    }, [isLoggedIn]);

    const currentMenu = managedMenu?.find(m => m.url === location);

    const [parentMenu, setParentMenu] = useState(false);
    useEffect(() => {
        setParentMenu(currentMenu);
    }, [currentMenu]);

    const expired = (props.exp && props.exp < new Date().getTime());
    if (autoLoginInProcess || ((!props.isLoggedIn || expired) && !Project.skipHeader(location)) || !managedMenu) {
        return <Loading />;
    }

    return (
            <React.Fragment>
                {(props.isLoggedIn && !Project.skipHeader(location) &&
                    <view>
                        {!props.isGoogleLoaded && <ScriptLoader />}
                        {/* {!props.isHiddenMenu && userRole && (userRole !== 'Admin' && userRole !== 'Customer') &&
                            <div style={{background: '#325353', color: 'white', fontSize: '14px', padding: '8px 20px'}}>
                                {userRole}
                            </div>
                        } */}
                        { !props.isHiddenMenu && (
                            <MainToolbar
                                managedMenu={managedMenu}
                                location={location}
                                closeAllPopups={props.closeAllPopups}
                                userFLName={`${props.userFirstName} ${props.userLastName}`}
                            />
                        )
                        }
                        <Popup />
                        <view>
                            {
                                currentMenu && currentMenu.sub && !props.isHiddenMenu ? <SubToolbar
                                    parentUrl={currentMenu.parentUrl}
                                    parentMenu={parentMenu}
                                    menu={currentMenu.sub}
                                    closeAllPopups={props.closeAllPopups}
                                /> : null
                            }
                        <Content />
                        </view>
                    </view>
                )
                    || props.children
                }
            </React.Fragment>
    )
};

export default connect(state => ({
    isLoggedIn: state.auth.isLoggedIn,
    menu: state.auth.userMenu,
    exp: state.auth.userTokenExpiration,
    refrasheToken: state.auth.userRefreshToken,
    userToken: state.auth.userToken,
    userFirstName: state.auth.userFirstName,
    userLastName: state.auth.userLastName,
    userRole: state.auth.userRole,
    userRememberMeToken: state.auth.userRememberMeToken,
    isGoogleLoaded: state.google.isLoaded,
    error: state.auth.error,
    isHiddenMenu: state.pageContent.isHiddenMenu
}),
    dispatch => ({
        closeAllPopups: () => dispatch(closeAllPopups()),
        logout: () => dispatch(logout()),
        check: (exp, refreshToken) => dispatch(check(exp, refreshToken)),
        autoLogin: (returnUrl, refreshToken) => dispatch(autoLogin(returnUrl, refreshToken))
    }))(withRouter(Main));