import { Stop } from "../stop";
import { ArraySum } from '../../../../helper/common';
import { BaseData } from '../baseData';

export class Data extends BaseData {
    static createKey(order) {
        let createKey = Data.defaultKey;
        if (order.loadId) {
            createKey = `${order.loadId}_${order.customerId}_${order.pickLocationId}`;
            if (order.warehouse) {
                createKey = `${createKey}_0_${order.warehouseId}`;
            }
            else {
                createKey = `${createKey}_${order.dropLocationId}_0`;
            }
        }
        return createKey;
    };

    constructor(list, loads) {
        super();
        this.list = list;
        this.group = this.list.reduce((r, o) => {
            const groupBy = Data.createKey(o);
            r[groupBy] = [...r[groupBy] || [], o];
            return r;
        }, {});

        Object.keys(this.group).forEach(go => {
            const oG = this.group[go];
            const stop = new Stop();
            if (oG.length > 0) {
                const firstO = oG[0];

                const load = loads.find(l => l.id === firstO.loadId);
                if (!load) {
                    //load not available
                    return;
                }
                stop.id = stop.key = go;
                stop.isFirst = this.stops.find(s => s.loadId === firstO.loadId) === undefined;
                stop.customer = firstO.customerCodeOrName;
                stop.customerTooltip = firstO.customerCodeOrName;
                stop.loadNumber = firstO.inboundLoadNumber;
                stop.loadId = firstO.loadId;
                //stop.truckNumber = firstO.truckNumber;
                //stop.driverName = firstO.driverName;
                //stop.driverPhone = firstO.driverPhone;
                //stop.rate = firstO.inboundRate;
                stop.customerId = firstO.customerId;
                //stop.carrierName = firstO.carrierName;
                //stop.trailer = firstO.inboundTrailer;

                stop.puDate = firstO.puDate;
                stop.pickLocationId = firstO.pickLocationId;
                stop.pickAppointment = firstO.inboundAppointment;
                stop.pickStatus = firstO.inbioundAppointmentStatus;
                stop.pickWeek = firstO.pickWeek;
                stop.pickCity = firstO.pickCity;
                stop.pickState = firstO.pickState;
                stop.pickWarehouse = firstO.pickLocationName;

                stop.dropLocationId = firstO.dropLocationId;
                stop.dropAppointment = firstO.dropInboundAppointment;
                stop.dropStatus = firstO.dropInboundAppointmentStatus;
                stop.dropCity = firstO.warehouse ? firstO.drop1City : firstO.drop2City;
                stop.dropState = firstO.warehouse ? firstO.drop1State : firstO.drop2State;
                stop.dropWarehouse = firstO.warehouse ? firstO.drop1WH : firstO.drop2WH;

                stop.isWarehouse = firstO.warehouse;
                stop.warehouseId = firstO.warehouseId;
                stop.sequencesName = firstO.inboundLoadingSequenceName;
                stop.inboundStopNotes = firstO.inboundStopNotes;

                stop.in = firstO.in;
                stop.out = firstO.out;

                stop.color = load.color;
                stop.truckNumber = load.truckNumber;
                stop.driverName = load.driverName;
                stop.driverPhone = load.driverPhone;
                stop.rate = load.rate;
                stop.carrierName = load.carrierName;
                stop.trailer = load.trailer;
                stop.truckId = load.truckId;
                stop.carrierId = load.carrierId;

                stop.readyToBook = load.readyToBook;
                stop.inTransit = load.inTransit;
                stop.isInbound = true;

                stop.orders = oG;
                this.stops.push(stop);
            }
        });
        this.stops.forEach(s => {
            s.loadTotalPallets = ArraySum(this.stops.filter(si => si.loadId === s.loadId), 'totalPallets');
            s.loadTotalWeight = ArraySum(this.stops.filter(si => si.loadId === s.loadId), 'totalWeight');
        });

        const firstStops = this.stops.filter(s => s.isFirst);
        loads.forEach(l => {
            if (!firstStops.find(fs => fs.loadId === l.id)) {
                const newStop = new Stop();
                newStop.key = `${l.id}_empty`;
                newStop.isEmpty = true;
                newStop.isFirst = true;
                newStop.loadId = l.id;
                newStop.loadNumber = l.loadNumber;
                newStop.truckNumber = l.truckNumber;
                newStop.driverName = l.driverName;
                newStop.driverPhone = l.driverPhone;
                newStop.rate = l.inboundRate;
                newStop.carrierName = l.carrierName; //shoukld add in backend
                newStop.color = l.color;
                this.stops.push(newStop);
            }
        });
    }
}