import React, { useState } from 'react';
import { Tooltip, DatePicker, Checkbox } from 'antd';
import CurrencyInput from 'react-currency-input-field';
import moment from 'moment';
import InputMask from 'react-input-mask';
import 'react-phone-number-input/style.css';
import { ValidationErrorClass, ValidationWarningClass } from './validation';
import { getNormalizedAttributes } from '../config/element.config';
import ButtonAction from '../components/list/buttonActions';

export const ElementContent = props => {
    const attributes = getNormalizedAttributes(props.Attributes, props.object, props.val);

    const content = (props.Field(
        props.val,
        props.object,
        props.fieldChange,
        props.fieldOut,
        props.ref,
        props.onKeyPress,
        attributes,
        props.onActionRecord,
        props.parentData,
        props.clicked,
        props.firstTimeAction
    )(props.valudationMessages, props.warningMessages)
    );
    if (!content) {
        return null;
    }
    let messages = [];
    if (props.valudationMessages && props.valudationMessages.length > 0) {
        messages = props.valudationMessages;
    } else if (props.warningMessages?.length) {
        messages = props.warningMessages;
    } else if (props.Messages && props.Messages.length > 0) {
        props.Messages.forEach((m) => {
            if (m.charAt(0) === '_') {
                const om = m.substring(1);
                if (props.object && typeof (props.object) === 'object' && props.object[om]) {
                    messages.push(props.object[om]);
                }
            }
            else {
                messages.push(m);
            }
        });
    }

    return (<Tooltip title={GenerateMessages(messages)}>
        {content}
    </Tooltip>);
};

export const ElementClassNames = (o, data, classes) => {
    const classNames = [];
    if (!classes) {
        return classNames;
    }
    classes.forEach(c => {
        if (typeof c === 'function') {
            classNames.push(c(o, data));
        }
        else {
            classNames.push(c);
        }
    });
    return classNames;
};

export const CssErrorClassHandling = (errorClass, classes, valudationMessages, warningMessages, warningClass) => {

    if (classes.indexOf(errorClass) !== -1) {
        classes.pop(errorClass);
    }
    if (valudationMessages && valudationMessages.length > 0) {
        classes.push(errorClass);
    } else if (warningMessages?.length) {
        classes.push(warningClass);
    }

    return classes;
};

export const GenerateElementValudationMessages = (valudationMessages) => GenerateMessages(valudationMessages)

export const GenerateMessages = (messages) =>
    messages && messages.length > 0
        ? <React.Fragment> {messages.map((m, i) =>
            <div key={i}>{m}</div>)}
        </React.Fragment> : null;


export const GenerateInput = (type, condition, ref, data, classes, action, out, valudationMessages, placeholder, onKeyPress, name, itemValue, style, tabIndex, warningMessages, attributes = {}, withTooltip) =>
(withTooltip === 'withTooltip' ?
    <Tooltip title={data}>
        <input
            disabled={!condition}
            type={type}
            ref={ref}
            autoComplete={type === 'password' ? 'new-password' : 'on'}
            {...(itemValue && { value: { itemValue } })}
            //defaultValue={data}
            value={data || data === 0 ? data : ''}
            className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages, warningMessages, ValidationWarningClass).join(' ')}
            style={style}
            onChange={action}
            placeholder={placeholder}
            onKeyPress={onKeyPress}
            name={name}
            onBlur={out}
            tabIndex={tabIndex}
            {...attributes.input} />
    </Tooltip> :
    <input
        disabled={!condition}
        type={type}
        ref={ref}
        autoComplete={type === 'password' ? 'new-password' : 'on'}
        {...(itemValue && { value: { itemValue } })}
        //defaultValue={data}
        value={data || data === 0 ? data : ''}
        className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages, warningMessages, ValidationWarningClass).join(' ')}
        style={style}
        onChange={action}
        placeholder={placeholder}
        onKeyPress={onKeyPress}
        name={name}
        onBlur={out}
        tabIndex={tabIndex}
        {...attributes.input}
    />
);

export const GenerateFileInput = (type, condition, ref, data, classes, action, out, valudationMessages, placeholder, onKeyPress, name, itemValue, style, warningMessages, attributes = {}) =>
(
    <div className="field_group">
        <div className="form_fields ">
            <div class="section_title">
                <text><b>Rate Confirmation</b></text>
            </div>
            <div class="form_separator"></div>
        </div>
        <div className="form_fields ">
            <demospace compact="">
                <icon>file</icon>
                <group compact="">
                    <badge filled="">PDF</badge>
                </group>
            </demospace>
            <div class="input_cont">
                <div class="data_help">Configure settings when and how You'll get notified for content changes</div>
            </div>
            <toolbar transparent="">
                <action>
                    <div class="button accent">Edit</div>
                    <div class="button accent">Download</div>
                    <div class="button accent">Send</div>
                </action>
            </toolbar>
        </div>
    </div>
);

export const GenerateDecimalInput = (type, condition, ref, data, classes, action, out, valudationMessages, placeholder, onKeyPress, name, itemValue, style, title, symbol) => {
    const isCreatePage = window.location.pathname.split('/')[3] === 'create';

    return (
    <CurrencyInput
        disabled={!condition}
        {...(itemValue && { value: { itemValue } })}
        value={data ? data : (data === 0 && isCreatePage) ? '' : (data === 0 && !isCreatePage) ? 0 : ''}
        ref={ref}
        thousandseparator="true"
        maxLength={7}
        className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages).join(' ')}
        style={style}
        onValueChange={values => {
            action(
                {
                    nativeEvent: null,
                    source: null,
                    target: {
                        // eslint-disable-next-line eqeqeq
                        value: values && values != 0 ? parseFloat(values) : (values == 0 && title === 'Space') ? values : '',
                        type: 'decimal'
                    }
                });
        }}
        placeholder={placeholder}
        onKeyPress={onKeyPress}
        name={name}
        onBlur={out}
        prefix={symbol} />
    )
};

export const GeneratePhoneInput = (type, condition, data, classes, action, out, valudationMessages, placeholder, onKeyPress, name, itemValue, style, warningMessages) =>
(
    <InputMask
        mask='999-999-9999'
        maskChar={' '}
        disabled={!condition}
        {...(itemValue && { value: { itemValue } })}
        value={data || ''}
        //ref={ref}
        //thousandseparator="true"
        //maxLength={7}
        className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages).join(' ')}
        style={style}
        placeholder={placeholder}
        onKeyPress={onKeyPress}
        name={name}
        country="US"
        defaultCountry="US"
        type="text"
        maxLength={16}
        //countryCallingCodeEditable={false}
        //international
        //initialValueFormat="national"
        onChange={({ target: { value } }) => {
            action(
                {
                    nativeEvent: null,
                    source: null,
                    target: {
                        value: value/*  ? parseFloat(values) : '' */,
                        type: 'phone'
                    }
                });
        }}
        onBlur={out} />);
;

export const GeneratePassword = props => {
    const { condition, ref, data, classes, action, out, valudationMessages, placeholder, onKeyPress, name, itemValue, style } = props;
    const [showPassword, setShowPassword] = useState(false);

    return (
        <React.Fragment>
            <input
                disabled={!condition}
                type={showPassword ? "text" : "password"}
                ref={ref}
                {...(itemValue && { value: { itemValue } })}
                value={data || ''}
                className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages).join(' ')}
                style={style}
                onChange={action}
                placeholder={placeholder}
                onKeyPress={onKeyPress}
                name={name}
                onBlur={out} />
            <div
                className="button shrink"
                effect="material"
                onClick={() => setShowPassword(!showPassword)}>
                <text>
                    {showPassword ? "Hide" : "Show"}
                </text>
            </div>
        </React.Fragment>
    );
};

export const GenerateStateLessInput = (type, condition, ref, data, classes, action, out, valudationMessages, placeholder, onKeyPress, name, itemValue, style) =>
(
    <input
        disabled={!condition}
        type={type}
        ref={ref}
        {...(itemValue && { value: { itemValue } })}
        defaultValue={data}
        //value={data || ''}
        className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages).join(' ')}
        style={style}
        onChange={action}
        placeholder={placeholder}
        onKeyPress={onKeyPress}
        name={name}
        onBlur={out} />);

export const GenerateInputCurrency = (condition, ref, data, classes, action, out, valudationMessages, placeholder, onKeyPress, name, itemValue, style, object, fieldName) =>
// (<Tooltip placement="topLeft" title={(fieldName === "price" && object && object.lumperPrice) ? object.lumperPrice : ""}>
(<CurrencyInput
    disabled={!condition}
    {...(itemValue && { value: { itemValue } })}
    value={fieldName === 'TP' && data !== null ? data : data || ''}
    ref={ref}
    thousandseparator="true"
    maxLength={7}
    prefix={fieldName === 'TP' ? '' : "$"}
    suffix={fieldName === 'TP' && data !== null ? ' ℉ ' : ""}
    className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages).join(' ')}
    style={style}
    onValueChange={values => {
        action(
            {
                nativeEvent: null,
                source: null,
                target: {
                    value: values/*  ? parseFloat(values) : '' */,
                    type: 'currency'
                }
            });
    }}
    placeholder={placeholder}
    onKeyPress={onKeyPress}
    name={name}
    onBlur={out} />
    // </Tooltip>
);

export const GenerateTextArea = (condition, ref, data, classes, action, out, valudationMessages, placeholder, onKeyPress, name, itemValue, style, attributes = {}) => {
    return <textarea
        {...attributes.textarea}
        disabled={!condition}
        ref={ref}
        //defaultValue={data}
        value={data || ''}
        className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages).join(' ')}
        style={style}
        placeholder={placeholder}
        onChange={action}
        onKeyPress={onKeyPress}
        name={name}
        {...(itemValue && { value: { itemValue } })}
        onBlur={out}
    />
};

export const GenerateCheckBox = (label, condition, ref, data, classes, action, out, valudationMessages, style) =>
    <label className='checkbox' classic="">
        <input
            disabled={!condition}
            type='checkbox'
            ref={ref}
            checked={data}
            className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages).join(' ')}
            style={style}
            onChange={action}
            onBlur={out} />
        <box>
            <check>
            </check>
        </box>
        {label && <text>
            {label}
        </text>}
    </label>;
export const GenerateCheckBoxNoLabel = (label, condition, ref, data, classes, action, out, valudationMessages, style, name) =>
    <label className='checkbox' classic="" style={{ marginTop: '10px' }}>
        {label && <text style={{ color: 'var(--main-color)', paddingLeft: '3px', fontWeight: 700 }}>
            {label}
        </text>}
        <input
            disabled={!condition}
            type='checkbox'
            ref={ref}
            name={name}
            checked={data}
            className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages).join(' ')}
            style={style}
            onChange={action}
            onBlur={out} />
        <box>
            <check>
            </check>
        </box>

    </label>;

export const GenerateCheckBoxModern = (label, condition, ref, data, classes, action, out, valudationMessages, style) =>
    <label checkbox=''>
        <input
            disabled={!condition}
            type='checkbox'
            ref={ref}
            checked={data}
            className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages).join(' ')}
            style={style}
            onChange={action}
            onBlur={out} />
        <box>
            <check>
            </check>
        </box>
        {label && label !== 'secondaryLanding' && <text>{label}</text>}
    </label>;

export const GenerateSimpleCheckbox = (label, condition, ref, data, classes, action, out, valudationMessages, name, style) => (
    <wrap block="">
        <label className="input_cont">
            {/* <input type="text" placeholder="Code" name="code" value="" />
            <separator vertical=""></separator> */}
            <label simple='' checkbox=''>
                <name>{label}</name>
                <input
                    disabled={!condition}
                    type="checkbox"
                    ref={ref}
                    className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages).join(' ')}
                    style={style}
                    onChange={action}
                    onBlur={out}
                    name={name}
                    checked={data} />
                <text light=''></text>
            </label>
        </label>
    </wrap>
);

export const GenerateToolBarButton = (condition, title, icon, classes, action) =>
(<div
    disabled={!condition}
    className={classes.join(' ')}
    onClick={action}>
    {icon ? <icon>{icon}</icon> : null}
    {title}
</div>);

export const GenerateRadio = (label, condition, ref, data, classes, action, out, valudationMessages, name, style, object, field) =>
    <label className="radiobutton" radio=''>
        <input
            type="radio"
            name={name}
            disabled={!condition}
            ref={ref}
            value={data}
            checked={data === +object?.[field]}
            className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages).join(' ')}
            style={style}
            onChange={e => action({
                target:
                {
                    value: e.target.value,
                    type: 'radio',
                    field: field
                },
                nativeEvent: e
            })}
            onBlur={out} />
        <box>
            <check></check>
        </box>
        <text>{label}</text>
    </label>;

export const GenerateRadioSwitcher = (label, condition, ref, data, classes, action, out, valudationMessages, name) => (
    <div large="" className="options" name="DraftFlag">
        <label>
            <input
                type="radio"
                disabled={!condition}
                ref={ref}
                className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages).join(' ')}
                name={name}
                value="1"
                onBlur={out}
                onChange={action}
                checked={data === "1"}
            />
            <wrap effect="material"><text>Yes</text></wrap>
        </label>
        <label>
            <input
                type="radio"
                disabled={!condition}
                ref={ref}
                className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages).join(' ')}
                name={name}
                value="0"
                onBlur={out}
                onChange={action}
                checked={data === "0"}
            />
            <wrap effect="material"><text>No</text></wrap>
        </label>
        <label>
            <input
                type="radio"
                disabled={!condition}
                ref={ref}
                className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages).join(' ')}
                name={name}
                value=""
                onBlur={out}
                onChange={action}
                checked={!data || data === ""}
            />
            <wrap effect="material"><text>All</text></wrap>
        </label>
    </div>
);

export const GenerateDatePicker = (condition, date_format, ref, data, min, max, classes, action, out, valudationMessages, style, restprops, warningMessages) => {
    return <DatePicker
            {...restprops}
            ref={ref}
            value={data ? moment(new Date(data), date_format) : null}
            format={date_format}
            className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages, warningMessages, ValidationWarningClass).join(' ')}
            disabled={!condition}
            onChange={(e, value) => action({
                target:
                {
                    value: value, type: 'date'
                },
                nativeEvent: e,
                source: [{
                    value: value,
                    weekNumber: value && new Date(value).getWeek()
                }]
            })}
            onBlur={out}
            style={style}
            disabledDate={(d) => (min && d.isBefore(min)) || (max && d.isAfter(max))}
        />
};

export const GenerateDueDatePicker = (
        condition,
        date_format,
        ref,
        data,
        min,
        max,
        classes,
        action,
        out,
        valudationMessages,
        style,
        restprops,
        warningMessages,
        object,
        fieldName,
        hardFieldName,
    ) => {
    let tooltipData = ''
    if (object.isHardDate) {
        tooltipData = 'Hard Date';
    } else {
        if (object && object.orderIndicator === 1) {
            tooltipData = 'Standard';
        } else if (object && object.orderIndicator === 2) {
            tooltipData = 'Data Change';
        }
    }
    return (<Tooltip placement="topLeft" title={(fieldName === "dueDate" && object && object.dueDate && object.orderIndicator) ? tooltipData : ""}>
        <DatePicker
            {...restprops}
            needConfirm={true}
            ref={ref}
            value={data ? moment(new Date(data), date_format) : null}
            format={date_format}
            className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages, warningMessages, ValidationWarningClass).join(' ')}
            disabled={!condition}
            onChange={(e, value) => action({
                target:
                {
                    value: value, type: 'date'
                },
                nativeEvent: e,
                source: [{
                    value: value,
                    weekNumber: value && new Date(value).getWeek()
                }]
            })}
            onBlur={out}
            style={style}
            disabledDate={(d) => (min && d.isBefore(min)) || (max && d.isAfter(max))}
            renderExtraFooter={() => (
                <Checkbox
                    checked={object[hardFieldName]}
                    onChange={e => action({
                        target:
                        {
                            checked: e.target.checked,
                            type: 'checkbox',
                            field: hardFieldName
                        },
                        nativeEvent: e
                    })}
                    id='checkboxId'
                    tabindex="0"
                >
                Hard Date</Checkbox>
            )}
        />
    </Tooltip>
    )
};

export const GenerateTimePicker = (condition, time_format, minuteStep, use12Hours, ref, data, classes, action, out, valudationMessages, restprops, style) =>
(
    <input
        ref={ref}
        type='time'
        format={time_format}
        className={CssErrorClassHandling(ValidationErrorClass, classes, valudationMessages).join(' ')}
        value={data ? moment(data, time_format).format(time_format) : ''}
        style={style}
        onBlur={out}
        onChange={(e) => action(e.nativeEvent)}
        disabled={!condition}
        {...restprops}
    />
);

export const GenerateColumnIndicator = (object, data, text, attributes = {}, dataTitle) => (
    <Tooltip placement="topLeft" title={(dataTitle && text) ? text : ""}>
        <circle {...attributes.circle} />
    </Tooltip>
);

export const GenerateColumnButton = (data, props, value, onActionRecord, parentData, clicked) => {
    return () => {
        if ('function' === typeof (props.PopupConfig?.Display)) {
            value = props.PopupConfig.Display(data, value);
        }
        return <ButtonAction
            id={data.id}
            icon={props.Icon}
            action={props.Action}
            label={props.Label}
            popupConfig={{ ...props.PopupConfig }}
            template={props.Template}
            data={data}
            value={value}
            onActionRecord={onActionRecord}
            parentData={parentData}
            clicked={clicked}
        />
    };
}