import React, { useMemo, useRef, useEffect, useCallback, useState } from 'react';
import { useLocation} from 'react-router-dom';
import { connect } from "react-redux";
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { message, Input, Checkbox } from 'antd';
import { orderBy } from "lodash";
import { load, resetError, filterDeliveredTrucks, setOrderId, setChecked } from '../../../../store/actions/accountReceivableActions/accountReceivableActions';
import { getContentWidth } from '../../../../store/reducers/accountReceivableReducer/helper/general';
import Row from './row';
import HeaderRow from './headerRow';
import Loading from "../../screen/loading";
import HideShowMenuSwitch from '../../layout/hideShowMenuSwitch';
import UploadModal from './UploadModal';
import CommentsModal from './CommentsModal';
import ChangeRequestModal from './ChangeRequestModal';
import ChangeRequestAcceptModal from './ChangeRequestAcceptModal';
import { useClientHubOrder } from '../../../hook/hub/useClientHubOrder';

const Spreadsheet = props => {
    const { 
        state,
        loadsAssignedOrders,
        resetError,
        haseError,
        actionMessage,
        loading,
        load,
        resource,
        filterDeliveredTrucks,
        filterType,
        sortType,
        setOrderId,
        setChecked,
        userRole,
        openOrdersLoading,
        changeRequestListLoading,
        userId,
    } = props;

    const scrollSizeSmall = 18;
    const scrollSize = 16;
    const defRowHeight = 20;
    const assignedRef = useRef({});
    const [uploadRowState, setUploadRowState] = useState('');
    const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);
    const [commentsModalIsOpen, setCommentsModalIsOpen] = useState(false);
    const [changeRequestModalIsOpen, setChangeRequestModalIsOpen] = useState(false);
    const [changeRequestAcceptModalIsOpen, setChangeRequestAcceptModalIsOpen] = useState(false);
    const [allIsChecked, setAllIsChecked] = useState(false);
    const isAdmin = userRole === 'Admin';

    const { pathname } = useLocation()
    let endpoint;
    let method;

    if(pathname === '/ar/deliverednopod') {
        method = '{"isReadyToBill": false, "isBilled": false}';
    } else if(pathname === '/ar/readytobill') {
        method = '{"isReadyToBill": true, "isBilled": false}';
    } else if(pathname === '/ar/alreadybilled') {
        method = '{"isReadyToBill": false, "isBilled": true}';
    } else if (pathname === '/ar/openorders'){
        endpoint = 'availableorderlist';
    } else if (pathname === '/ar/changerequestsar') {
        endpoint = 'orderschangerequestslist';
    };

    useClientHubOrder((message) => {
        if (userId !== message.byId) {
            load(resource, method, endpoint, 'withoutLoading');
        }
    }, 'notification');

    useEffect(() => {
        load(resource, method, endpoint);
    }, [load, resource, endpoint, method]);

    useEffect(() => {
        filterDeliveredTrucks(resource, filterType)
    }, [filterDeliveredTrucks, filterType, resource])

    useEffect(() => {
        if (haseError) {
            message.error(actionMessage);
            resetError(resource, method);
        }
    }, [haseError, actionMessage, resetError, resource, method]);

    const listReference = useRef(null);
    const listHeaderReference = useRef(null);
    const mainScroll = useRef(null);
    const startOnScroll = width => mainScroll.current.scrollLeft = width;

    const onScrollX = e => {
        if (listHeaderReference.current && listReference.current && e.target) {
            listHeaderReference.current.scrollLeft =
            listReference.current.scrollLeft = e.target.scrollLeft;
        }
    };

    const header = useMemo(() =>
        <div style={{ overflow: 'hidden' }}>
            <div
                ref={listHeaderReference}
                style={{
                    width: '100%',
                    overflow: 'hidden'
                }}>
                <HeaderRow resource={resource} />
            </div>
        </div>,
    [resource]);
    
    const width = getContentWidth(state[resource].headers) + scrollSize;
    const extraScrollVisibility = window.innerWidth > width ? 'none' : 'flex';
    const [filteredData, setFilteredData] = useState([])

    useEffect(()=>{
        if(loadsAssignedOrders){
            setFilteredData(loadsAssignedOrders)
        }
    },[loadsAssignedOrders])

    useEffect(()=>{
        if(sortType && sortType.sort !== null){
            let newList;
            newList = [...orderBy(loadsAssignedOrders, [c => c.row[sortType.ind].value], sortType.sort)];
            setFilteredData(newList);
        } else{
            setFilteredData(loadsAssignedOrders)
        }
    }, [loadsAssignedOrders, sortType])
    const search = e => {
        let newList = [...loadsAssignedOrders];
        if(e.target.value){
            newList = newList?.filter(item => {
                const searchCell = item.row.filter(item1 => item1.key === "poNumber"
                    || item1.key === "soNumber"
                    || item1.key === "pickLocationName"
                    || item1.key === "drop2WH"
                    || item1.key === "customerName"
                )
                const bool = searchCell.filter(item1 => item1.value.toLowerCase().search(e.target.value.toLowerCase()) !== -1)
                return bool.length
            })
            setFilteredData(newList)
        } else {
            setFilteredData(newList)
        }
    };

    const myLoadsAssignedOrders = filteredData?.filter(d => d?.rowState?.visible);

    const getAsignedItemSize = useCallback(
        index => myLoadsAssignedOrders[index] ? myLoadsAssignedOrders[index].rowState.height : defRowHeight,
        [myLoadsAssignedOrders]);

    const RenderAssignedRow = useCallback(({ data, index, style }) => (
        (index !== undefined && data[index])
            ? <Row
                listRef={assignedRef}
                data={data[index]}
                itemSize={getAsignedItemSize(index)}
                style={style}
                dndRowIndex={index}
                startOnScroll={startOnScroll}
                resource={resource}
                uploadModalIsOpen={uploadModalIsOpen}
                setUploadModalIsOpen={setUploadModalIsOpen}
                setUploadRowState={setUploadRowState}
                setCommentsModalIsOpen={setCommentsModalIsOpen}
                setChangeRequestModalIsOpen={setChangeRequestModalIsOpen}
                changeRequestAcceptModalIsOpen={changeRequestAcceptModalIsOpen}
                setChangeRequestAcceptModalIsOpen={setChangeRequestAcceptModalIsOpen}
            />
            : null
    ), [changeRequestAcceptModalIsOpen, getAsignedItemSize, resource, uploadModalIsOpen]);

    const allOrdersList = useMemo(() => myLoadsAssignedOrders &&
        <AutoSizer disableWidth>
            {({ height }) => (
                <>
                    <VariableSizeList
                        ref={assignedRef}
                        outerRef={el => {
                            listReference.current = el;
                        }}
                        style={{ overflowX: 'hidden', overflowY: 'scroll' }}
                        itemData={myLoadsAssignedOrders}
                        itemCount={myLoadsAssignedOrders.length}
                        height={height}
                        itemSize={getAsignedItemSize}>
                        {RenderAssignedRow}
                    </VariableSizeList>
                </>
            )}
        </AutoSizer>
        , [myLoadsAssignedOrders, getAsignedItemSize, RenderAssignedRow]);

    const content = (<>
        <div style={{ display: 'flex', paddingLeft: '10px' }}>
            {header}
            <div style={{
                width: `${scrollSizeSmall}px`,
                maxWidth: `${scrollSizeSmall}px`,
                minWidth: `${scrollSizeSmall}px`,
                height: '32px',
                backgroundColor: 'lightgrey',
                display: extraScrollVisibility,
            }} />
        </div>
        <div style={{ flexGrow: 1, width: '100%', paddingLeft: '10px' }}>{allOrdersList}</div>
        <div style={{
            backgroundColor: 'red',
            width: '100%',
            height: `${scrollSize}px`,
            overflowX: 'scroll',
            flexShrink: 0,
        }}
            ref={mainScroll}
            onScroll={e => onScrollX(e)}>
            <div className='resp-table-row'>
                <div
                    className='table-body-cell'
                    style={{
                        maxWidth: `${width}px`,
                        width: `${width}px`,
                        minWidth: `${width}px`,
                        boxSizing: 'border-box',
                        backgroundColor: 'black',
                    }} />
            </div>
        </div>
    </>);

    const refreshPage = e => {
        if (pathname === '/ar/openorders') {
            load(resource, method, 'availableorderlist');
        } else if (pathname === '/ar/changerequestsar') {
            load(resource, method, 'orderschangerequestslist');
        } else {
            load(resource, method);
        };
    };

    const markAllAsChecked = () => {
        const orderIds = myLoadsAssignedOrders?.map(order => order.rowState.orderObjectId);
        if (orderIds.length) {
            setAllIsChecked(true);
            setChecked(resource, orderIds);
            setOrderId(resource, orderIds);
            setChangeRequestAcceptModalIsOpen(true);
        }
    };

    const subHeader = (
        <div>
            <div style={{ 
                    display: 'flex',
                    justifyContent: 'space-between',
                    background: '#F2F2F2',
                    height: '50px',     
                    minHeight: '50px',
                }}>
                <div className='customer-order__upper-header__sort'
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "15px",
                        marginBottom: 0
                    }}
                >
                    <div className="button accent"
                        onClick={refreshPage}
                    >Refresh</div>
                    <Input
                        style={{
                            height: "32px",
                            marginLeft: "15px",
                            width: "400px",
                            borderRadius: "12px",
                            borderColor: "#DEDEDE",
                            backgroundColor: "#fff"
                        }}
                        onChange={search}
                        placeholder="Search"
                        type="text"
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <HideShowMenuSwitch />
                </div>
                {uploadModalIsOpen && 
                    <UploadModal
                        rowState={uploadRowState}
                        uploadModalIsOpen={uploadModalIsOpen}
                        setUploadModalIsOpen={setUploadModalIsOpen}
                    />
                }
                {commentsModalIsOpen && 
                    <CommentsModal
                        commentsModalIsOpen={commentsModalIsOpen}
                        setCommentsModalIsOpen={setCommentsModalIsOpen}
                        rowState={uploadRowState}
                    />
                }
                {changeRequestModalIsOpen && 
                    <ChangeRequestModal
                        changeRequestModalIsOpen={changeRequestModalIsOpen}
                        setChangeRequestModalIsOpen={setChangeRequestModalIsOpen}
                        resource={resource}
                    />
                }
                {changeRequestAcceptModalIsOpen && 
                    <ChangeRequestAcceptModal
                        changeRequestAcceptModalIsOpen={changeRequestAcceptModalIsOpen}
                        setChangeRequestAcceptModalIsOpen={setChangeRequestAcceptModalIsOpen}
                        resource={resource}
                        setAllIsChecked={setAllIsChecked}
                    />
                }            
            </div>
            {pathname === '/ar/changerequestsar' && isAdmin ?
                <div style={{ background: '#F2F2F2', padding: '4px 0 14px 24px', fontSize: '14px' }}>
                    <Checkbox onChange={markAllAsChecked} checked={allIsChecked} className='customer-order-admin__checkbox'></Checkbox>
                    <span>Mark all as read</span>
                </div> : null
            }
        </div>
    )

    const pageLoading = (pathname === '/ar/changerequestsar' && changeRequestListLoading) || 
                        (pathname === '/ar/openorders' && openOrdersLoading) || 
                        loading;

    return  <>
                {subHeader}
                {pageLoading ? <Loading /> : <>{content}</>}
            </>;
};

const mapStateToProps = state => ({
    state: state,
    loadsAssignedOrders: state.AccountReceivable.loadsAssignedOrders,
    loading: state.AccountReceivable.loading,
    haseError: state.AccountReceivable.hasError,
    actionMessage: state.AccountReceivable.message,
    filterType: state.AccountReceivable.filterType,
    sortType: state.AccountReceivable.sortType,
    userRole: state.auth.userRole,
    changeRequestListLoading: state.AccountReceivable.changeRequestListLoading,
    openOrdersLoading: state.AccountReceivable.openOrdersLoading,
    userId: state.auth.userId,
});

const mapDispatchToProps = (dispatch) => ({
    load: (resource, method, endpoint, loadingType) => dispatch(load(resource, method, endpoint, loadingType)),
    resetError: (resource, method) => dispatch(resetError(resource, false, method)),
    filterDeliveredTrucks: (resource, key) => dispatch(filterDeliveredTrucks(resource, key)),
    setOrderId: (resource, orderId) => dispatch(setOrderId(resource, orderId)),
    setChecked: (resource, orderId) => dispatch(setChecked(resource, orderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Spreadsheet);