import React from 'react';
import Modal from 'react-modal';

const WarningModal = ({ warningModalIsOpen, setWarningModalIsOpen }) => {
  const closeModal = () => {
    setWarningModalIsOpen(false);
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '400px',
      minHeight: '200px',      
      borderRadius: '8px',
      padding: '6px 12px',
    },
  };

  return (
    <Modal
        isOpen={warningModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        >
        <div className='edit_modal__container'>
          <div className='edit_modal_text'>The Load isn’t in the current week</div>
          <div>
            <button className='edit-modal-button' type="submit" onClick={closeModal}>Close</button>
          </div>
        </div>  
    </Modal>
  );
};

export default WarningModal;