import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect } from "react-redux";
import Split from "react-split-it";

import { openPopup, closePopup, closePopupAsync } from "../../../../../store/actions/popup/popup";
import axios from '../../../../axios/axios';
import InboundLoadConfig from '../../../../screens/LoadArea/InboundLoad/config';

import { PopupTypes } from "../../../custom/popup/popup";
import { CommonLabels } from "../../../../../project/Defines";
import { CloneObject } from '../../../../helper/common';
import DragDrop from "../../../custom/dragDrop";
import errorHandler from '../../../../error/errorHandler';
import Loading from "../../../screen/loading";
import Filter from "../../../list/filter/filter";

import { Data } from "./data";
import { useDragging } from '../useDragging';
import { useFiltering } from '../useFiltering';
import { useSelection } from "../useSelection";
import Toolbar from '../loadCreationToolbar';
import Orders from '../orders';
import Stops from '../stops';
import { useAppointment } from './useAppointment';
import { useLoadSequence } from '../useLoadSequence';

const mainResource = "AvailableOrders";
const assignedOrders = "AssignedOrders";
const groupBy = 'orderGroupIndex';
const groupType = 'batchType';
const groupTypeValue = 'Hard';
const subGroup = 'pickRegionCode';
const defaultDroppableId = 'orders';

const InboundLoad = (props) => {
    const getOrderConfig = useCallback(() => CloneObject(InboundLoadConfig.Pages.Order), []);
    const getStopConfig = useCallback(() => CloneObject(InboundLoadConfig.Pages.Stop), []);
    const getLoadResource = useCallback(() => CloneObject(InboundLoadConfig), []);
    const getOrderForm = useCallback(() => CloneObject(InboundLoadConfig.Pages.OrderForm), []);
    const getAssignedOrderGroups = useCallback(() => CloneObject(InboundLoadConfig.Pages.AssignedOrderGroups), []);
    const getFilterConfig = useCallback(() => CloneObject(InboundLoadConfig.Pages.Filter), []);

    const [stops, setStops] = useState(null);
    const [loads, setLoads] = useState(null);
    const [availableOrders, setAvailableOrders] = useState(null);
    const [loading, setLoading] = useState(false);
    const [disableAdd, setDisableAdd] = useState(false);
    const [loadAdded, setLoadAdded] = useState(false);

    const lastStop = useRef(null);

    const openAlert = props.openAlert;

    const [selectedOrders, truckNotes, availableSelection, handleCheck,
        handleUncheck, onAfterMouseOrdersSelect, handleSuggest, updateSelectedOrders]
        = useSelection(stops, availableOrders, groupBy, groupType, groupTypeValue, props.openAlert, Data.createKey);

    const load = useCallback(() => {
        setLoading(true);
        axios({
            url: getLoadResource().AreaResource,
            method: 'GET'
        })
            .then(response => {
                //available
                response.data.availableOrders.data.forEach(item => {
                    item.puWeek = item.puDate && new Date(item.puDate).getWeek();
                    item.dropWeek = item.dropDate && new Date(item.dropDate).getWeek();
                });
                setAvailableOrders(response.data.availableOrders.data);
                //assigned
                response.data.assignedOrders.data.forEach(item => {
                    item.puWeek = item.puDate && new Date(item.puDate).getWeek();
                    item.dropWeek = item.dropDate && new Date(item.dropDate).getWeek();
                });
                setLoads(response.data.inboundLoads.data);
                const d = new Data(response.data.assignedOrders.data, response.data.inboundLoads.data);
                setStops(d.stops);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    }, [getLoadResource]);

    const resetOrder = (order) => {
        order.loadId = order.inboundLoadNumber = order.truckNumber = order.driverName =
            order.driverPhone = order.rate = order.carrierName = order.inboundTrailer = order.inboundAppointment =
            order.inbioundAppointmentStatus = order.dropInboundAppointment = order.dropInboundAppointmentStatus = null;
    };

    const initOrderFromStop = (order, stop, stops) => {
        if (!order) {
            throw Error('order is undefined');
        }
        if (!stop) {
            throw Error('stop is undefined');
        }
        if (!stops) {
            throw Error('stops is undefined');
        }
        //set load defaults
        order.loadId = stop.loadId;
        order.inboundLoadNumber = stop.loadNumber;
        order.truckNumber = stop.truckNumber;
        order.driverName = stop.driverName;
        order.driverPhone = stop.driverPhone;
        order.rate = stop.rate;
        order.carrierName = stop.carrierName;
        order.inboundTrailer = stop.trailer;
        //try to find correct stop
        const key = Data.createKey(order);
        const corectStop = stops.find(s => s.key === key);
        if (corectStop) {
            order.inboundAppointment = corectStop.pickAppointment;
            order.inbioundAppointmentStatus = corectStop.pickStatus;
            order.dropInboundAppointment = corectStop.dropAppointment;
            order.dropInboundAppointmentStatus = corectStop.dropStatus;
        }
        else {
            order.inbioundAppointmentStatus = order.dropInboundAppointmentStatus = 'Open';
            order.inboundAppointment = order.dropInboundAppointment = null;
        }
    };

    const getAllStopOrders = lookingStops => lookingStops.reduce((r, a) => {
        a.orders.forEach(o => {
            r.push(o);
        });
        return r;
    }, []);

    const initStops = (data, loadsList) => {
        const d = new Data(data, loadsList);
        setStops(d.stops);
    };

    const [updatePickAppointment, updateDropAppointment] = useAppointment(setStops);
    const [updateInboundLoadSequence] = useLoadSequence(setStops);

    const [filterData, getFilteredSortedOrders, getFilteredStops] = useFiltering(
        stops,
        availableOrders,
        InboundLoadConfig.Pages.Filter,
        mainResource,
        assignedOrders,
        subGroup,
        'pickAppointment',
        props.profileSettings,
        'pickRegionCode',
        'AvailableOrders',
        'InboundFilterRegions'
    );

    const [getDragDropData, updateDragDropData] = useDragging(
        defaultDroppableId,
        availableOrders,
        mainResource,
        getFilteredSortedOrders,
        getFilteredStops,
        updateSelectedOrders,
        stops,
        getLoadResource().LoadResource,
        load,
        setAvailableOrders,
        initStops,
        initOrderFromStop,
        getAllStopOrders,
        loads,
        setStops,
        'assignorders',
        'unassignorders',
        'loadId',
        resetOrder
    );

    useEffect(() => {
        load();
    }, [load]);

    const createLoad = () => {
        setDisableAdd(true);
        setLoadAdded(false);
        axios({
            url: `${getLoadResource().Resource}/createloadassignorders`,
            method: 'PUT',
            data: {
                loadId: 0,
                orderIds: selectedOrders.map(i => i.id)
            }
        })
            .then(result => {
                if (result?.data) {
                    const newLoad = result.data;
                    const loadId = newLoad.id;

                    const loadOrders = [...selectedOrders].map(o => {
                        o.loadId = loadId;
                        return o;
                    });
                    const newLoadList = [...loads, newLoad];

                    updateSelectedOrders([], '');

                    const d = new Data(loadOrders, [newLoad]);
                    const newStops = [...stops, ...d.stops];

                    const newAvailableOrders = [...availableOrders];
                    loadOrders.forEach(o => {
                        newAvailableOrders.splice(newAvailableOrders.indexOf(newAvailableOrders.find(ao => ao.id === o.id)), 1);
                        initOrderFromStop(o, newLoad, d.stops);
                        o.loadId = loadId;
                    });
                    setAvailableOrders(newAvailableOrders);
                    setLoads(newLoadList);
                    initStops([...getAllStopOrders(newStops)], newLoadList);

                    openAlert({
                        windowKey: "wndConfirmClose",
                        type: PopupTypes.Alert,
                        title: 'Inbound Load Created',
                        classes: 'truck',
                        html: <div>
                            <text><b>{result.data.loadNumber}</b></text>
                            <text>Total Pallets: {truckNotes.totalPallet}</text>
                            <text>Total Weight: {truckNotes.totalWeight?.toLocaleString()}</text>
                        </div>,
                        buttonYesText: CommonLabels.Buttons.Close,
                    });
                }
            })
            .finally(() => {
                setDisableAdd(false);
                setLoadAdded(true);
            });
    };

    const stopsComponent = <container horizontal="" s_space="" key={'c_1'}>
        <view light=''>
            <Stops
                draggable
                colgroup
                selectable
                handleCheck={handleCheck}
                selectedOrders={selectedOrders}
                onAfterMouseOrdersSelect={onAfterMouseOrdersSelect}
                grouping
                groupBy={groupBy}
                groupType={groupType}
                groupTypeValue={groupTypeValue}
                getFilteredSortedOrders={getFilteredSortedOrders}
                getOrderForm={getOrderForm}
                getAssignedOrderGroups={getAssignedOrderGroups}
                apiResource={'Order/assignloadorderitem'}
                openPopup={props.openPopup}
                stops={stops}
                setStops={setStops}
                availableOrders={availableOrders}
                setAvailableOrders={setAvailableOrders}
                closePopupAsync={props.closePopupAsync}
                closePopup={props.closePopup}
                getOrderConfig={getOrderConfig}
                resource={assignedOrders}
                stopResource={InboundLoadConfig.Pages.Stop.Resource}
                getFilteredStops={getFilteredStops}
                lastStop={lastStop}
                getStopConfig={getStopConfig}
                loads={loads}
                getAllStopOrders={getAllStopOrders}
                setLoads={setLoads}
                initStops={initStops}
                getLoadResource={getLoadResource}
                setLoading={setLoading}
                load={load}
                updatePickAppointment={updatePickAppointment}
                updateDropAppointment={updateDropAppointment}
                updateInboundLoadSequence={updateInboundLoadSequence}
            />
        </view>
    </container>;

    const availableOrdersComponent = <Orders key={'cc_2'}
        data={availableOrders}
        draggable
        colgroup
        droppableId={defaultDroppableId}
        selectable
        handleCheck={handleCheck}
        selectedOrders={selectedOrders}
        onAfterMouseOrdersSelect={onAfterMouseOrdersSelect}
        grouping
        groupBy={groupBy}
        groupType={groupType}
        groupTypeValue={groupTypeValue}
        subGroup={subGroup}
        getFilteredSortedOrders={getFilteredSortedOrders}
        getOrderForm={getOrderForm}
        getAssignedOrderGroups={getAssignedOrderGroups}
        openPopup={props.openPopup}
        stops={stops}
        setStops={setStops}
        availableOrders={availableOrders}
        setAvailableOrders={setAvailableOrders}
        closePopupAsync={props.closePopupAsync}
        closePopup={props.closePopup}
        getOrderConfig={getOrderConfig}
        resource={mainResource}
        attributes={{
            horizontal: "",
            s_space: ""
        }}
        resizable
    />;

    const swapContainers = () => props.swap ? [stopsComponent, availableOrdersComponent] : [availableOrdersComponent, stopsComponent];

    return (
        <>
            <Toolbar
                mainResource={mainResource}
                getOrderConfig={getOrderConfig}
                getLoadResource={getLoadResource}
                load={load}
                truckNotes={truckNotes}
                handleUncheck={handleUncheck}
                assignedOrders={assignedOrders}
                handleSuggest={handleSuggest}
                availableSelection={availableSelection}
                loads={loads}
                initStops={initStops}
                getAllStopOrders={getAllStopOrders}
                setLoads={setLoads}
                stops={stops}
                createLoad={createLoad}
                disableAdd={disableAdd}
                setDisableAdd={setDisableAdd}
                loadAdded={loadAdded}
                setLoadAdded={setLoadAdded}
            />
            {availableOrders && stops && !loading ? <DragDrop
                getDragDropData={getDragDropData}
                dispatch={updateDragDropData}
                selectedRows={selectedOrders}
                oneState
                grouping
                groupBy={groupBy}
                groupType={groupType}
                groupTypeValue={groupTypeValue}
            >
                <view vertical="">
                    {InboundLoadConfig.Pages.Filter ? (
                        <Filter filtersConfig={getFilterConfig()} data={filterData} />
                    ) : null}
                    <view>
                        <Split sizes={[0.7, 0.3]} direction="vertical" gutterSize={3}>
                            {swapContainers()}
                        </Split>
                    </view>
                </view>
            </DragDrop> : <Loading />}
        </>
    );
};

const mapStateToProps = state => {
    return {
        swap: state.auth.profileSettings && state.auth.profileSettings.screens
            && state.auth.profileSettings.screens[mainResource] ? state.auth.profileSettings.screens[mainResource].swap : false,
        profileSettings: state.auth.profileSettings
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        openPopup: (data) => dispatch(openPopup(data)),
        closePopup: (key) => dispatch(closePopup(key)),
        closePopupAsync: (key) => dispatch(closePopupAsync(key)),
        openAlert: (key) => dispatch(openPopup(key))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(errorHandler(InboundLoad, axios));