export const useFormValidation = (messageDispatch, data, mapping, validationMethod, config, componentRefs, messageIdentityPrefix, keyField, type, expandedRowKeys) => {
    let passValidation = true;
    let newExpandedRowKeys = expandedRowKeys ? [...expandedRowKeys] : null;

    const updateExpandedRowKeys = (value) => {
        if (newExpandedRowKeys) {
            newExpandedRowKeys = [...newExpandedRowKeys, value];
        }
    }

    const validateObject = (data, index) => {
        const sectionGroupsData = mapping(config, data);
        const identityPrefix = (index && type) ? (
            messageIdentityPrefix == null
                ? `${type}_${index}`
                : `${messageIdentityPrefix}_${type}_${index}`) : null;
        if (!validationMethod(sectionGroupsData, data, messageDispatch, identityPrefix)) {
            updateExpandedRowKeys(data[keyField]);
            return false;
        }
        return true;
    };

    const validate = newData => {
        const validationData = newData || data;
        
        if (validationData) {
            if (Array.isArray(validationData)) {
                for (let i in validationData) {
                    if (validationData[i].deleted) {
                        continue;
                    }
                    if (!validateObject(validationData[i], i)) {
                        passValidation = false;
                    }
                }
            }
            else {
                if (!validateObject(validationData)) {
                    passValidation = false;
                }
            }
        }
        if (componentRefs) {
            for (let ci in componentRefs.current) {
                if (!componentRefs.current[ci].validate()) {
                    const p = ci.split('.');
                    if (p.length > 1) {
                        const i = p.reverse()[1];
                        updateExpandedRowKeys(validationData[i][keyField]);
                    }
                    passValidation = false;
                }
            }
        }
        return [passValidation, newExpandedRowKeys];
    }
    return validate;

};

export const useFormStatus = (messageDispatch, data, mapping, validationMethod, config, componentRefs, messageIdentityPrefix, keyField, type, expandedRowKeys) => {
    let passValidation = true;
    let countCompNotValidElements = 0;
    const validateObject = (data, index) => {
        const sectionGroupsData = mapping(config, data);
        const identityPrefix = (index && type) ? (
            messageIdentityPrefix == null
                ? `${type}_${index}`
                : `${messageIdentityPrefix}_${type}_${index}`) : null;
        if (!validationMethod(sectionGroupsData, data, messageDispatch, identityPrefix)) {
            return false;
        }
        return true;
    };

    const validate = () => {
        if (data) {
            if (Array.isArray(data)) {
                for (let i in data) {
                    if (data[i].deleted) {
                        continue;
                    }
                    if (!validateObject(data[i], i)) {
                        passValidation = false;
                    }
                }
            }
            else {
                if (!validateObject(data)) {
                    passValidation = false;
                }
            }
        }
        if (componentRefs) {
            for (let ci in componentRefs.current) {
                if (componentRefs.current[ci] && componentRefs.current[ci].check && typeof componentRefs.current[ci].check === 'function') {
                    countCompNotValidElements = componentRefs.current[ci].check();
                    if (countCompNotValidElements > 0) {
                        passValidation = false;
                    }
                }
            }
        }
        return [passValidation, countCompNotValidElements];
    }
    return validate;

};

