import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { VariableSizeList } from 'react-window';
import Split from "react-split-it";
import AutoSizer from 'react-virtualized-auto-sizer';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { trafficControl, resetError, reOrderLoadsByDragAndDrop } from '../../../../store/actions/trafficControl/trafficControl';
import { getContentWidth, getContentWidthSomePartOnly } from '../../../../store/reducers/trafficControl/helper/general';
import Row from './row';
import HeaderRow from './headerRow';
import { SelectableGroup } from '../reactSelectable';
import { message } from 'antd';
import Loading from "../../screen/loading";
import { useClientHub } from "../../../hook/hub/useClientHubTrafficControl";
import { useClientHubOrder } from "../../../hook/hub/useClientHubOrder";

const Spreadsheet = props => {
    const scrollSize = 15;
    const defRowHeight = 40;
    const doneTrafficRef = useRef({});
    const currentTrafficRef = useRef({});
    const { userId, trafficControl, loading, resource, haseError, resetError, actionMessage, state, reOrderLoadsByDragAndDrop, upcomingUrl } = props;
    const overIndex = useRef(null);
    const statusList = {
        currentlyInDoor: 5,
        nextUp: 1,
    };
    const onDrugStartMouseX = useRef(0);

    const [trafficUpdateFromSocket, setTrafficUpdatedFromSocket] = useState(0);
    useClientHub((message) => setTrafficUpdatedFromSocket(trafficUpdateFromSocket + 1), 'notification');
    useClientHubOrder((message) => {
        if (userId !== message.byId) {
            trafficControl(resource, 'withoutLoading');
        }
    }, 'notification');

    useEffect(() => {
        trafficControl(resource);
    }, [resource, trafficControl, trafficUpdateFromSocket]);

    useEffect(() => {
        if (haseError) {
            message.error(actionMessage);
            resetError(resource);
        }
    }, [haseError, actionMessage, resetError, resource]);

    const listFirstReference = useRef(null);
    const listSecondReference = useRef(null);
    const listHeaderReference = useRef(null);
    const listTrafficHeaderReference = useRef(null);
    const pallInSummaryReference = useRef(null);

    const mainScroll = useRef(null);
    const startOnScroll = width => mainScroll.current.scrollLeft = width;
    const onScrollX = e => {      
        if (listHeaderReference.current && listFirstReference.current && listSecondReference.current && e.target) {
            listHeaderReference.current.scrollLeft =
                listFirstReference.current.scrollLeft =
                listSecondReference.current.scrollLeft =
                e.target.scrollLeft;
        }
        if (listTrafficHeaderReference.current && listFirstReference.current && e.target) {
            listTrafficHeaderReference.current.scrollLeft =
                listFirstReference.current.scrollLeft =
                e.target.scrollLeft;
        }
        if (pallInSummaryReference.current && listFirstReference.current && e.target) {
            pallInSummaryReference.current.scrollLeft =
                listFirstReference.current.scrollLeft =
                e.target.scrollLeft;
        }
    };
 
    if (listHeaderReference && listHeaderReference?.current && listHeaderReference?.current?.scrollLeft) {
        listFirstReference.current.scrollLeft = listHeaderReference?.current?.scrollLeft
    };

    const headers = state[resource].headers;
    const width = getContentWidth(headers) + scrollSize;
    const palletSummaryWidthBefore = getContentWidthSomePartOnly(headers, 5);
    const palletSummaryWidth = headers[5].width;

    const header = useMemo(() => <div ref={listHeaderReference} style={{
        width: '100%',
        overflow: 'hidden'
    }}>
        <HeaderRow resource={resource} />
    </div>, [resource]);

    const headerTraffic = useMemo(() => <div ref={listTrafficHeaderReference} style={{
        width: '100%',
        overflow: 'hidden',
        borderRadius: '12px 12px 0 0',
    }}>
        <HeaderRow resource={resource} trafficSubHeader/>
    </div>, [resource]);
    
    const doneTrafficList = state[resource]?.doneTrafficList;
    const currentAndNextTraffic = state[resource]?.currentAndNextTrafficList;

    const palletCountSummary = state[resource].palletCountSummary;
    const titleWidth = state[resource].subHeaders.reduce((acc, item) => acc + item.width, 0);

    const getDoneTrafficItemSize = useCallback(
        index => doneTrafficList[index] ? doneTrafficList[index].rowState.height : defRowHeight,
        [doneTrafficList]);

    const getCurrentAndNextTrafficItemSize = useCallback(
        index => currentAndNextTraffic[index] ? currentAndNextTraffic[index].rowState.height : defRowHeight,
        [currentAndNextTraffic]);

    const RenderDoneTrafficRow = useCallback(({ data, index, style }) => (
        (index !== undefined && data[index])
            ? <Row
                listRef={doneTrafficRef}
                data={data[index]}
                itemSize={getDoneTrafficItemSize(index)}
                style={style}
                startOnScroll={startOnScroll}
                resource={resource}
                doneTrafficList={doneTrafficList}
                currentAndNextTraffic={currentAndNextTraffic}
            />
            : null
    ), [currentAndNextTraffic, doneTrafficList, getDoneTrafficItemSize, resource]);

    const RenderCurrentTrafficRow = useCallback(({ data, index, style }) => (
        (index !== undefined && data[index])
            ? <Row
                listRef={currentTrafficRef}
                data={data[index]}
                itemSize={getCurrentAndNextTrafficItemSize(index)}
                style={style}
                dndRowIndex={index}
                startOnScroll={startOnScroll}
                resource={resource}
                onDrugStartMouseX={onDrugStartMouseX}
                doneTrafficList={doneTrafficList}
                currentAndNextTraffic={currentAndNextTraffic}
            />
            : null
    ), [currentAndNextTraffic, doneTrafficList, getCurrentAndNextTrafficItemSize, resource]);

    const getCloneXPosition = cloneWidth => {
        let left = 0;
        const cloneHalfeWidth = cloneWidth / 2;
        if (onDrugStartMouseX.current) {
            left = onDrugStartMouseX.current - cloneHalfeWidth;
        }
        if (left < 0) {
            return 0;
        }
        return left;
    };

    const renderClone = useCallback((provided, snapshot, rubric) => {
        const cloneWidth = 400;
        provided.draggableProps.style = {
            ...provided.draggableProps.style, ...{
                backgroundColor: '#FFF',
                border: '1px solid #000',
                width: cloneWidth,
                height: '50px',
                fontSize: '14px',
                padding: '10px',
                left: getCloneXPosition(cloneWidth)
            }
        };
        return (
            <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}>
                <div>Drop Status: <span style={{ fontStyle: 'bold' }} ref={overIndex}></span></div>
            </div>
        );
    }, []);

    const first = useMemo(() => doneTrafficList &&
    <AutoSizer disableWidth key='first'>
        {({ height }) => (
            <>
                <VariableSizeList
                    ref={doneTrafficRef}
                    outerRef={el => { listFirstReference.current = el }}
                    style={{ overflowX: 'hidden', overflowY: 'auto', height: doneTrafficList.length * 20  }}
                    itemData={doneTrafficList}
                    itemCount={doneTrafficList.length}
                    height={height}
                    itemSize={getDoneTrafficItemSize}>
                    {RenderDoneTrafficRow}
                </VariableSizeList>
                <div ref={pallInSummaryReference} className='palInSummary'>
                    <span className='palInSummarySpanOne' style={{ width: palletSummaryWidthBefore, maxWidth: palletSummaryWidthBefore, minWidth: palletSummaryWidthBefore }}></span>
                    <span className='palInSummarySpanTwo' style={{ width: palletSummaryWidth, maxWidth: palletSummaryWidth, minWidth: palletSummaryWidth }}>{palletCountSummary}</span>
                    <span className='palInSummarySpanThree'></span>
                </div>
            </>)}
    </AutoSizer>
    , [doneTrafficList, getDoneTrafficItemSize, RenderDoneTrafficRow, palletCountSummary, palletSummaryWidthBefore, palletSummaryWidth]);

    const second = useMemo(() => currentAndNextTraffic &&
    <AutoSizer disableWidth key='second'>
            {({ height }) => (
                <SelectableGroup tolerance={10} useCtrlKey>
                    <Droppable key='second' droppableId="currentAndNextTrafficList" mode="virtual" direction="vertical"
                        ignoreContainerClipping={true}
                        renderClone={renderClone}>
                        {(provided, snapshot) => {
                            const refHanlder = el => {
                                provided.innerRef(el);
                                listSecondReference.current = el;
                            }
                            return (
                                <VariableSizeList
                                    ref={currentTrafficRef}
                                    outerRef={refHanlder}
                                    innerRef={provided.innerRef}
                                    style={{ overflowX: 'hidden', overflowY: 'auto' }}
                                    itemData={currentAndNextTraffic}
                                    itemCount={snapshot.isUsingPlaceholder ? currentAndNextTraffic.length + 1 : currentAndNextTraffic.length}
                                    height={height}
                                    itemSize={getCurrentAndNextTrafficItemSize}>
                                    {RenderCurrentTrafficRow}
                                </VariableSizeList>
                            );
                        }}
                    </Droppable>
                </SelectableGroup>
            )}
        </AutoSizer>
        , [currentAndNextTraffic, renderClone, getCurrentAndNextTrafficItemSize, RenderCurrentTrafficRow]);

    const getSelectedLoad = useCallback(result => {
        const druggedOrder = currentAndNextTraffic[result.source.index];
        return [...currentAndNextTraffic.filter(o => o.rowState.id === druggedOrder.rowState.id)];
    }, [currentAndNextTraffic]);

    const onDragEnd = result => {
        const selectedLoad = getSelectedLoad(result);
        const selectedLoadId = selectedLoad[0].rowState.loadId;

        const currentTrafficOrderedLoadsIDs = currentAndNextTraffic
        .filter(load => load.rowState.loadStatus === statusList.currentlyInDoor)
        .map(load => load.rowState.loadId);

        const nextTrafficOrderedLoadsIDs = currentAndNextTraffic
        .filter(load => load.rowState.loadStatus === statusList.nextUp)
        .map(load => load.rowState.loadId);

        const dragOnCurrentlyInDoor = currentTrafficOrderedLoadsIDs.filter(loadId => loadId === selectedLoadId);
        const draggableList = dragOnCurrentlyInDoor.length ? currentTrafficOrderedLoadsIDs : nextTrafficOrderedLoadsIDs

        const selectedLoadPreviousIndexForCurrentlyList = dragOnCurrentlyInDoor.length ?
                result.source?.index -1 :
                result.source?.index - (currentTrafficOrderedLoadsIDs.length +3);
        const selectedLoadDestinationIndexForCurrentlyList = result.destination?.index -1;

        const selectedLoadPreviousIndexForNextList = result.source?.index - (currentTrafficOrderedLoadsIDs.length + 3);
        const selectedLoadDestinationIndexForNextLoads = result.destination?.index - (currentTrafficOrderedLoadsIDs.length + 3);

        const selectedLoadPreviousIndex = dragOnCurrentlyInDoor.length ? selectedLoadPreviousIndexForCurrentlyList : selectedLoadPreviousIndexForNextList;
        const selectedLoadDestinationIndex = dragOnCurrentlyInDoor.length ? selectedLoadDestinationIndexForCurrentlyList : selectedLoadDestinationIndexForNextLoads;

        let newList = [];
        if (result.source?.index < result.destination?.index) {
            for (let i = 0; i < draggableList.length; i++) {
                if (draggableList[i] !== draggableList[selectedLoadPreviousIndex]) {
                    newList.push(draggableList[i]);
                    if (draggableList[i] === draggableList[selectedLoadDestinationIndex]) {
                        newList.push(selectedLoadId)
                    }
                }
            }
        } else {
            for (let i = 0; i < draggableList.length; i++) {
                if (draggableList[i] !== draggableList[selectedLoadPreviousIndex] && draggableList[i] !== draggableList[selectedLoadDestinationIndex]) {
                    newList.push(draggableList[i]);
                }
                if (draggableList[i] === draggableList[selectedLoadDestinationIndex]) {
                    newList.push(selectedLoadId)
                    if (draggableList[i] !== draggableList[selectedLoadPreviousIndex]) {
                        newList.push(draggableList[i]);
                    }
                }
            }
        }

        let sendRequest = true;
        const currentlyInDoorSize = currentTrafficOrderedLoadsIDs.length;
        const subHeadersSize = 2;

        if (dragOnCurrentlyInDoor.length && (result.destination?.index === 0 || result.destination?.index > currentlyInDoorSize || result.destination?.index === undefined)) {
            sendRequest = false;
        };
        if (!dragOnCurrentlyInDoor.length && (result.destination?.index <= currentlyInDoorSize + subHeadersSize || result.destination?.index === undefined)) {
            sendRequest = false;
        };
        sendRequest && reOrderLoadsByDragAndDrop(resource, newList);
    };

    const splitterWidth = width < window.innerWidth - scrollSize ? width - scrollSize : '';

    const content = (<>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: titleWidth }}>
                <div className='doneTitle'>DONE</div>
                <Link to={upcomingUrl} className='upcomingButton title_btn'>UPCOMING</Link>
            </div>
        </div>
        <div>{headerTraffic}</div>
        <div>{header}</div>
        <div style={{ flexGrow: 1 }}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Split
                    sizes={[50, 50]}
                    direction="vertical"
                    style={{ width: splitterWidth }}
                    gutterSize={10}>
                    {[first, second]}
                </Split>
            </DragDropContext>
        </div>
        <div style={{
            width: '100%',
            overflowX: 'scroll',
            flexShrink: 0
        }}
            ref={mainScroll}
            onScroll={e => onScrollX(e)}>
            <div
                className='table-body-cell'
                style={{
                    maxWidth: `${width}px`,
                    width: `${width}px`,
                    minWidth: `${width}px`,
                }} />
        </div>
    </>);

    return <>{loading ? <Loading /> : content}</>;
};

const mapStateToProps = state => ({
    state: state,
    traffic: state.traffic,
    haseError: state.traffic.hasError,
    loading: state.traffic.loading,
    actionMessage: state.traffic.message,
    userId: state.auth.userId,
});

const mapDispatchToProps = (dispatch) => ({
    trafficControl: (resource, loadingType) => dispatch(trafficControl(resource, loadingType)),
    resetError: resource => dispatch(resetError(resource)),
    reOrderLoadsByDragAndDrop: (resource, newList) => dispatch(reOrderLoadsByDragAndDrop(resource, newList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Spreadsheet);
