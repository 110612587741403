import moment from 'moment';
import { Defines } from '../../../../project/Defines';

export const getCSVData = myLoadsAssignedOrders => {
  const toDate = value => moment.utc(value).local().format(Defines.Format.PrintDate);
  const toDateTime = value => moment.utc(value).local().format(Defines.Format.PrintDateTime);
  const getPODRequestName = number => {
      let PODRequestName = '-';
      if (number === 1) {
          PODRequestName = 'Received'
      } else if (number === 2) {
          PODRequestName = 'Requested'
      }
      else if (number === 3) {
        PODRequestName = 'Don’t Split'
    }
      return PODRequestName;
  }

  const countSum = (orders, type) => {
    const list = orders.map(order => order?.rowState?.orderData[type]);
    let sum = 0;
    for (let i = 0; i < list.length; i++) {
      sum += list[i];
    }
    return sum;
  }

  const countLoadReadyDate = ordersData => {
    const filterData = ordersData.filter(o => o.rowState.orderData['readyDate']);
        if (filterData && filterData.length > 0) {
          const maxDate = Math.max.apply(
            null,
            filterData.map(order => {
              return new Date(order.rowState.orderData['readyDate']);
            }),
          );
          if (maxDate) {
            return moment.utc(maxDate).local().format(Defines.Format.PrintDate);
          }
        }
  }

  const countLoadPickAppt = ordersData => {
    let value = '';
    if (ordersData.length !== 0) {
      const order = ordersData[ordersData.length - 1];
      if (order) {
        value = order.row.find(f => f.key === 'inboundPickAppointment').value;
      }
    }
    return value ? moment.utc(value).local().format(Defines.Format.PrintDateTime) : '';
  }

  let CSVData = [];
  for (let i = 0; i < myLoadsAssignedOrders?.length; i++) {
      const loadType = myLoadsAssignedOrders[i]?.rowState?.loadData?.loadType; // 1 - inbound, 3 - outbound
      let tempObject = {};
      let object = {
          'Ready date': myLoadsAssignedOrders[i]?.ordersData?.length ? countLoadReadyDate(myLoadsAssignedOrders[i]?.ordersData) : myLoadsAssignedOrders[i]?.rowState?.orderData?.readyDate && toDate(myLoadsAssignedOrders[i]?.rowState?.orderData?.readyDate),
          'Due Date': myLoadsAssignedOrders[i]?.rowState?.orderData?.dueDate && toDate(myLoadsAssignedOrders[i]?.rowState?.orderData?.dueDate),
          Cust: myLoadsAssignedOrders[i]?.rowState?.orderData?.customerCodeOrName,
          Pick: myLoadsAssignedOrders[i]?.ordersData?.length ? myLoadsAssignedOrders[i]?.ordersData[0]?.rowState?.orderData?.pickLocation : myLoadsAssignedOrders[i]?.rowState?.orderData?.pickLocation,
          Delivery: myLoadsAssignedOrders[i]?.ordersData?.length ? myLoadsAssignedOrders[i]?.ordersData[0]?.rowState?.orderData?.dropLocation : myLoadsAssignedOrders[i]?.rowState?.orderData?.dropLocation,
          'PO#': myLoadsAssignedOrders[i]?.ordersData?.length ? '' : ` ${myLoadsAssignedOrders[i]?.rowState?.orderData?.poNumber} `,
          'SO#': myLoadsAssignedOrders[i]?.ordersData?.length ? '' : ` ${myLoadsAssignedOrders[i]?.rowState?.orderData?.soNumber} `,
          Pal: myLoadsAssignedOrders[i]?.ordersData?.length ? countSum(myLoadsAssignedOrders[i]?.ordersData, 'palletCount') : myLoadsAssignedOrders[i]?.rowState?.orderData?.palletCount,
          SPA: myLoadsAssignedOrders[i]?.ordersData?.length ? countSum(myLoadsAssignedOrders[i]?.ordersData, 'space') : myLoadsAssignedOrders[i]?.rowState?.orderData?.space,
          CS: myLoadsAssignedOrders[i]?.ordersData?.length ? countSum(myLoadsAssignedOrders[i]?.ordersData, 'cases') : myLoadsAssignedOrders[i]?.rowState?.orderData?.cases,
          LBS: myLoadsAssignedOrders[i]?.ordersData?.length ? countSum(myLoadsAssignedOrders[i]?.ordersData, 'weight') : myLoadsAssignedOrders[i]?.rowState?.orderData?.weight,
          WK: myLoadsAssignedOrders[i]?.rowState?.orderData?.puDate ? new Date(myLoadsAssignedOrders[i].rowState.orderData.puDate).getWeek() : '',
          'In Load': myLoadsAssignedOrders[i]?.rowState?.orderData?.inLoad,
          'Out Load': myLoadsAssignedOrders[i]?.rowState?.orderData?.outLoad,
          Carrier: myLoadsAssignedOrders[i]?.ordersData ? myLoadsAssignedOrders[i]?.rowState?.loadData?.carrierName : '',
          Rate: myLoadsAssignedOrders[i].ordersData?.length && myLoadsAssignedOrders[i]?.rowState?.loadData?.rate ? myLoadsAssignedOrders[i]?.rowState?.loadData?.rate : '',
          'Additional Pay': `${myLoadsAssignedOrders[i].ordersData && myLoadsAssignedOrders[i]?.rowState?.loadData?.amount ? `$${myLoadsAssignedOrders[i]?.rowState?.loadData?.amount} ${myLoadsAssignedOrders[i]?.rowState?.loadData?.additionalPay}` : ''}`,
          'Driver/Truck': `${myLoadsAssignedOrders[i]?.ordersData ? `${myLoadsAssignedOrders[i]?.rowState?.loadData?.driverName} ${myLoadsAssignedOrders[i]?.rowState?.loadData?.truckNumber} ${myLoadsAssignedOrders[i]?.rowState?.loadData?.trailer}` : ''}`,
          'EFS': `${myLoadsAssignedOrders[i].ordersData && myLoadsAssignedOrders[i]?.rowState?.loadData?.efsSum ? `$${myLoadsAssignedOrders[i]?.rowState?.loadData?.efsSum}` : ''}`,
          Notes: myLoadsAssignedOrders[i]?.ordersData?.length ? myLoadsAssignedOrders[i]?.rowState?.loadData?.notes : '',
      }

      if (loadType === 1) {
        tempObject = {
          'Pick IN': myLoadsAssignedOrders[i]?.rowState?.orderData?.inboundPickIn && toDateTime(myLoadsAssignedOrders[i]?.rowState?.orderData?.inboundPickIn),
          'Pick OUT': myLoadsAssignedOrders[i]?.rowState?.orderData?.inboundPickOut && toDateTime(myLoadsAssignedOrders[i]?.rowState?.orderData?.inboundPickOut),
          'Pick Appt': myLoadsAssignedOrders[i]?.ordersData?.length ? countLoadPickAppt(myLoadsAssignedOrders[i]?.ordersData) : myLoadsAssignedOrders[i]?.rowState?.orderData?.inboundPickAppointment ? toDateTime(myLoadsAssignedOrders[i]?.rowState?.orderData?.inboundPickAppointment) : '',
          'Del Appt': myLoadsAssignedOrders[i]?.rowState?.orderData?.inboundDropAppointment && toDate(myLoadsAssignedOrders[i]?.rowState?.orderData?.inboundDropAppointment),
          'Del In': myLoadsAssignedOrders[i]?.rowState?.orderData?.inboundDropIn && toDateTime(myLoadsAssignedOrders[i]?.rowState?.orderData?.inboundDropIn),
          'Del Out': myLoadsAssignedOrders[i]?.rowState?.orderData?.inboundDropOut && toDateTime(myLoadsAssignedOrders[i]?.rowState?.orderData?.inboundDropOut),
          'LOAD #': myLoadsAssignedOrders[i]?.ordersData?.length ? myLoadsAssignedOrders[i]?.ordersData[0]?.rowState?.orderData?.inboundLoadNumber : myLoadsAssignedOrders[i]?.rowState?.orderData?.inboundLoadNumber,
          Lumper: myLoadsAssignedOrders[i]?.rowState?.orderData?.inboundLumper,
          'POD Request': getPODRequestName(myLoadsAssignedOrders[i]?.rowState?.orderData?.inPodRequestStatus),
          'POD Doc.': myLoadsAssignedOrders[i]?.rowState?.orderData?.callCheckInAttachment?.fileName,
          'POD Status': myLoadsAssignedOrders[i]?.rowState?.orderData?.inNotes,
        }
      } else {
        tempObject = {
          'Pick IN': myLoadsAssignedOrders[i]?.rowState?.orderData?.outboundPickIn && toDateTime(myLoadsAssignedOrders[i]?.rowState?.orderData?.outboundPickIn),
          'Pick OUT': myLoadsAssignedOrders[i]?.rowState?.orderData?.outboundPickOut && toDateTime(myLoadsAssignedOrders[i]?.rowState?.orderData?.outboundPickOut),
          'Pick Appt': myLoadsAssignedOrders[i]?.ordersData?.length ? countLoadPickAppt(myLoadsAssignedOrders[i]?.ordersData) : myLoadsAssignedOrders[i]?.rowState?.orderData?.outboundPickAppointment ? toDateTime(myLoadsAssignedOrders[i]?.rowState?.orderData?.outboundPickAppointment) : '',
          'Del Appt': myLoadsAssignedOrders[i]?.rowState?.orderData?.outboundDropAppointment && toDate(myLoadsAssignedOrders[i]?.rowState?.orderData?.outboundDropAppointment),
          'Del In': myLoadsAssignedOrders[i]?.rowState?.orderData?.outboundDropIn && toDateTime(myLoadsAssignedOrders[i]?.rowState?.orderData?.outboundDropIn),
          'Del Out': myLoadsAssignedOrders[i]?.rowState?.orderData?.outboundDropOut && toDateTime(myLoadsAssignedOrders[i]?.rowState?.orderData?.outboundDropOut),
          'LOAD #': myLoadsAssignedOrders[i]?.ordersData?.length ? myLoadsAssignedOrders[i]?.ordersData[0]?.rowState?.orderData?.outboundLoadNumber : myLoadsAssignedOrders[i]?.rowState?.orderData?.outboundLoadNumber,
          Lumper: myLoadsAssignedOrders[i]?.rowState?.orderData?.outboundLumper,
          'POD Request': getPODRequestName(myLoadsAssignedOrders[i]?.rowState?.orderData?.outPodRequestStatus),
          'POD Doc.': myLoadsAssignedOrders[i]?.rowState?.orderData?.callCheckOutAttachment?.fileName,
          'POD Status': myLoadsAssignedOrders[i]?.rowState?.orderData?.outNotes,
        }
      }
      const newObject = { ...object, ...tempObject}
      CSVData.push(newObject);
  };

  return CSVData;
}