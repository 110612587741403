export const GET_CALL_CHECK_LOADING = 'GET_CALL_CHECK_LOADING';
export const GET_CALL_CHECK = 'GET_CALL_CHECK';
export const FILTER_CALL_CHECKS = 'FILTER_CALL_CHECKS';
export const CALL_CHECK_ERROR = 'CALL_CHECK_ERROR';
export const RELOAD_ON_ERROR_CALL_CHECK = 'RELOAD_ON_ERROR_CALL_CHECK';
export const RESET_ERROR = 'RESET_ERROR';
export const SET_DISPLAY_STATE = 'SET_DISPLAY_STATE';
export const SET_HEADER_WIDTH_CALL_CHECK = 'SET_HEADER_WIDTH_CALL_CHECK';
export const REACTED_ASSIGNED = 'REACTED_ASSIGNED';
export const REACTED_ASSIGNEDS = 'REACTED_ASSIGNEDS';
export const SET_CALL_CHECK_DATA = 'SET_CALL_CHECK_DATA';
export const GET_HISTORY_CALL_CHECK = 'GET_HISTORY_CALL_CHECK';
export const GET_HISTORY_CALL_CHECK_LOADING = 'GET_HISTORY_CALL_CHECK_LOADING';

export const getActionType = (resource, key) => {
    return CALL_CHECK_BUILD[key];
};

export const CALL_CHECK_BUILD = {
    GET_CALL_CHECK: 'GET_CALL_CHECK',
    GET_CALL_CHECK_LOADING: 'GET_CALL_CHECK_LOADING',
    FILTER_CALL_CHECKS: 'FILTER_CALL_CHECKS',
    CALL_CHECK_ERROR: 'CALL_CHECK_ERROR',
    RELOAD_ON_ERROR_CALL_CHECK: 'RELOAD_ON_ERROR_CALL_CHECK',
    SET_HEADER_WIDTH_CALL_CHECK: 'SET_HEADER_WIDTH_CALL_CHECK',
    SET_DISPLAY_STATE: 'SET_DISPLAY_STATE',
    REACTED_ASSIGNED: 'CALL_CHECK_REACTED_ASSIGNED',
    REACTED_ASSIGNEDS: 'CALL_CHECK_REACTED_ASSIGNEDS',
    SET_CALL_CHECK_DATA: 'SET_CALL_CHECK_DATA',
    RESET_ERROR: 'RESET_ERROR',
    GET_HISTORY_CALL_CHECK: 'GET_HISTORY_CALL_CHECK',
    GET_HISTORY_CALL_CHECK_LOADING: 'GET_HISTORY_CALL_CHECK_LOADING',
};
