import React, { useState, useRef, useEffect } from 'react';
import { connect } from "react-redux";
import {Link, useLocation} from 'react-router-dom';
import moment from 'moment';
import { Defines } from '../../../../project/Defines';
import { InputNumber, Select, Tooltip, Popover  } from 'antd';
import { setItemData, setItemDisplayState, paidButtonAction } from '../../../../store/actions/deliveredTrucksActions/deliveredTrucks';
import { enabled } from 'store/dist/store.legacy';
import { GenerateMessages } from '../../../helper/element';
import Uploader from './Uploader';
import './index.css';
import AuthenticatedLink from './authenticatedLinkDeliveredTrucks';
import HistoryModal from '../../custom/spreadsheet/HistoryModal';

const Cell = (
    {
        state,
        cellData,
        itemSize,
        paidButtonAction,
        rowState,
        setItemData,
        setItemDisplayState,
        resource,
        loadsAssignedOrders,
        setUploadRowState,
        setUploadModalIsOpen,
        setCommentsModalIsOpen
    }) => {
    const loadArea = state[resource];
    const redonly = 1;
    const editable = 2;
    const header = loadArea.headers.find(h => h.key === cellData.key);
    const selectNameOptionsPODStatus = [ { name: 'Delivered', id: 'Delivered' }, { name: 'Shortage', id: 'Shortage' }, { name: 'Overage', id: 'Overage' }, { name: 'Reject', id: 'Reject' }];
    const selectNameOptionsPOD = [{ name: '-', id: 0 }, { name: 'Received', id: 1 }, { name: 'Requested', id: 2 }, { name: 'Don’t Split', id: 3}];
    const selectOptionsPOD = { null: '-', 0: '-', 1: 'Received', 2: 'Requested', 3: 'Don’t Split' };
    const selectOptionsPODStatus = { 'Delivered': 'Delivered', 'Shortage': 'Shortage', 'Overage': 'Overage', 'Reject': 'Reject' };
    const value2= cellData.value2;

    const width = header.width;
    const lastSelectId = loadArea.lastSelected.id;
    const lastSelectHeaderKey = loadArea.lastSelected.headerKey;
    const lastSelectenable = loadArea.lastSelected.enable;
    const rowStateId = rowState.id;
    const headerKey = header.key;
    const [displayState, setDisplayState] = useState(redonly);
    const [tempData, setTempData] = useState(null);
    const [isHistoryModalOpen, setHistoryModalOpen] = useState(false);
    const orderGuid = rowState.loadData.loadType === 1 ? rowState?.orderData?.callCheckInAttachment?.guid : rowState.loadData.loadType === 3 ? rowState?.orderData?.callCheckOutAttachment?.guid : '';

    const ordersAssignedToCurrentLoad = loadsAssignedOrders.filter(order => order.rowState.loadData.id === rowState.loadId && order.rowState.type === 'order');
    const poNumbers = ordersAssignedToCurrentLoad.map(order => order.rowState.orderData.poNumber).join(', ');
    const mailBodyText = `Please send POD for the bellow PO#s: ${poNumbers}`;
    const carrierEmail = rowState.loadData.carrierEmail;

    useEffect(() => {
        if (lastSelectId === rowStateId && lastSelectHeaderKey === headerKey && lastSelectenable) {
            setDisplayState(enabled);
        }
    }, [lastSelectId, lastSelectHeaderKey, lastSelectenable, rowStateId, headerKey]);
    const {pathname} = useLocation()

    useEffect(() => {
        if ((cellData.key === 'orderStatusByCall' ||
            cellData.key === 'delayedReason' ||
            cellData.key === 'loadCallCheckNote' ||
            cellData.key === 'podDoc' ||
            cellData.key === 'orderNotesByCall' ||
            cellData.key === 'podRequestStatus')
            &&
            resource === "AccountPayable" && pathname === '/ap/deliveredtrucks') {
            setDisplayState(editable);
        }
    }, [cellData.key, pathname, resource]);

    const data = cellData.value;
    const podNotesHistory = cellData?.value2;
    const cellStyle = cellData.cellStyle;
    const tooltip = cellData.tooltip;
    const color = rowState.type === 'load' ? 'white' : '#101C1C';

    useEffect(() => {
        setTempData(data);
    }, [data]);

    const inputReference = useRef(null);

    const dataCompare = (newData, data) => {

        switch (cellData.fieldType) {
            case 'date':
            case 'dateTime':
                if (newData instanceof Date && data instanceof Date) {
                    return newData.getTime() === data.getTime();
                }
                return newData === data;
            default:
                return newData === data;
        }
    }

    const setData = value => {
        onSetBlur();
        let newValue = value;
        if (value !== '') {
            switch (cellData.fieldType) {
                case 'date':
                    newValue = new Date(value);
                    break;
                case 'dateTime':
                    newValue = new Date(value);
                    break;
                case 'text':
                    newValue = value;
                    break;
                default:
                    newValue = value;
                    break;
            }
        }
        if (!dataCompare(newValue, tempData)) {
            setTempData(newValue);  
            setTimeout(() => {
                setItemData(resource, newValue, rowState, header);
            }, 100);
        }
    };

    const handleEdit = () => {
        setUploadRowState(rowState);
        setTimeout(() => {   
            setCommentsModalIsOpen(true);
        }, 100);
    };

    const changeImage =  (data) => {
        setTimeout(() => {
            setUploadRowState(rowState);
        }, 100);
    }
    const paidButtonClick = () => {
        paidButtonAction(resource, "pay", rowState)
    }
    const getData = () => {        
        if (tempData !== '') {
            switch (cellData.fieldType) {
                case 'date':
                    return <>{tempData ? moment(tempData).format(Defines.Format.PrintDate) : ''}</>
                case 'button':
                    return <button className="delivered_btn-callcheck" onClick={paidButtonClick}>{tempData}</button>;
                case 'dateTime':
                    return <div className='date-time__container'>
                        <div className='date__part'>{tempData ? moment(tempData).format(Defines.Format.Date): ''}</div>
                        <div className='time__part'>{tempData ? moment(tempData).format(Defines.Format.Time): ''}</div>
                    </div>;
                case 'link':
                    return <Link style={{ color, textDecoration: 'underline' }} to={rowState.loadData.loadType === 3  ? '/orderprocess/outboundloadbuld' : '/orderprocess/inboundloadbuld'}>{tempData}</Link>
                case 'text':
                    if (header.key === 'carrierName' && rowState.type === 'load') {
                        return (
                            <a  className='call-checking__carrierName'
                                style={{color: 'white'}}
                                href={`mailto:${carrierEmail}?subject=POD request&body=${mailBodyText}`}>
                                {tempData}
                            </a>)
                    } else {
                        return tempData;
                    }
                case 'textInputPO':
                    let asterisks = '';
                    for(let i = 0; i < value2; i++) {
                        asterisks += '*';
                    }
                    return <div style={{ display: 'flex'}}>
                        <input
                            defaultValue={tempData ? tempData : ''}
                            className={`disabled__input ${value2 ? 'po__data' : ''} `}
                            disabled
                        />
                        {value2 &&
                            <span
                                className='disabled__input asterisk'
                                onClick={() => setHistoryModalOpen(true)}>
                            {value2 ? asterisks : ''}
                            </span>}
                    </div>
                case 'download':
                    const fileName = rowState.loadData.loadType === 1 ? rowState.orderData.callCheckInAttachment?.fileName : rowState.loadData.loadType === 3 ? rowState.orderData.callCheckOutAttachment?.fileName : '';
                    return <div style={{position: 'relative'}}>
                        {fileName?
                            <AuthenticatedLink url={`/attachment/download/${orderGuid}`} filename={fileName}>
                                <span className='file-name' style={{ width: header.width - 26 }}>{fileName}</span>
                            </AuthenticatedLink>
                        : ''}
                    </div>
                case 'popover':
                    return (
                        <Popover content={content} title="POD status list">
                            {tempData}
                        </Popover>
                    );
                case 'actions':
                    return (
                        <div className='edit_icons-callcheck'>
                        <div onClick={handleEdit}>
                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.1965 6.02L12.1165 6.94L3.05647 16H2.13647V15.08L11.1965 6.02ZM14.7965 0C14.5465 0 14.2865 0.1 14.0965 0.29L12.2665 2.12L16.0165 5.87L17.8465 4.04C18.2365 3.65 18.2365 3.02 17.8465 2.63L15.5065 0.29C15.3065 0.09 15.0565 0 14.7965 0ZM11.1965 3.19L0.136475 14.25V18H3.88647L14.9465 6.94L11.1965 3.19Z" fill="#3C8680"/>
                            </svg>
                        </div>
                        </div>
                    );
                default:
                return tempData;
        }
        }
        return tempData
    };

    const handleSelectChange = (value) => {
        if (header.key === 'orderNotesByCall') {
            setData(value);
        } else {
            setData(parseInt(value));
        }
    };

    let splittedData = [];
    if (header.key === 'orderNotesByCall' && podNotesHistory && podNotesHistory?.[0]) {
        splittedData = podNotesHistory?.split(',');
    };

    const content = splittedData.length ? (
        <div>
            <table className='pod-status-modal__table'>
                <tr className='pod-status-modal__tr'>
                    <th className='pod-status-modal__th'>
                        Created By &nbsp; &nbsp; &nbsp; Data Time &nbsp; &nbsp; Status note</th>
                </tr>
                {splittedData?.map(data => (
                    <tr className='pod-status-modal__tr' >
                        <td className='pod-status-modal__td'>{data}</td>
                    </tr>
                ))}
            </table>
        </div>
    ) : <div>You don't have any notes</div>;

    const getInput = () => {
        switch (cellData.fieldType) {
            case 'integer':
                return <InputNumber type="number" min={0}
                    ref={inputReference}
                    value={tempData ? tempData : ''}
                    onBlur={(e) => {
                        if (inputReference.current) {
                            setDisplayState(redonly);
                        }
                        setData(e.target.value)
                    }}
                    className='spreadsheet-number-input'
                />;
            case 'textInput':
                return <input
                    defaultValue={tempData ? tempData : ''}
                    ref={inputReference}
                    onBlur={(value) => {
                        setData(value.currentTarget.value)
                    }}
                    style={{ cursor: 'pointer', textAlign: 'center', width: 'inherit' }}
                    className={header.key === 'loadCallCheckNote' ? 'green__BG' : ''}
                />;
            case 'textInputPO':
                let asterisks = '';
                for(let i = 0; i < value2; i++) {
                    asterisks += '*';
                }
                return <div style={{ display: 'flex'}}>
                    <input
                        ref={inputReference}
                        defaultValue={tempData ? tempData : ''}
                        className={`disabled__input ${value2 ? 'po__data' : ''} `}
                        onBlur={(value) => {
                            if (inputReference.current) {
                                setDisplayState(redonly);
                            }
                            setData(value.currentTarget.value)
                        }}
                    />
                    {value2 &&
                        <span
                            className='disabled__input asterisk'
                            ref={inputReference}
                            onClick={() => setHistoryModalOpen(true)}>
                        {asterisks}
                        </span>
                    }
                </div>
            case 'select':
                let selectOption;
                let selectNameOptions;
                if (header.key === 'podRequestStatus') {
                    selectOption = selectOptionsPOD;
                    selectNameOptions = selectNameOptionsPOD;
                }
                return <Select
                    ref={inputReference} 
                    value={selectOption[`${tempData}`]}
                    className={`rowSelect ${rowState.type === 'order' ? 'order_cell' : ''} ${rowState?.isSub ? 'sub_order_cell' : ''}`}
                    onChange={handleSelectChange}
                    defaultValue={selectOption[`${tempData}`]}>
                    {selectNameOptions.map((item, index) => (
                        <Select.Option key={index} value={item.id}>
                            {item.name}
                        </Select.Option>
                    ))}
                </Select>;
            case 'select_and_popover':
                return (
                <Popover content={content} title="POD status list">
                    <Select
                        ref={inputReference}
                        value={selectOptionsPODStatus[`${tempData}`]}
                        className={`rowSelect ${rowState.type === 'order' ? 'order_cell' : ''} ${rowState?.isSub ? 'sub_order_cell' : ''}`}
                        onChange={handleSelectChange}
                        defaultValue={selectOptionsPODStatus[`${tempData}`]}>
                        {selectNameOptionsPODStatus.map((item, index) => (
                            <Select.Option key={index} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Popover>
                );
            case 'upload':
                return <div style={{position: 'relative'}}>
                    <div onClick={
                        ()=> changeImage(rowState)}
                    >
                        <Uploader
                            rowState={rowState}
                            setData={setData}
                            setUploadModalIsOpen={setUploadModalIsOpen}
                            headerWidth={header.width}>
                        </Uploader>
                    </div>
                </div>

            default: return <></>;
        }
    };

    const getDataWithTooltip = () => tooltip ? (<Tooltip title={GenerateMessages([tooltip])}>
        {displayState === redonly ? getData() : getInput()}
    </Tooltip>) : displayState === redonly ? getData() : getInput();

    const dataDisplay = () =>  getDataWithTooltip();

    const cellDefaultStyle = {
        maxWidth: `${width}px`,
        width: `${width}px`,
        minWidth: `${width}px`,
        height: `${itemSize}px`,
        lineHeight: 'inherit',
    };

    const onSetFocus = () => {
        // startOnScroll && startOnScroll(offsetWidth);
        if (cellData.editable) {
            setDisplayState(editable);
        }
        // setItemDisplayState(resource, rowState, header, cellData.editable);
    };

    const onSetBlur = () => {
        setItemDisplayState(resource, rowState, header, false);
    };

    const editableFunction = (cellData) => (
        cellData.editable ? setDisplayState(editable) : null
    );

    return (
        <>
            <div
                onFocus={() => onSetFocus()}
                onClick={() => { editableFunction(cellData)}}
                className='table-body-cell'
                style={{ ...cellDefaultStyle, ...cellStyle, color: color}}
                tabIndex={displayState === redonly ? 0 : -1}
                >
                {dataDisplay()}
            </div>
            {isHistoryModalOpen &&
                <HistoryModal
                    isHistoryModalOpen={isHistoryModalOpen}
                    setHistoryModalOpen={setHistoryModalOpen}
                    resource={resource}
                    rowState={rowState}
                    cellDataKey={cellData.key}
                />
            }
        </>
    );
};

const mapStateToProps = state => ({
    state: state,
    loadsAssignedOrders: state.AccountPayable.loadsAssignedOrders,
});

const mapDispatchToProps = (dispatch) => ({
    setItemData: (resource, value, rowState, header) => dispatch(setItemData(resource, value, rowState, header)),
    paidButtonAction: (resource, method, rowState) => dispatch(paidButtonAction(resource, method, rowState)),
    setItemDisplayState: (resource, rowState, header, enable) => dispatch(setItemDisplayState(resource, rowState, header, enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Cell);