import React, { useCallback, useState } from 'react';
import { connect } from "react-redux";
import { CaretDownOutlined, CaretUpOutlined} from '@ant-design/icons';
import { deleteLoad, updateLoadData } from '../../../../store/actions/loadArea/loadArea';
import { openPopup, closePopup, closePopupAsync } from "../../../../store/actions/popup/popup";
import { PopupTypes } from "../../custom/popup/popup";
import { CommonLabels } from "../../../../project/Defines";
import LoadDetailsModal from './loadDetailsModal';
import { setReOrderLoad } from '../../../../store/actions/loadArea/loadArea';
import { Select } from 'antd';

const RightActionCell = ({
    state,
    cellData,
    itemSize,
    rowState,
    deleteLoad,
    openConfirm,
    resource,
    setReOrderLoad,
    }) => {
    const loadArea = state[resource];
    const header = loadArea.headers.find(h => h.key === cellData.key);
    const width = header.width;

    const cellStyle = {
        maxWidth: `${width}px`,
        width: `${width}px`,
        minWidth: `${width}px`,
        height: `${itemSize}px`,
        position: 'sticky',
        backgroundColor: '#65A7A5',
        right: 0,
        borderLeft: '1px solid #000'
    };
    const [isDetailsModalOpen, setDetailsModalToOpen] = useState(false);

    const deleteConfirmLoad = useCallback(rowState => {
        openConfirm({
            windowKey: 'wndConfirmDelete',
            type: PopupTypes.Confirm,
            title: CommonLabels.Messages.ConfirmDeleteTitle('Inbound Load', rowState.loadData.loadNumber),
            text: CommonLabels.Messages.ConfirmDeleteText,
            buttonYesText: CommonLabels.Buttons.Delete,
            buttonNoText: CommonLabels.Buttons.Cancel,
            yesCallback: () => setTimeout(() => deleteLoad(resource, rowState), 10)
        });
    }, [openConfirm, deleteLoad, resource]);

    const editLoad = () => {
        setDetailsModalToOpen(true);
    };

    const subOrders_loadIds = state?.outboundload?.subOrders?.map(sub => sub.loadId);
    const loadWithSub = subOrders_loadIds?.includes(rowState?.subId?.id);

    const handleClickUp = () => {
        setReOrderLoad(resource, state, rowState, 'up');
    };

    const handleClickDown = () => {
        setReOrderLoad(resource, state, rowState, 'down');
    };

    const handleChangeSorting = e => {
        setOrderSortNumber(e);
        setReOrderLoad(resource, state, rowState, e);
    }

    const loadId = rowState.loadObjectId;
    const locationId = resource === 'load' ? 'pickLocationId' : 'dropLocationId';
    const orderPossitionIndex = resource === 'load' ? 'inOrderPossitionIndex' : 'outOrderPossitionIndex';
    const orderLocationId = rowState?.orderData?.[locationId];
    const loadOrders = state[resource].loadsAssignedOrders?.filter(item => item.rowState.loadObjectId === loadId);
    const ordersWithSameLocation = loadOrders.filter(order => order.rowState?.orderData?.[locationId] === orderLocationId);
    const ordersIndexesList = ordersWithSameLocation.map(order => order.rowState.orderData?.[orderPossitionIndex]);
    const index = ordersWithSameLocation.findIndex(order => order.rowState.orderObjectId === rowState?.orderObjectId);
    const firstArrowIsDisabled = index === 0;
    const lastArrowIsDisabled = index === ordersWithSameLocation.length-1;
    const [orderSortNumber, setOrderSortNumber] = useState(rowState?.orderData?.[orderPossitionIndex]);
    const orderHasPickOutData = resource === 'load' ? rowState?.orderData?.inboundPickOut : rowState?.orderData?.outboundPickOut;

    return (
        <>
            <div
                className='table-body-cell'
                style={cellStyle}>
                {rowState.type === 'load' ? 
                <elastic><action>
                    <div className="button micro edit" onClick={() => editLoad(rowState)}>
                        <svg width="14" height="14" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.06 6.02L11.98 6.94L2.92 16H2V15.08L11.06 6.02ZM14.66 0C14.41 0 14.15 0.1 13.96 0.29L12.13 2.12L15.88 5.87L17.71 4.04C18.1 3.65 18.1 3.02 17.71 2.63L15.37 0.29C15.17 0.09 14.92 0 14.66 0ZM11.06 3.19L0 14.25V18H3.75L14.81 6.94L11.06 3.19Z" fill="white"/>
                        </svg>
                    </div>
                    {rowState?.loadData?.carrierId ? null : 
                    <div disabled={loadWithSub} 
                        className='button micro delete' 
                        onClick={() => deleteConfirmLoad(rowState)}>
                            <svg width="14" height="14" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 6V16H3V6H11ZM9.5 0H4.5L3.5 1H0V3H14V1H10.5L9.5 0ZM13 4H1V16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4Z" fill="white"/>
                            </svg>
                    </div>}
                </action></elastic> : cellData.value}
                {(rowState.type === 'order' && !!rowState.loadObjectId && !rowState.isSub && !rowState.orderHasSub) ?
                <div className='arrow__container'>
                    <div>
                        <button disabled={firstArrowIsDisabled || orderHasPickOutData} className={`arrow__inherit_BG ${firstArrowIsDisabled || orderHasPickOutData ? 'arrow__disabled' : '' }`} >
                            <CaretUpOutlined onClick={handleClickUp} className='caret_up arrow__disabled' />
                        </button>
                        <button disabled={lastArrowIsDisabled || orderHasPickOutData} className={`arrow__inherit_BG ${lastArrowIsDisabled || orderHasPickOutData ? 'arrow__disabled' : '' }`} >
                            <CaretDownOutlined onClick={handleClickDown} className='caret_down arrow__disabled' />
                        </button>
                    </div>
                    <div className='re_order__select'>
                        <div className='order_number'>{orderSortNumber}</div>
                        <Select 
                            value={orderSortNumber} 
                            className={'rowSelect_sort'}
                            onChange={handleChangeSorting}
                            disabled={orderHasPickOutData}
                        >
                            {ordersIndexesList.map((item, index) => (
                                <Select.Option key={index} value={item} >
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                </div> : null
                }
            </div>
            {isDetailsModalOpen &&
                <LoadDetailsModal
                    isDetailsModalOpen={isDetailsModalOpen}
                    setDetailsModalToOpen={setDetailsModalToOpen}
                    loadId={rowState?.loadData.id}
                    resource={resource}
                />
            }
        </>
    );
};
export default connect(state => ({
    state: state
}), dispatch => ({
    deleteLoad: (resource, rowState) => dispatch(deleteLoad(resource, rowState)),
    openPopup: data => dispatch(openPopup(data)),
    closePopup: key => dispatch(closePopup(key)),
    closePopupAsync: key => dispatch(closePopupAsync(key)),
    openAlert: key => dispatch(openPopup(key)),
    updateLoadData: (resource, newData) => dispatch(updateLoadData(resource, newData)),
    openConfirm: data => dispatch(openPopup(data)),
    setReOrderLoad: (resource, state, rowState, number) => dispatch(setReOrderLoad(resource, state, rowState, number)),
}))(RightActionCell);