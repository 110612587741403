import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { getHistoryList } from '../../../../store/actions/loadArea/loadArea';
import { getHistoryListCallCheck } from '../../../../store/actions/callCheck/callCheck';
import { getHistoryListDeliveredTrucks } from '../../../../store/actions/deliveredTrucksActions/deliveredTrucks';
import { HistorySelectors } from '../../../../store/selectors/historySelector';
import Loading from "../../screen/loading";
import { customStyles } from './styles';
import { translateIntoCamelCase } from './helper';

const HistoryModal = ({ isHistoryModalOpen, setHistoryModalOpen, inputRef, resource, rowState, cellDataKey, field }) => {
  const dispatch = useDispatch();
  const isSub = rowState.orderData.subOrderId;
  const orderId = isSub ? rowState.orderData.subOrderId : rowState.orderObjectId;
  const { historyList, loading } = HistorySelectors(resource);

  useEffect(() => {
    if (isSub) {
      if (resource === 'callCheck') {
        dispatch(getHistoryListCallCheck(resource, 'suborder/suborderchangeloginfo', orderId, translateIntoCamelCase(cellDataKey), isSub));
      } else if (resource === 'AccountPayable') {
        dispatch(getHistoryListDeliveredTrucks(resource, 'suborder/suborderchangeloginfo', orderId, translateIntoCamelCase(cellDataKey), isSub));
      }  else {
        dispatch(getHistoryList(resource, 'suborder/suborderchangeloginfo', orderId, translateIntoCamelCase(cellDataKey), isSub));
      }
    } else {
      dispatch(getHistoryList(resource, 'order/orderchangeloginfo', orderId, translateIntoCamelCase(cellDataKey)));
    }
  }, [cellDataKey, dispatch, isSub, orderId, resource]);

  const closeModal = () => {
    setHistoryModalOpen(false);
    if (field === 'appt') {
      inputRef.current.focus();
    }
  };

  const getFromDataValue = (fromData, field) => {
    if (field === 'appt') {
      return moment.utc(fromData).utcOffset(4).format('MM/DD/YY HH:mm');
    } else if (cellDataKey === 'poNumber') {
      return fromData;
    }
  }

  if (loading) {
    return (
      <Modal isOpen={isHistoryModalOpen} style={customStyles}>
        <div style={{ marginTop: '12% '}}>
          <Loading />
        </div>
      </Modal>
    )
  };

  return (
    <Modal isOpen={isHistoryModalOpen} onRequestClose={closeModal} style={customStyles} ariaHideApp={false}>
      <div className='history-modal__container'>
        <button onClick={closeModal} className='history-modal__x-button'>
          <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C13.91 14.69 13.28 14.69 12.89 14.3L10 11.41L7.11 14.3C6.72 14.69 6.09 14.69 5.7 14.3C5.31 13.91 5.31 13.28 5.7 12.89L8.59 10L5.7 7.11C5.31 6.72 5.31 6.09 5.7 5.7C6.09 5.31 6.72 5.31 7.11 5.7L10 8.59L12.89 5.7C13.28 5.31 13.91 5.31 14.3 5.7C14.69 6.09 14.69 6.72 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z'
              fill='#678482'
            />
          </svg>
        </button>
        <div className='history-modal__text'>{field === 'appt' ? 'Changes' : 'PO# Changes'}</div>
        <table className='history-modal__table'>
          <tr className='history-modal__tr'>
            <th className='history-modal__th'>Created/Changed by</th>
            <th className='history-modal__th'>Date/Time</th>
            <th className='history-modal__th'>{field === 'appt' ? 'Old APPT Date' : 'Old PO#'}</th>
            <th className='history-modal__th'>{field === 'appt' ? 'NEW APPT Date' : 'New PO#'}</th>
          </tr>
          {historyList?.map(item => (
            <tr className='history-modal__tr' key={item.id}>
              <td className='history-modal__td'>{item.updated}</td>
              <td className='history-modal__td'>{item.editDate ? moment.utc(item.editDate).utcOffset(4).format('MM/DD/YY HH:mm') : ''}</td>
              <td className='history-modal__td'>{item.fromData ? getFromDataValue(item.fromData, field) : ''}</td>
              <td className='history-modal__td'>{item.toData ? getFromDataValue(item.toData, field) : ''}</td>
            </tr>
          ))}
        </table>
      </div>
    </Modal>
  );
};

export default HistoryModal;
