import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { DatePicker } from "antd";
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Switchable from './switchable';
import FilterSettings from 'codeless/components/list/filterSettings';
import FilterContainer from './filterContainer';
import FilterField from './filterField/filterField';
import GroupFilterField from './filterField/groupFilterField';
import { FilterType } from '../../../config/list.config';
import {
    GenerateInput,
    GenerateSimpleCheckbox,
    GenerateRadioSwitcher,
    GenerateCheckBoxNoLabel
} from '../../../helper/element';
import SelectElement from '../../../elements/selectElement';
import { ElementConditions } from '../../../config/condition.config';

import { CommonLabels, Defines } from '../../../../project/Defines';
import { getListFilter, buildListFilterQuery, getCriteriaQuery } from '../../../helper/list';

const Filter = props => {
    const [filter, setFilter] = useState(null);
    const refs = useRef({});
    const history = useHistory();
    const criteriaQuery = getCriteriaQuery(history);
    const saveColumnSettingsRef = useRef(null);
    const [region, setRegion] = useState(null);

    const applyFilter = (f) => {
        history.push({
            search: buildListFilterQuery(criteriaQuery, f || filter)
        });
    };

    const applyHandler = () => {
        let fieldResource = props.profileSettings && props.filtersConfig.dragDrop && props.profileSettings.screens[props.filtersConfig.dragDrop.FieldSortResource];
        //
        if (region || (region && !fieldResource)) {
            saveColumnSettingsRef.current.saveColumnSettingsHandler();
        }

        applyFilter();
        region && setRegion(null);
    };

    const applyHandlerForRegions = e => {
        setRegion(e);
    };

    const clearFilterHandler = () => {
        const newFilters = {};
        for (let fk in Object.keys(props.filtersConfig)) {

            const key = Object.keys(props.filtersConfig)[fk];
            const f = props.filtersConfig[key];
            switch (f.Type) {
                case FilterType.Text:
                    newFilters[key] = '';
                    if (f.includeEmpty) {
                        newFilters[`${key}-empty`] = false;
                    }
                    break;
                case FilterType.Select:
                    newFilters[key] = '';
                    break;
                case FilterType.MultiSelect:
                    newFilters[key] = '';
                    break;
                case FilterType.Radio:
                    newFilters[key] = '';
                    break;
                case FilterType.Date:
                    newFilters[key + '_from'] = '';
                    newFilters[key + '_to'] = '';
                    break;
                case FilterType.Checkbox:
                    newFilters[key] = true;
                    break;
                default:
                    break;
            }
        }
        const f = { ...filter, ...newFilters };
        setFilter(f);
        region && setRegion(null);
        applyFilter(f);
    };

    useEffect(() => {
        const filter = getListFilter(criteriaQuery);
        setFilter(filter);
    }, [criteriaQuery]);

    const filterFieldChangeHandler = event => {
        if (event) {
            const newFilter = { ...filter };
            const target = event.target;
            const type = target.type;
            switch (type) {
                case 'checkbox':
                    newFilter[target.name] = target.checked;
                    break;
                case 'multiple':
                    newFilter[target.name] = target.value ? target.value.join(',') : '';
                    break;
                default:
                    newFilter[target.name] = target.value;
                    break;
            }
            //newFilter[target.name] = target.type !== 'checkbox' ? target.value : target.checked;
            setFilter(newFilter);
            if (props.filtersConfig[target.name] && props.filtersConfig[target.name].ApplyOnChange) {
                applyFilter(newFilter);
            }
        }
    };

    const keyPressHandler = event => {
        if (event.key === "Enter") {
            event.preventDefault();
            applyFilter();
        }
    };

    return (props.filtersConfig &&
        <Switchable hide left>
            <FilterContainer onApply={applyHandler} onClearAll={clearFilterHandler}>
                {
                    Object.keys(props.filtersConfig).map((fk, i) => {
                        const f = props.filtersConfig[fk];
                        let dataSource = f.Valuelist;
                        if (typeof (dataSource) === 'string' && props.data && typeof props.data[dataSource] === 'function') {
                            dataSource = props.data[dataSource]();
                        }

                        switch (f.Type) {
                            case FilterType.Text:
                                return (<FilterField key={fk + i} Title={f.Title}>
                                    {GenerateInput('text',
                                        ElementConditions.Enable,
                                        ref => { refs.current[fk] = ref; }, //ref
                                        filter && filter[fk], //data
                                        [],
                                        filterFieldChangeHandler,
                                        null,
                                        null,
                                        f.Title,
                                        keyPressHandler,
                                        fk)}
                                    {f.includeEmpty && <React.Fragment>
                                        <separator vertical=""></separator>
                                        {GenerateSimpleCheckbox(CommonLabels.Text.Empty,
                                            ElementConditions.Enable,
                                            null,
                                            filter && filter[`${fk}-empty`],
                                            [],
                                            filterFieldChangeHandler,
                                            null,
                                            null)}
                                    </React.Fragment>}
                                </FilterField>);
                            case FilterType.Select:
                                return (<FilterField key={fk + i} Title={f.Title} formFields>
                                    <wrap block="">
                                        <div className="form_fields line">
                                            <SelectElement
                                                defaultValue={f.DefaultValue}
                                                filterOption={(input, option, ...rest) => {
                                                    let text = f.caseSensitive ? option.text : option.text && option.text.toLowerCase();
                                                    let searchInput = f.caseSensitive ? input : input && input.toLowerCase();
                                                    return !searchInput || (text && (f.contains ? text.includes(searchInput) : text.startsWith(searchInput)));
                                                }}
                                                condition
                                                showSearch
                                                style={{ width: '100%' }}
                                                type="text"
                                                dataSource={dataSource}
                                                classes={['line']}
                                                placeholder={f.Title}
                                                name={fk}
                                                {...(filter ? { data: filter[fk] } : { placeholder: f.Title })}
                                                action={(e) => filterFieldChangeHandler({ target: { name: fk, value: e.target.value } }, f.applyOnChange)}
                                                allowClear />
                                        </div>
                                    </wrap>
                                </FilterField>);
                            case FilterType.MultiSelect:
                                return (<FilterField key={fk + i} Title={f.Title} formFields>
                                    <wrap block="">
                                        <div className="form_fields line">
                                            <SelectElement
                                                mode='multiple'
                                                filterOption={(input, option, ...rest) => {
                                                    let text = f.caseSensitive ? option.text : option.text && option.text.toLowerCase();
                                                    let searchInput = f.caseSensitive ? input : input && input.toLowerCase();
                                                    return !searchInput || (text && (f.contains ? text.includes(searchInput) : text.startsWith(searchInput)));
                                                }}
                                                condition
                                                showSearch
                                                style={{ width: '100%' }}
                                                type="text"
                                                dataSource={dataSource}
                                                classes={['line']}
                                                placeholder={f.Title}
                                                name={fk}
                                                {...(filter ? { data: filter[fk] } : { placeholder: f.Title })}
                                                action={(e) => filterFieldChangeHandler({ target: { type: 'multiple', name: fk, value: e.target.value } }, f.applyOnChange)}
                                                allowClear />
                                        </div>
                                    </wrap>
                                </FilterField>);
                            case FilterType.Date:
                                return <FilterField key={fk + i} Title={f.Title} formFields>
                                    <wrap block="">
                                        <div className="form_fields line">
                                            <DatePicker
                                                disabledDate={(current) => filter && filter[fk + '_to'] && current > moment(filter[fk + '_to'])}
                                                placeholder={CommonLabels.Text.From}
                                                format={Defines.Format.PrintDate}
                                                value={filter && filter[fk + '_from'] ? moment(filter[fk + '_from']) : ''}
                                                onChange={(e, value) => filterFieldChangeHandler({ target: { name: fk + '_from', value: value } }, f.applyOnChange)}
                                            />
                                        </div>
                                    </wrap>
                                    <wrap block="">
                                        <div className="form_fields line">
                                            <DatePicker placeholder={CommonLabels.Text.To}
                                                disabledDate={(current) => filter && filter[fk + '_from'] && current < moment(filter[fk + '_from'])}
                                                format={Defines.Format.PrintDate}
                                                value={filter && filter[fk + '_to'] ? moment(filter[fk + '_to']) : ''}
                                                onChange={(e, value) => filterFieldChangeHandler({ target: { name: fk + '_to', value: value } }, f.applyOnChange)}
                                            />
                                        </div>
                                    </wrap>
                                </FilterField>
                            case FilterType.Draggable:
                                const regionsObj = {};

                                dataSource.forEach(slot => regionsObj[slot.text] = { 'Title': slot.text });
                                return (
                                    (filter && !(f.Assigned === filter.applyFor)) &&
                                    <FilterField key={fk + i} Title={f.Title}>
                                        <wrap>
                                            <FilterSettings applyHandlerForRegions={applyHandlerForRegions} columns={regionsObj} isFiltered={true} ref={saveColumnSettingsRef} screen={f.FieldSortResource} >
                                                <text>Region Name</text>
                                            </FilterSettings>
                                        </wrap>
                                    </FilterField>
                                )

                            case FilterType.Checkbox:
                                return <>
                                { GenerateCheckBoxNoLabel(f.Title, ElementConditions.Enable, null, (filter && filter[fk]), [], filterFieldChangeHandler, null, null, null, fk)}
                                    </>
                            case FilterType.Radio:
                                return <GroupFilterField key={fk + i} Title={f.Title}>
                                    {GenerateRadioSwitcher(f.Title, ElementConditions.Enable, null, (filter && filter[fk]), [], filterFieldChangeHandler, null, null, fk)}

                                </GroupFilterField>
                            default:
                                return null;
                        }
                    })
                }
            </FilterContainer>
        </Switchable>
    );
};

const mapStateToProps = state => {
    return {
        profileSettings: state.auth.profileSettings
    }
}

export default connect(mapStateToProps, null)(Filter);